import * as React from 'react'
import { SVGProps } from 'react'

export const GetOutOfCar = (props: SVGProps<SVGSVGElement>) => (
    <svg
        width="1em"
        height="1em"
        viewBox="0 0 61 39"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="M5.10308 14.4013L6.98316 12.7849L7.25175 12.6379L4.02875 27.9209L0.268583 34.8277L0 35.8564C0.3223 38.5603 2.68583 38.2076 3.35729 37.4728L6.98316 31.0069V18.516C6.98316 15.9296 9.75853 14.2054 11.1462 13.6666H15.7121L13.2949 7.93543C12.9726 7.34762 11.9967 6.80879 11.5491 6.61286H9.669L2.68583 13.0788L1.34292 19.8386C2.09495 21.9547 3.49158 21.0142 4.02875 20.1325L5.10308 14.4013Z"
            fill={props.color}
        />
        <path
            d="M11.5491 6.02505C8.0575 6.02505 8.21865 0 11.5491 0C14.8795 0 15.8464 6.02505 11.5491 6.02505Z"
            fill={props.color}
        />
        <path
            d="M14.8732 30.878C14.8732 31.0989 11.1192 30.9701 9.24219 30.878V17.7614C9.81727 16.5464 11.1591 15.4142 11.7582 15H15.7119C15.041 15.3314 14.8732 16.6108 14.8732 17.2091V30.878Z"
            fill={props.color}
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M15.7119 31.4151H19.2268V36.5086C20.0379 38.9825 24.3369 40.5833 25.851 36.5086V31.4151H50.5903V36.5086C51.4015 38.837 55.5923 40.5833 57.2145 36.5086V31.4151H60.9998V17.7356C60.5492 16.7169 59.0801 14.5049 56.809 13.8064L52.7533 3.1829C52.3027 2.50378 51.0229 1.11642 49.5088 1H27.2029C26.3016 1.24254 24.418 2.01869 24.0936 3.1829L19.7675 13.8064C18.4157 14.3885 15.7119 16.0766 15.7119 18.1722V31.4151ZM24.0303 13H42.1574L46.2462 11.4286L50.6076 13H53.606C52.652 10.0476 50.5258 4.11429 49.6535 4H28.2554C27.0585 4 25.6514 8.18635 24.4956 11.6251L24.4956 11.6251L24.4956 11.6251C24.3352 12.1022 24.1797 12.5649 24.0303 13ZM22.7866 24C18.6359 24 17.9441 18 22.7866 18C26.9373 18 26.8819 24 22.7866 24ZM54.2104 24C50.0597 24 49.3679 18 54.2104 18C58.3611 18 58.3058 24 54.2104 24Z"
            fill={props.color}
        />
        <path
            d="M45.8362 12C41.5621 12 41.2568 6 45.8362 6C49.9576 6 50.1104 12 45.8362 12Z"
            fill={props.color}
        />
    </svg>
)
