import React, { ReactNode } from "react";
import styles from './PageLayout.module.scss'
import classNames from "classnames";

interface PageLayoutProps {
    children?: ReactNode
    className?: string
}

export const PageLayout: React.FC<PageLayoutProps> = ({
    children,
    className
}) => {
    return(
        <div className={classNames(styles.container, className)}>{children}</div>
    )
}