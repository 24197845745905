import React, {useRef, useEffect} from 'react'
import styles from './RemoteControl.module.scss'
import {Props} from './RemoteControl.typings'
import { moveAt } from '../../utils/moveAt'
import cn from 'classnames'

const RemoteControl = (props:Props) => {

    const remoteControlRef = useRef<null | HTMLDivElement>(null)
    const scale = Number(localStorage.getItem('remoteScale'))
    const wrapperRects = {x:0, y:0}

    useEffect(() => {
        if(props.wrapperRef){
            if(props.wrapperRef.current) {
                wrapperRects.x = props.wrapperRef.current.offsetWidth
                wrapperRects.y = props.wrapperRef.current.offsetHeight
            }
        }
    })

    useEffect(() => {
        const remoteControl = remoteControlRef.current
        if (remoteControl) {
            const rectX = localStorage.getItem('remoteControlRectX')
            const rectY = localStorage.getItem('remoteControlRectY')
            if(rectX) {
                remoteControl.style.left = rectX
            }
            if(rectY) {
                remoteControl.style.top = rectY
            }
            remoteControl.onmousedown = (event) => {
                const shiftX = event.clientX - remoteControl.getBoundingClientRect().left
                const shiftY = event.clientY - remoteControl.getBoundingClientRect().top
                remoteControl.style.zIndex = '100'

                const onMouseMove = (event: any) => {
                    moveAt(event.pageX, event.pageY, shiftX, shiftY, 'remoteControlRectX', 'remoteControlRectY', wrapperRects, remoteControlRef, props.padding, scale)
                }

                moveAt(event.pageX, event.pageY, shiftX, shiftY, 'remoteControlRectX', 'remoteControlRectY', wrapperRects, remoteControlRef, props.padding, scale)

                props.wrapperRef.current && props.wrapperRef.current.addEventListener('mousemove', onMouseMove)

                window.onmouseup = () => {
                    props.wrapperRef.current && props.wrapperRef.current.removeEventListener('mousemove', onMouseMove)
                    remoteControl.style.zIndex = '13'
                    window.onmouseup = null
                }
            }
            remoteControl.ondragstart = () => false
        }
    },[props.padding])

    return (
        <div
            ref={remoteControlRef}
            style={{transform: `scale(${props.remoteScale})`}}
            className={styles.rc}
        >
            <button
                data-nuitype="toggleRadarDisplay"
                className={cn(styles.rounded_btn, styles.toggle_display)}
                onClick={() => props.onToggleDisplay(prev => !prev)}
            >
                TOGGLE DISPLAY
            </button>
            <p className={styles.label}>FRONT ANTENNA</p>
            <div className={styles.antenna_btns_container}>
                <div className={cn(styles.btns, styles.btns_top)}>
                    <button
                        data-nuitype="setAntennaMode"
                        data-value="front"
                        data-mode="2"
                        className={cn(styles.zone_btn, styles.top_left)}
                        onClick={() => props.onXMitFrontOppOn()}
                    >
                        OPP LK/REL
                    </button>
                    <div className={styles.xmit_wrap}>
                        <div className={cn(styles.xmit_btn, styles.xmit_top)}>
                            <div className={cn(styles.arrow, styles.arrow_top)}></div>
                            <button
                                data-nuitype="toggleAntenna"
                                data-value="front"
                                className={styles.top_middle}
                                onClick={() => props.onXMitFrontOn()}
                            >
                                XMIT HOLD
                            </button>
                        </div>
                    </div>
                    <button
                        data-nuitype="setAntennaMode"
                        data-value="front"
                        data-mode="1"
                        className={cn(styles.zone_btn, styles.top_right)}
                        onClick={() => props.onXMitFrontSameOn()}
                    >
                        SAME LK/REL
                    </button>
                </div>
                <div className={styles.breaker}></div>
                <div className={cn(styles.btns, styles.btns_bottom)}>
                    <button
                        data-nuitype="setAntennaMode"
                        data-value="rear"
                        data-mode="2"
                        className={cn(styles.zone_btn, styles.bottom_left)}
                        onClick={() => props.onXMitRearOppOn()}
                    >
                        LK/REL OPP
                    </button>
                    <div className={styles.xmit_wrap}>
                        <div className={cn(styles.xmit_btn, styles.xmit_bottom)}>
                            <div className={cn(styles.arrow, styles.arrow_bottom)}></div>
                            <button
                                data-nuitype="toggleAntenna"
                                data-value="rear"
                                className={styles.bottom_middle}
                                onClick={() => props.onXMitRearOn()}
                            >
                                HOLD XMIT
                            </button>
                        </div>
                    </div>
                    <button
                        data-nuitype="setAntennaMode"
                        data-value="rear"
                        data-mode="1"
                        className={cn(styles.zone_btn, styles.bottom_right)}
                        onClick={() => props.onXMitRearSameOn()}
                    >
                        LK/REL SAME
                    </button>
                </div>
            </div>
            <p className={styles.label}>REAR ANTENNA</p>
            <button
                data-nuitype="menu"
                className={cn(styles.circle_btn, styles.menu, styles.blue)}
                onClick={() => props.onMenuChange()}
            >
                MENU
            </button>
            <div className={styles.plate_reader_and_help_container}>
                <button
                    className={cn(styles.plate_reader, styles.blue)}
                    onClick={() => props.onPlateReaderMenuOpen(true)}
                >
                    PLATE READER
                </button>
                <button
                    className={cn(styles.help, styles.blue)}
                    onClick={() => props.onHelpWindowOpen(true)}
                >
                    HELP
                </button>
            </div>
            <button
                className={cn(styles.rounded_btn, styles.light, styles.blue)}
                onClick={() => props.onUISettingsOpen(true)}
            >
                UI SETTINGS
            </button>
            <p className={styles.logo}>
                <span className={styles.large}>Wraith</span>
                ARS 2X
            </p>
        </div>
    )
}

export default RemoteControl