import * as React from 'react'
import { SVGProps } from 'react'

export const HandRope = (props: SVGProps<SVGSVGElement>) => (
    <svg
        width="1em"
        height="1em"
        viewBox="0 0 35 39"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="M0.600781 0.410477C0.257478 0.75378 -1.02424e-09 4.18682 -8.74017e-10 7.96315C-5.97473e-10 14.915 -5.97473e-10 14.915 3.86216 19.8071C6.52276 23.1543 7.72433 25.5575 7.72433 27.3598C7.72433 29.7629 7.9818 29.9346 10.6424 29.9346C15.5345 29.9346 16.3069 29.3338 16.3069 25.0425C16.3069 22.9827 15.8778 20.4079 15.3628 19.378C13.7321 16.1166 7.55268 10.2805 6.35111 10.7954C4.54877 11.482 4.89207 12.6836 7.81015 15.945C10.6424 19.2063 10.5566 20.9229 7.6385 18.2623C4.80625 15.6875 3.43303 12.5119 4.37712 10.7954C4.89207 10.023 5.14955 7.27654 4.9779 4.7876C4.72042 1.01126 4.46294 0.324652 3.0039 0.067173C1.97399 -0.104479 0.944084 0.067173 0.600781 0.410477Z"
            fill={props.color}
        />
        <path
            d="M29.7816 0.410999C28.9233 1.26926 29.095 9.50854 29.9532 10.9676C30.8973 12.4266 29.3525 15.8596 26.6918 18.2628C23.7738 20.9234 23.6879 19.2069 26.5202 15.9455C29.4383 12.6841 29.7816 11.4825 27.9792 10.7959C26.7777 10.281 20.5982 16.1171 18.9675 19.3785C18.4526 20.4084 18.0234 23.069 18.0234 25.3005C18.0234 28.991 18.1951 29.2485 19.9974 28.7335C21.0273 28.476 22.9155 28.2186 24.2887 28.2186C26.0052 28.2186 26.606 27.7894 26.606 26.5021C26.606 25.558 28.3225 22.5541 30.4682 19.8076L34.4162 14.7439L34.1587 7.53454C33.9012 1.01178 33.7296 0.325172 32.1847 0.0676956C31.1548 -0.103956 30.1249 0.0676956 29.7816 0.410999Z"
            fill={props.color}
        />
        <path
            d="M15.4488 30.5356C10.5567 31.1364 6.35124 31.9088 6.09376 32.0805C4.8922 33.282 7.72445 33.282 16.822 32.1663C22.4007 31.3939 27.2927 30.7931 27.636 30.7931C27.9794 30.7931 28.3227 30.364 28.3227 29.9348C28.3227 28.9049 26.8636 28.9907 15.4488 30.5356Z"
            fill={props.color}
        />
        <path
            d="M13.9898 33.7109C7.03787 34.655 3.60483 35.9424 8.06778 35.9424C12.6165 35.9424 27.0353 33.9684 27.8935 33.2818C28.9235 32.2519 22.9156 32.5093 13.9898 33.7109Z"
            fill={props.color}
        />
        <path
            d="M15.4488 36.5434C10.5567 37.1442 6.35124 37.9166 6.09376 38.0883C4.8922 39.2899 7.72445 39.2899 16.822 38.1741C22.4007 37.4017 27.2927 36.8009 27.636 36.8009C27.9794 36.8009 28.3227 36.3718 28.3227 35.9426C28.3227 34.9127 26.8636 34.9986 15.4488 36.5434Z"
            fill={props.color}
        />
    </svg>
)
