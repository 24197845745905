import * as React from 'react'
import { SVGProps } from 'react'

export const Sleep = (props: SVGProps<SVGSVGElement>) => (
    <svg
        width="1em"
        height="1em"
        viewBox="0 0 33 30"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="M18.9513 9.35431C18.8059 9.23812 18.622 9.18085 18.4364 9.19381H15.6075L18.2491 6.24458L18.4966 5.97039L18.6571 5.77645C18.7011 5.71442 18.7371 5.64696 18.7641 5.57582C18.7944 5.49916 18.8102 5.41749 18.8109 5.33506C18.8295 5.14004 18.7329 4.95195 18.5634 4.85356C18.3436 4.75299 18.1027 4.7071 17.8612 4.7198H14.5442C14.369 4.70785 14.1954 4.76018 14.056 4.86693C13.9408 4.96499 13.8767 5.11028 13.8821 5.2615C13.8821 5.48888 13.9557 5.62263 14.1095 5.67613C14.3268 5.73766 14.5526 5.76474 14.7783 5.75638H16.9183C16.8291 5.88119 16.711 6.02832 16.5638 6.19776L16.0088 6.8264L15.2865 7.62891L14.4238 8.58524C14.1229 8.91962 13.9289 9.147 13.8353 9.254C13.623 9.51189 13.6494 9.89083 13.8955 10.1167C14.0725 10.2599 14.2968 10.3315 14.5241 10.3173H18.4297C18.6165 10.332 18.8023 10.2772 18.9513 10.1635C19.0649 10.0643 19.1286 9.91975 19.1252 9.76894C19.131 9.61187 19.0674 9.46023 18.9513 9.35431Z"
            fill={props.color}
        />
        <path
            d="M24.8498 12.7181C24.7328 12.6245 24.5846 12.5791 24.4352 12.5911H22.1882L24.2948 10.237L24.4954 10.0164L24.6225 9.86254C24.6582 9.81297 24.6874 9.75905 24.7094 9.70204C24.7344 9.64034 24.748 9.57464 24.7495 9.5081C24.7663 9.35202 24.6905 9.20047 24.5556 9.12021C24.3798 9.03946 24.187 9.00268 23.9938 9.01321H21.3723C21.2328 9.00544 21.095 9.04815 20.9844 9.13359C20.8919 9.21133 20.8402 9.3272 20.844 9.44791C20.844 9.62847 20.9041 9.73547 21.0245 9.78229C21.1982 9.83311 21.3788 9.85577 21.5595 9.84916H23.2448C23.1712 9.94948 23.0776 10.0632 22.9639 10.2036L22.5225 10.7052L21.9474 11.3739L21.2787 12.1363C21.0379 12.4038 20.8797 12.5866 20.8038 12.6847C20.6463 12.8868 20.6665 13.1752 20.8506 13.3535C20.9892 13.45 21.1572 13.495 21.3255 13.4805H24.4419C24.5902 13.4924 24.7376 13.4496 24.8565 13.3602C24.945 13.28 24.9938 13.1651 24.9903 13.0458C24.9934 12.9214 24.9421 12.8018 24.8498 12.7181Z"
            fill={props.color}
        />
        <path
            d="M31.8584 8.97982C31.7129 8.86362 31.5291 8.80636 31.3434 8.81931H28.5213L31.1629 5.87008L31.4036 5.56914L31.5641 5.3752C31.608 5.313 31.644 5.24563 31.6711 5.17457C31.7015 5.09791 31.7173 5.01624 31.7179 4.93382C31.7366 4.73879 31.64 4.5507 31.4705 4.45231C31.2423 4.33962 30.9892 4.28671 30.7349 4.29849H27.4579C27.2827 4.28654 27.1092 4.33887 26.9697 4.44562C26.8546 4.54368 26.7904 4.68897 26.7959 4.84019C26.7959 5.06757 26.8694 5.20132 27.0232 5.25482C27.2406 5.31626 27.4663 5.34335 27.692 5.33507H29.832C29.7428 5.45988 29.627 5.60701 29.4843 5.77645L28.9292 6.40509L28.207 7.2076L27.3443 8.16393C27.0433 8.49831 26.8494 8.72569 26.7557 8.83269C26.6686 8.93844 26.6191 9.07018 26.6153 9.20719C26.6064 9.39161 26.6799 9.5705 26.8159 9.69539C26.9923 9.82831 27.211 9.89242 27.4312 9.87595H31.3368C31.5236 9.89058 31.7093 9.83583 31.8584 9.72214C31.972 9.62291 32.0357 9.47838 32.0323 9.32757C32.02 9.1939 31.9579 9.06977 31.8584 8.97982Z"
            fill={props.color}
        />
        <path
            d="M29.3529 20.0936C29.1815 20.0125 28.9837 20.0081 28.8089 20.0812H28.8155C21.5663 23.0797 13.2588 19.6337 10.2604 12.3844C8.73953 8.70751 8.83065 4.56119 10.5116 0.954649C10.6695 0.620771 10.5268 0.222106 10.1929 0.0641949C10.0215 -0.0168923 9.82376 -0.0213228 9.64888 0.0518229C1.70225 3.31621 -2.09355 12.4045 1.17075 20.3511C4.43505 28.2978 13.5233 32.0936 21.4701 28.8293C25.0944 27.3404 28.0234 24.5387 29.6716 20.984C29.8295 20.6501 29.6868 20.2515 29.3529 20.0936Z"
            fill={props.color}
        />
    </svg>
)
