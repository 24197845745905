import gait1Icon from '../../../pages/ui/CharacterPersonalization/screens/About/components/GaitSelect/assets/gait1Icon.svg'
import gait2Icon from '../../../pages/ui/CharacterPersonalization/screens/About/components/GaitSelect/assets/gait2Icon.svg'
import gait3Icon from '../../../pages/ui/CharacterPersonalization/screens/About/components/GaitSelect/assets/gait3Icon.svg'
import gait4Icon from '../../../pages/ui/CharacterPersonalization/screens/About/components/GaitSelect/assets/gait4Icon.svg'

export const heightRange = [4.6, 7.5]
export const weightRange = [88, 440]
export const gaitVariants = [
    { id: 1, icon: gait1Icon, title: 'Отличная походка' },
    { id: 2, icon: gait2Icon, title: 'Великолепная походка' },
    { id: 3, icon: gait3Icon, title: 'Чудесная походка' },
    { id: 4, icon: gait4Icon, title: 'Замечательная походка' },
]

export const monthArr = [
    'Январь',
    'Февраль',
    'Март',
    'Апрель',
    'Май',
    'Июнь',
    'Июль',
    'Август',
    'Сентябрь',
    'Октябрь',
    'Ноябрь',
    'Декабрь',
]

export const daysArr: number[] = Array.from({ length: 31 }, (_, i) => i + 1)

export const nationalities = [
    {
        code: 'CHN',
        titles: {
            male: 'Китаец',
            female: 'Китаянка',
        },
    },
    {
        code: 'SAU',
        titles: {
            male: 'Араб',
            female: 'Арабка',
        },
    },
    {
        code: 'USA',
        titles: {
            male: 'Американец',
            female: 'Американка',
        },
    },
    {
        code: 'CHE',
        titles: {
            male: 'Чеченец',
            female: 'Чеченка',
        },
    },
    {
        code: 'ISR',
        titles: {
            male: 'Еврей',
            female: 'Еврейка',
        },
    },
    {
        code: 'BRA',
        titles: {
            male: 'Бразилец',
            female: 'Бразильянка',
        },
    },
    {
        code: 'MEX',
        titles: {
            male: 'Мексиканец',
            female: 'Мексиканка',
        },
    },
    {
        code: 'RUS',
        titles: {
            male: 'Русский',
            female: 'Русская',
        },
    },
    {
        code: 'JPN',
        titles: {
            male: 'Японец',
            female: 'Японка',
        },
    },
    {
        code: 'AZE',
        titles: {
            male: 'Азербайджанец',
            female: 'Азербайджанка',
        },
    },
    {
        code: 'ARM',
        titles: {
            male: 'Армянин',
            female: 'Армянка',
        },
    },
    {
        code: 'CZE',
        titles: {
            male: 'Чех',
            female: 'Чешка',
        },
    },
    {
        code: 'ESP',
        titles: {
            male: 'Испанец',
            female: 'Испанка',
        },
    },
    {
        code: 'SWE',
        titles: {
            male: 'Швед',
            female: 'Шведка',
        },
    },
    {
        code: 'HUN',
        titles: {
            male: 'Венгр',
            female: 'Венгерка',
        },
    },
    {
        code: 'KOR',
        titles: {
            male: 'Кореец',
            female: 'Кореянка',
        },
    },
    {
        code: 'GBR',
        titles: {
            male: 'Англичанин',
            female: 'Англичанка',
        },
    },
    {
        code: 'THA',
        titles: {
            male: 'Таец',
            female: 'Тайка',
        },
    },
    {
        code: 'VNM',
        titles: {
            male: 'Въетнамец',
            female: 'Въетнамка',
        },
    },
    {
        code: 'DEU',
        titles: {
            male: 'Немец',
            female: 'Немка',
        },
    },
    {
        code: 'FRA',
        titles: {
            male: 'Француз',
            female: 'Француженка',
        },
    },
    {
        code: 'TUR',
        titles: {
            male: 'Турок',
            female: 'Турчанка',
        },
    },
    {
        code: 'ITA',
        titles: {
            male: 'Итальянец',
            female: 'Итальянка',
        },
    },
    {
        code: 'KAZ',
        titles: {
            male: 'Казах',
            female: 'Казашка',
        },
    },
    {
        code: 'IRL',
        titles: {
            male: 'Ирландец',
            female: 'Ирландка',
        },
    },
    {
        code: 'GRC',
        titles: {
            male: 'Грек',
            female: 'Гречанка',
        },
    },
    {
        code: 'IND',
        titles: {
            male: 'Индус',
            female: 'Индуска',
        },
    },
    {
        code: 'END',
        titles: {
            male: 'Индеец',
            female: 'Индейка',
        },
    },
]