import * as React from 'react'
import { SVGProps } from 'react'

export const TurnRight = (props: SVGProps<SVGSVGElement>) => (
    <svg
        width="1em"
        height="1em"
        viewBox="0 0 15 15"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="M14.7592 6.96162L7.48033 0.223023C7.24522 0.00525654 6.89162 -0.0598908 6.5845 0.0580239C6.27727 0.175837 6.07701 0.453373 6.07701 0.761149V3.41525H0.822062C0.367955 3.41525 0 3.756 0 4.17631V10.8233C0 11.2436 0.367955 11.5843 0.822062 11.5843H6.07701L6.07723 14.2384C6.07723 14.5462 6.27748 14.8238 6.58472 14.9416C6.68643 14.9805 6.79319 14.9995 6.89907 14.9995C7.11303 14.9995 7.32326 14.9222 7.48065 14.7766L14.7593 8.03797C14.9134 7.89529 15 7.70168 15 7.49984C15 7.29801 14.9134 7.10429 14.7592 6.96162ZM7.72125 12.4011L7.72114 10.8232C7.72114 10.4029 7.35307 10.0621 6.89907 10.0621H1.64412V4.93728H6.89907C7.35318 4.93728 7.72114 4.59652 7.72114 4.17621V2.59857L13.0154 7.49984L7.72125 12.4011Z"
            fill={props.color}
        />
    </svg>
)
