import logoSupermarket from '../assets/logoSupermarket.svg'
import fastFoodButtonPic from '../assets/fastFoodButtonPic.svg'
import otherButtonPic from '../assets/otherButtonPic.svg'
import hamburgerPic from '../assets/hamburgerPic.png'
export const previewStore = {
    headerLogo:logoSupermarket,
    title: "Супермаркет",
    sidebarButtons: [fastFoodButtonPic, otherButtonPic],
    mainHeaderTitles: ['Продукты', 'Другие товары'],
    categories: [
        {key: 'mainGoods', title: 'Продукты', icon: fastFoodButtonPic},
        {key: 'otherGoods', title: 'Другие товары', icon: fastFoodButtonPic},
    ],
    mainGoods: [
        {id: '1', price:20000, image: hamburgerPic, description:'Lorem Ipsum is simply dummy text of the', sale: {isSale:true, oldPrice: 54000}, color: '2'}, 
        {id: '2', price:54000, image: hamburgerPic, description:'Lorem Ipsum is simply dummy text of the', sale: {isSale:false}, color: '2'}, 
        {id: '3', price:54000, image: hamburgerPic, description:'Lorem Ipsum is simply dummy text of the', sale: {isSale:false}, color: 'red'}, 
        {id: '4', price:34000, image: hamburgerPic, description:'Lorem Ipsum is simply dummy text of the', sale: {isSale:false}, color: '2'}, 
        {id: '5', price:54000, image: hamburgerPic, description:'Lorem Ipsum is simply dummy text of the', sale: {isSale:false}, color: '2'}, 
        {id: '6', price:54000, image: hamburgerPic, description:'Lorem Ipsum is simply dummy text of the', sale: {isSale:false}, color: '2'}, 
        {id: '7', price:54000, image: hamburgerPic, description:'test for search', sale: {isSale:false}, color: '2'}, 
        {id: '8', price:54000, image: hamburgerPic, description:'Lorem Ipsum is simply dummy text of the', sale: {isSale:false}, color: '2'}, 
        {id: '9', price:54000, image: hamburgerPic, description:'Lorem Ipsum is simply dummy text of the', sale: {isSale:false}, color: '2'}, 
        {id: '10', price:54000, image: hamburgerPic, description:'Lorem Ipsum is simply dummy text of the', sale: {isSale:false}}, 
        {id: '11', price:54000, image: hamburgerPic, description:'Lorem Ipsum is simply dummy text of the', sale: {isSale:false}}, 
        {id: '12', price:54000, image: hamburgerPic, description:'Lorem Ipsum is simply dummy text of the', sale: {isSale:false}, color: '2'}, 
        {id: '13', price:54000, image: hamburgerPic, description:'Lorem Ipsum is simply dummy text of the', sale: {isSale:false}, color: '2'}, 
        {id: '14', price:54000, image: hamburgerPic, description:'Lorem Ipsum is simply dummy text of the', sale: {isSale:false}, color: '2'}, 
        
    ],
    otherGoods: [
        {id: '1', price:20000, image: hamburgerPic, description:'Lorem Ipsum is simply dummy text of the', sale: {isSale:true, oldPrice: 54000}}, 
        {id: '2', price:54000, image: hamburgerPic, description:'Lorem Ipsum is simply dummy text of the', sale: {isSale:false}}, 
        {id: '3', price:54000, image: hamburgerPic, description:'Lorem Ipsum is simply dummy text of the', sale: {isSale:false}}, 
        {id: '4', price:34000, image: hamburgerPic, description:'Lorem Ipsum is simply dummy text of the', sale: {isSale:false}}, 
        {id: '5', price:54000, image: hamburgerPic, description:'Lorem Ipsum is simply dummy text of the', sale: {isSale:false}}, 
        {id: '6', price:54000, image: hamburgerPic, description:'Lorem Ipsum is simply dummy text of the', sale: {isSale:false}}, 
        {id: '7', price:54000, image: hamburgerPic, description:'test for search', sale: {isSale:false}}, 
        {id: '8', price:54000, image: hamburgerPic, description:'Lorem Ipsum is simply dummy text of the', sale: {isSale:false}}
    ],
    select: [{key: 'fromLowerToUpper', title: 'От дешевых к дорогим'}, {key: 'fromUpperToLower', title: 'От дорогих к дешевым'}],
    salesTax: 0.1,
    playerBalance: 1000000,
    storeType: 'supermarket',
    colorSelect: [
        {colorId: '1', color: '#000000'},
        {colorId: '2', color: '#00ff00'},
        {colorId: '3', color: '#0000ff'},
        {colorId: 'red', color: '#ff0000'},
        {colorId: '5', color: '#00ffff'},
        {colorId: '6', color: '#ffff00'},
        {colorId: '7', color: '#ffffff'},
        {colorId: '8', color: '#0f0f0f'}
    ],
    playerCash:9000,
    playerCreditCardValue: 9000
}
