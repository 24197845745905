import React, { useState, useEffect } from 'react'
import styles from '../../PoliceRadar.module.scss'
import cn from 'classnames'
import { Props } from './FastSpeedWindow.typings'
import { Position } from '../../PoliceRadar.config'

const FastSpeedWindow = (props: Props) => {

    const [displayValue, setDisplayValue] = useState<string>('')

    const handleDisplayValue = () => {
        if (props.position === Position.bottom) {
            if (props.isTurningOn) {
                setDisplayValue('888')
            } else if (props.PWRon && props.menuIndex === 9 && !props.xMitRearOn) {
                setDisplayValue('HLd')
            } else {
                setDisplayValue('')
            }
        } else if(props.position === Position.top) {
            if(props.isTurningOn) {
                setDisplayValue('888')
            } else if(props.PWRon && props.menuIndex === 9 && !props.xMitFrontOn) {
                setDisplayValue('HLd')
            } else {
                setDisplayValue(props.menuTitle)
            }
        }
    }

    useEffect(() => {
        handleDisplayValue()
    }, [props])

    return (
        <div className={cn(styles.display, styles.fast, props.position === Position.bottom ? styles.fast_bottom : styles.fast_top)}>
            <p className={styles.fast_ghost_speed}>
                888
            </p>
            <p className={styles.fast_speed}>
                {displayValue}
            </p>
        </div>
    )
}

export default FastSpeedWindow