import { ProgressSvg } from 'shared/ui/ProgressSvg/ProgressSvg'
import { Mood } from 'shared/icons/Mood'
import { Toilet } from 'shared/icons/Toilet'
import { Shower } from 'shared/icons/Shower'
import { Guard } from 'shared/icons/Guard'
import { Sleep } from 'shared/icons/Sleep'
import { Pizza } from 'shared/icons/Pizza'
import { Cup } from 'shared/icons/Cup'
import styles from './HudNeeds.module.scss'
import React from 'react'
import cn from 'classnames'
import { useNeedsChangeQuery } from './api/hudNeedsApi'
import { useAltStatusQuery } from 'widgets/ui/HudHints/api/hudHintsApi'

export const HudNeeds = () => {

    const {data: needsStatus = {
        toilet: 1,
        shower: 1,
        immunity: 1,
        sleep: 1,
        mood: 1,
        food: 1,
        water: 1
    }} = useNeedsChangeQuery()

    const { data: altStatus = false } = useAltStatusQuery()

    const checkValue = (value:number) => {
        if(value >= 0 && value <= 1) return value
        else return 0
    }

    return (
        <div className={styles.container}>
            <ProgressSvg
                className={cn(styles.icon, (needsStatus.toilet <= 0.3 || altStatus) && styles.iconShow)}
                Icon={Toilet}
                percent={checkValue(needsStatus.toilet)}
            />
            <ProgressSvg
                className={cn(styles.icon, (needsStatus.shower <= 0.3 || altStatus) && styles.iconShow)}
                Icon={Shower}
                percent={checkValue(needsStatus.shower)}
            />
            <ProgressSvg
                className={cn(styles.icon, (needsStatus.immunity <= 0.3 || altStatus) && styles.iconShow)}
                Icon={Guard}
                percent={checkValue(needsStatus.immunity)}
            />
            <ProgressSvg
                className={cn(styles.icon, (needsStatus.sleep <= 0.3 || altStatus) && styles.iconShow)}
                Icon={Sleep}
                percent={checkValue(needsStatus.sleep)}
            />
            <ProgressSvg
                className={cn(styles.icon, (needsStatus.mood <= 0.3 || altStatus) && styles.iconShow)}
                Icon={Mood}
                percent={checkValue(needsStatus.mood)}
            />
            <ProgressSvg
                className={cn(styles.icon, (needsStatus.food <= 0.3 || altStatus) && styles.iconShow)}
                Icon={Pizza}
                percent={checkValue(needsStatus.food)}
            />
            <ProgressSvg
                className={cn(styles.icon, (needsStatus.water <= 0.3 || altStatus) && styles.iconShow)}
                Icon={Cup}
                percent={checkValue(needsStatus.water)}
            />
        </div>
    )
}
