import * as React from 'react'
import { SVGProps } from 'react'

export const AutoDoor = (props: SVGProps<SVGSVGElement>) => (
    <svg
        width="1em"
        height="1em"
        viewBox="0 0 15 15"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="M14.6138 6.05291L8.94712 0.38625C8.69818 0.137344 8.36689 0 8.01498 0H1.31836C0.591387 0 0 0.591387 0 1.31836V13.6816C0 14.4086 0.591387 15 1.31836 15H8.37891C8.54543 15 8.69733 14.906 8.77201 14.7571C9.78094 12.7384 11.8393 11.4844 14.0966 11.4844H14.5605C14.8034 11.4844 15 11.2878 15 11.0449V6.98502C15 6.63311 14.8627 6.30182 14.6138 6.05291ZM5.27344 10.6055C5.27344 11.5749 4.48509 12.3633 3.51562 12.3633C2.54616 12.3633 1.75781 11.5749 1.75781 10.6055V10.166C1.75781 9.92311 1.95437 9.72656 2.19727 9.72656H4.83398C5.07688 9.72656 5.27344 9.92311 5.27344 10.166V10.6055ZM12.8027 7.93945H2.19727C1.95437 7.93945 1.75781 7.7429 1.75781 7.5V2.19727C1.75781 1.95437 1.95437 1.75781 2.19727 1.75781H7.65064C7.76736 1.75781 7.87896 1.80416 7.96134 1.88657L13.1134 7.03866C13.3489 7.27415 13.2899 7.93945 12.8027 7.93945Z"
            fill={props.color}
        />
    </svg>
)
