import sportIcon from './assets/sportIcon.svg'
import createFamilyIcon from './assets/createFamilyIcon.svg'
import strengthIcon from './assets/strengthIcon.svg'
import stateIcon from './assets/stateIcon.svg'
import foodIcon from './assets/foodIcon.svg'
import criminalIcon from './assets/criminalIcon.svg'
import loveIcon from './assets/loveIcon.svg'
import hobbyIcon from './assets/hobbyIcon.svg'
import bookIcon from './assets/bookIcon.svg'
import swordIcon from './assets/swordIcon.svg'

export const traitsArr = {
    traits1: [
        { id: 1, icon: sportIcon, title: 'Спорт', selected: false },
        { id: 2, icon: createFamilyIcon, title: 'Создать семью', selected: false },
        { id: 3, icon: strengthIcon, title: 'Сила', selected: false },
        { id: 4, icon: stateIcon, title: 'Состояние', selected: false },
        { id: 5, icon: foodIcon, title: 'Еда', selected: false },
        { id: 6, icon: criminalIcon, title: 'Бандит', selected: false },
        { id: 7, icon: loveIcon, title: 'Любовь', selected: false },
        { id: 8, icon: hobbyIcon, title: 'Хобби', selected: false },
        { id: 9, icon: bookIcon, title: 'Кругозор', selected: true }
    ],
    traits2: [
        { id: 9, icon: bookIcon, title: 'Кругозор', selected: true },
        { id: 2, icon: createFamilyIcon, title: 'Создать семью', selected: false },
        { id: 5, icon: foodIcon, title: 'Еда', selected: false },
        { id: 3, icon: strengthIcon, title: 'Сила', selected: false },
        { id: 4, icon: stateIcon, title: 'Состояние', selected: false },
        { id: 6, icon: criminalIcon, title: 'Бандит', selected: false },
        { id: 7, icon: loveIcon, title: 'Любовь', selected: false },
        { id: 1, icon: sportIcon, title: 'Спорт', selected: false },
        { id: 8, icon: hobbyIcon, title: 'Хобби', selected: false },
    ],
    traits3: [
        { id: 6, icon: criminalIcon, title: 'Бандит', selected: false },
        { id: 1, icon: sportIcon, title: 'Спорт', selected: false },
        { id: 2, icon: createFamilyIcon, title: 'Создать семью', selected: false },
        { id: 7, icon: loveIcon, title: 'Любовь', selected: false },
        { id: 3, icon: strengthIcon, title: 'Сила', selected: false },
        { id: 4, icon: stateIcon, title: 'Состояние', selected: false },
        { id: 5, icon: foodIcon, title: 'Еда', selected: false },
        { id: 8, icon: hobbyIcon, title: 'Хобби', selected: false },
        { id: 9, icon: bookIcon, title: 'Кругозор', selected: true }
    ],
    traits4: [
        { id: 1, icon: sportIcon, title: 'Спорт', selected: false },
        { id: 2, icon: createFamilyIcon, title: 'Создать семью', selected: false },
        { id: 6, icon: criminalIcon, title: 'Бандит', selected: false },
        { id: 3, icon: strengthIcon, title: 'Сила', selected: false },
        { id: 7, icon: loveIcon, title: 'Любовь', selected: false },
        { id: 4, icon: stateIcon, title: 'Состояние', selected: false },
        { id: 9, icon: bookIcon, title: 'Кругозор', selected: true },
        { id: 5, icon: foodIcon, title: 'Еда', selected: false },
        { id: 8, icon: hobbyIcon, title: 'Хобби', selected: false },
    ],
}

export const characterTraitsLabels = [
    { id: 1, icon: swordIcon },
    { id: 2, icon: swordIcon },
    { id: 3, icon: swordIcon },
    { id: 4, icon: swordIcon },
]