import React from 'react'
import {Props} from './PlateReaderBox.typings'
import styles from './PlateReaderBox.module.scss'

const PlateReaderBox = (props:Props) => {

    return (
        <div
            className={styles.plateReaderBox}
        >
            <div className={styles.header}>
                <p className={styles.title}>Plate Reader</p>
            </div>
            <div className={styles.container}>
                <button
                    data-nuitype="togglePlateReaderDisplay"
                    className={styles.btn}
                    onClick={() => props.onPlateReaderOn(prev => !prev)}
                >
                    Toggle Display
                </button>
                <input
                    type="text"
                    maxLength={8}
                    placeholder="12ABC345"
                    className={styles.plate_input}
                />
                <button className={styles.btn}>Set BOLO Plate</button>
                <button className={styles.btn}>Clear BOLO Plate</button>
            </div>
            <button
                id="closePlateReaderSettings"
                className={styles.close}
                onClick={() => props.onClose(false)}
            >
                CLOSE
            </button>
        </div>
    )
}

export default PlateReaderBox