import React from 'react'
import { Input } from '../Input/Input'
import Login from '../../../../../shared/icons/generated/Login'
import { PasswordInput } from '../PasswordInput/PasswordInput'
import { useFormik } from 'formik'
import styles from './LoginForm.module.scss'
import { CheckBox } from '../CheckBox/CheckBox'
import { Button } from '../Button/Button'
import { SocialBlock } from '../SocialBlock/SocialBlock'
import { Gap } from 'shared/ui/Gap/Gap'
import { toastify } from 'shared/ui/Toast/Toast'
import { useLoginMutation } from '../../../../../app/api/authApi';

export const LoginForm: React.FC<any> = () => {
    const [login, { data, error, isLoading }] = useLoginMutation();
    const { handleChange, values, handleSubmit, setFieldValue } = useFormik({
        initialValues: {
            email: '',
            password: '',
            rememberMe: false,
        },

        onSubmit: (values: any) => {
            login(values).catch(() =>
                toastify.error('Не удалось авторизоваться')
            )
            onSubmitForm({email:values.email, password:values.password})
        },
    })

    const onSubmitForm = async ({email, password}:{email:string, password:string}) => {
        try {
            await login({email, password}); // Вызываем мутацию
        } catch (e) {
            console.error('Error during registration', e);
        }
        alert(data && data || error && error || isLoading && isLoading)
    };

    return (
        <form className={styles.container} onSubmit={handleSubmit}>
            <Input
                Icon={Login}
                placeholder="Логин или пароль"
                onChange={handleChange}
                value={values.email}
                name="email"
            />
            <Gap size={10} />
            <PasswordInput
                onChange={handleChange}
                value={values.password}
                name="password"
            />
            <Gap size={10} />
            <CheckBox
                label="Запомнить меня"
                checked={values.rememberMe}
                onChange={(checked) => setFieldValue('rememberMe', checked)}
            />
            <Button value="Войти" type="submit" className={styles.button} />
            <div className={styles.forgotPassword}>
                Забыли пароль? <button type="button">Восстановить</button>
            </div>
            <Gap size={20} />
            <SocialBlock />
        </form>
    )
}
