import * as React from 'react'
import { SVGProps } from 'react'

export const Cup = (props: SVGProps<SVGSVGElement>) => (
    <svg
        width="1em"
        height="1em"
        viewBox="0 0 27 30"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="M20.2428 0.190476C20.2114 0.0634921 20.0857 0 19.9285 0H0.565794C0.408629 0 0.251464 0.0634921 0.157165 0.190476C0.0628659 0.31746 0 0.444444 0 0.603175L2.16888 27.3968C2.29461 28.8254 3.55193 30 4.96641 30H15.4336C16.8795 30 18.1368 28.8254 18.2311 27.3968L20.4 0.603175C20.4 0.444444 20.3686 0.285714 20.2428 0.190476ZM15.4965 28.9206H4.99784C4.14915 28.9206 3.39476 28.2222 3.30046 27.3651H17.1938C17.0995 28.2222 16.3137 28.9206 15.4965 28.9206ZM17.2881 26.254H3.20616L1.16302 1.11111H19.2998L17.2881 26.254Z"
            fill={props.color}
        />
        <path
            d="M17.2079 9.44483C17.0507 9.31785 16.8621 9.28611 16.705 9.3496C14.6304 10.0798 12.4301 10.0798 10.4812 9.38134C9.75827 9.12737 8.90958 9.00039 7.99803 9.00039C5.73485 9.00039 3.66028 9.79404 3.56598 9.82579C3.34595 9.92103 3.18878 10.1432 3.22022 10.3972L4.28894 24.1115C4.32037 24.3972 4.5404 24.6194 4.8233 24.6194H15.9506C16.2335 24.6194 16.4849 24.3972 16.4849 24.1115L17.4279 9.92103C17.4279 9.73055 17.3651 9.54007 17.2079 9.44483Z"
            fill={props.color}
        />
    </svg>
)
