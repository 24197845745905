import React, { useEffect, useRef } from 'react'
import stylesBase from './Chat.module.scss'
import { useState } from 'react'
import Message from './components/Message'
import { usePrintMutation, useSendMutation, useChatCommandMutation, useMessagesQuery, usePersonFindMutation, useChatStatusQuery } from './api/chatApi'
import cn from 'classnames'
import { useSelector, useDispatch } from 'react-redux'
import { CHAT_TAGS } from './Chat.config'
import { isEmptyString } from './utils/isEmptyString';
import { getFormatGender } from './utils/getFormatGender'
import { friendsSelector } from 'app/redux-toolkit/friendsSlice'
import { MessagesProps } from './api/types'
import { RootState } from '../../../app/store';
import { addCommandToHistory, setActiveIndex } from '../../../app/redux-toolkit/chatHistorySlice';

export const Chat = () => {
    const { data: chatStatus = false } = useChatStatusQuery()
    const friends = useSelector(friendsSelector);
    const [activeTag, setActiveTag] = useState<number>(1)
    const [send] = useSendMutation();
    const [command] = useChatCommandMutation()
    const [value, setValue] = useState('')
    const [personFind] = usePersonFindMutation();

    const errorTimerRef = useRef<NodeJS.Timeout | null>(null)

    const dispatch = useDispatch();
    const { chatCommandHistory, chatCommandHistoryActiveIndex } = useSelector(
        (state: RootState) => state.chatHistory
    );

    const handleAddCommandHistory = (value: string) => {
        dispatch(addCommandToHistory(value));
    };

    const handlePrevCommandHistory = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
        if (e.key === 'ArrowUp') {
            if (chatCommandHistory.length > 0) {
                const newIndex =
                    (chatCommandHistoryActiveIndex + 1) % chatCommandHistory.length; // Листание вверх по кругу
                dispatch(setActiveIndex(newIndex));
                setValue(chatCommandHistory[newIndex]);
                setTimeout(() => moveCursorToEnd(e.target as HTMLTextAreaElement), 0); // Перемещаем курсор
            }
        } else if (e.key === 'ArrowDown') {
            if (chatCommandHistory.length > 0) {
                const newIndex =
                    (chatCommandHistoryActiveIndex - 1 + chatCommandHistory.length) % chatCommandHistory.length; // Листание вниз по кругу
                dispatch(setActiveIndex(newIndex));
                setValue(chatCommandHistory[newIndex]);
                setTimeout(() => moveCursorToEnd(e.target as HTMLTextAreaElement), 0); // Перемещаем курсор
            }
        }
    };

    const moveCursorToEnd = (textarea: HTMLTextAreaElement) => {
        const length = textarea.value.length;
        textarea.setSelectionRange(length, length); // Устанавливаем курсор в конец текста
        textarea.focus(); // Обязательно ставим фокус на элемент
    };

    const handleCommand = (value: string) => {
        const commandEndIndex = value.split('').findIndex(item => item === ' ')
        const enteredCommand = value.slice(1, commandEndIndex + 1)
        if (value.length > commandEndIndex) {
            const enteredArgs = value.slice(commandEndIndex + 1).split(' ')
            command({
                name: enteredCommand,
                args: enteredArgs,
            })
        }
        else {
            command({
                name: enteredCommand,
                args: []
            })
        }
        handleAddCommandHistory(value)
        setValue('')
    }

    const handleSubmit = (event: React.FormEvent<HTMLFormElement> | React.KeyboardEvent) => {
        event.preventDefault()
        if (value[0] === '#' && value.length === 7) {
            const isFriend = friends.find((item: any) => item.staticId === Number(value.slice(1)))
            if (isFriend) {
                setValue(`${isFriend.name} ${isFriend.staticId}`)
            } else {
                const onPersonFind = async () => {
                    try {
                        const result = await personFind(value); // Вызываем мутацию
                        if (result.data) {
                            setValue(`${getFormatGender(result.data.gender)} ${result.data.uuid}`)
                        }
                    } catch (e) {
                        console.error('Error', e);
                    }
                };
                onPersonFind()
            }
        } else if (activeTag === 5) {
            const isToDoCorrect = checkToDoMessage(value)
            if (isToDoCorrect) {
                send({ text: value, type: 'toDo' })
            } else if (!errorTimerRef.current) {
                typeErrorToDo()
                errorTimerRef.current = setTimeout(() => { errorTimerRef.current = null }, 60000)
            }
            setValue('')
        } else if (value[0] === '/') {
            handleCommand(value)
        } else {
            if (!isEmptyString(value)) {
                if (value[0] !== '/' && value[0] !== '#') {
                    send({ text: value, type: CHAT_TAGS.find(item => item.id === activeTag)?.text || '' });
                    setValue('')
                } else {
                    setValue('')
                }
            } else {
                setValue('')
            }
        }
    }
    //const [isTagListActive, setTagListActive] = useState<boolean>(false)
    const [isTyping, setTyping] = useState<boolean>(false)
    const [isChatActive, setChatActive] = useState<boolean>(true)

    useEffect(() => {
        const callback = (e: any) => {
            if (e.keyCode === 9) {
                e.preventDefault()
                setActiveTag(prev =>
                    activeTag < CHAT_TAGS.length ? prev + 1 : 1
                )
            }
        }

        document.addEventListener('keydown', callback)

        return () => {
            document.removeEventListener('keydown', callback)
        }
    }, [activeTag])

    const checkToDoMessage = (value: string) => {
        const isStar = value.includes('*')
        if (isStar) {
            return value.split('').filter(item => item === '*').length === 1
        }
    }

    const typeErrorToDo = () => {
        const fixedDate = new Date()
        const errorMessage = {
            text:
                `Используйте для описания слов и действий персонажа: в формате: слово*действие [действие должно отвечать на вопрос "что сделав?"].

Например:
Ура, получилось!*радостно вскинув руки`,
            type: 'error',
            staticId: '000000',
            name: null,
            gender: 'male',
            sendedAt: fixedDate,
        }
        setErrorMessages((prev) => prev ? [...prev, errorMessage] : [errorMessage]);
        setValue('')
    }

    const timerRef = useRef<ReturnType<typeof setTimeout>>()

    const onTyping = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        const textarea = e.target;
        const lineHeight = 18;
        textarea.style.height = '18px';
        const hasFilledFirstLine = textarea.scrollHeight > lineHeight;
        const newHeight = hasFilledFirstLine ? Math.min(textarea.scrollHeight, 35) : lineHeight;
        textarea.style.height = `${newHeight}px`;

        setValue(e.target.value)
        clearTimeout(timerRef.current)
        clearTimeout(activeChatTimerRef.current)
        setTyping(true)
    }

    const [print/*, { data, error, isLoading }*/] = usePrintMutation();

    const activeChatTimerRef = useRef<ReturnType<typeof setTimeout>>()

    useEffect(() => {
        if (isTyping !== undefined) {
            print({ status: isTyping });
        }
    }, [isTyping, print]);


    useEffect(() => {
        let timer: ReturnType<typeof setTimeout>
        if (!chatStatus) timer = setTimeout(() => setChatActive(false), 30000)
        else setChatActive(true)
        return () => clearTimeout(timer)

    }, [chatStatus])

    useEffect(() => {
        if (value[0] === '/' && value.length > 1) {
            const commandEndIndex = value.split('').findIndex(item => item === ' ')
            if (commandEndIndex !== -1) {
                const foundTag = CHAT_TAGS.find(item => item.text.toUpperCase() === value.toUpperCase().slice(1, -1))?.id
                if (foundTag) {
                    setActiveTag(foundTag)
                    setValue('')
                }
            }
        }
    }, [value])

    const { data: serverMessages = [] /*, error:messageError, isLoading:messageIsLoading*/ } = useMessagesQuery();

    const [errorMessages, setErrorMessages] = React.useState<MessagesProps[]>([])
    const messagesPool = [...serverMessages, ...errorMessages].sort((a, b) => new Date(a.sendedAt).getTime() - new Date(b.sendedAt).getTime())

    const activeTagText = CHAT_TAGS.find(item => item.id === activeTag)?.text ?? ''

    const lastMessageRef = useRef<HTMLDivElement | null>(null)

    useEffect(() => {
        lastMessageRef.current?.scrollIntoView({ behavior: 'smooth' });
    }, [messagesPool.length])

    const [, setViewMessage] = useState<number>(messagesPool.length)

    useEffect(() => {
        const handleScroll = (e: KeyboardEvent) => {
            setViewMessage((prev) => {
                // Проверяем нажатую клавишу
                if (e.key === 'ArrowUp') {
                    // Если "стрелка вверх" и не первое сообщение
                    return Math.max(prev - 1, 0);
                } else if (e.key === 'ArrowDown') {
                    // Если "стрелка вниз" и не последнее сообщение
                    return Math.min(prev + 1, messagesPool.length - 1);
                }
                return prev;
            });
        };

        document.addEventListener('keydown', handleScroll);
        return () => document.removeEventListener('keydown', handleScroll);
    }, [messagesPool]);

    const getTagBackground = (value: string, selectedTag: boolean) => {
        const tag = value.toUpperCase()
        if (tag === 'ME') {
            return selectedTag ? stylesBase.tagMe : stylesBase.tagItemMe
        } else if (tag === 'DO') {
            return selectedTag ? stylesBase.tagDo : stylesBase.tagItemDo
        } else if (tag === 'B') {
            return selectedTag ? stylesBase.tagB : stylesBase.tagItemB
        } else if (tag === 'W') {
            return selectedTag ? stylesBase.tagW : stylesBase.tagItemW
        } else if (tag === 'S') {
            return selectedTag ? stylesBase.tagS : stylesBase.tagItemS
        } else {
            return null
        }
    }

    const handleFindTag = (value: string) => {
        const possibleTag = value.slice(0, -1)
        const findedTag = CHAT_TAGS.find(item => item.text === possibleTag.toUpperCase())
        if (findedTag) {
            setActiveTag(findedTag.id)
        }
    }

    useEffect(() => {
        if (value[0] === '/') {
            handleFindTag(value)
        }
    }, [value])

    const messageRefs = useRef<HTMLDivElement[]>([]);
    const textareaRef = useRef<HTMLTextAreaElement | null>(null)

    useEffect(() => {
        const textarea = textareaRef.current
        if(textarea){
            textarea.focus()
        }
    }, [])

    useEffect(() => {
        messageRefs.current = messagesPool.map((_, i) => messageRefs.current[i] || React.createRef());
    }, [messagesPool]);

    // const defaultToDo = [
    //     {
    //         text:'Ура, получилось!*радостно вскинув руки',
    //         type:'b',
    //         staticId:'123456',
    //         name:null,
    //         gender:'male',
    //         sendedAt: new Date(),
    //     }
    // ]

    return (
        <div className={cn(stylesBase.chat)}>
            <div className={cn(stylesBase.chatListOverflow, !isChatActive && stylesBase.chatListOverflowInactive)}>
                <div className={stylesBase.chatList}>
                    {messagesPool?.map((item: any, index: number) => (
                        <Message {...item} key={item.text + index} ref={(el: HTMLDivElement) => (messageRefs.current[index] = el)} />
                    ))}
                    <div ref={lastMessageRef}></div>
                </div>
            </div>
            <div className={cn(stylesBase.chatInput, !chatStatus && stylesBase.chatInputHide)}> {/*//добавить ! in chatStatus*/}
                <form onSubmit={handleSubmit}>
                    <div className={stylesBase.chatInfo}>
                        <textarea
                        ref={textareaRef}
                            className={stylesBase.textarea}
                            value={value}
                            onChange={(e) => onTyping(e)}
                            placeholder='Введите сообщение...'
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') {
                                    e.preventDefault(); // Предотвращаем добавление новой строки
                                    handleSubmit(e as any); // Вызываем обработчик отправки
                                } else {
                                    handlePrevCommandHistory(e)
                                }
                            }}
                        />
                    </div>
                    <div className={stylesBase.buttonBlock}>
                        <div className={stylesBase.tab}>TAB</div>
                        <div className={cn(stylesBase.tag, getTagBackground(activeTagText, true))}
                            onClick={() => setActiveTag(prev => activeTag < CHAT_TAGS.length ? prev + 1 : 1)}
                        >
                            {activeTagText}
                        </div>
                    </div>
                </form>
            </div>
        </div>
    )
}
