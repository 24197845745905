import * as React from 'react'
import { SVGProps } from 'react'

export const Trading = (props: SVGProps<SVGSVGElement>) => (
    <svg
        width="108"
        height="97"
        viewBox="0 0 108 97"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="M30.6375 0.258806C29.6857 0.679375 28.9774 1.54265 28.7339 2.53873C28.6232 3.00357 28.5347 4.9072 28.5347 6.74442V10.1311H61.4054H94.2762V6.19104C94.2762 1.87468 94.1434 1.3213 92.7931 0.458023C92.2176 0.0817242 90.6682 0.0595894 61.7375 0.0153189C36.6804 -0.0289516 31.1909 0.0153189 30.6375 0.258806ZM55.7831 3.91111C56.3365 4.5973 56.3143 5.65979 55.7167 6.30171L55.2519 6.81082H47.8808C43.8301 6.81082 40.3549 6.74442 40.1556 6.67801C39.7572 6.52307 39.1596 5.46058 39.1596 4.92933C39.1596 4.73012 39.4473 4.26528 39.8236 3.91111L40.4655 3.24706L47.9251 3.31346L55.3625 3.37987L55.7831 3.91111ZM83.2307 3.91111C83.7841 4.5973 83.762 5.65979 83.1643 6.30171L82.6995 6.81082H75.3285C71.2777 6.81082 67.8025 6.74442 67.6033 6.67801C67.072 6.45666 66.5851 5.3499 66.74 4.70798C66.8064 4.39809 67.1385 3.95538 67.4483 3.68976C68.0017 3.26919 68.2673 3.24706 75.417 3.31346L82.8102 3.37987L83.2307 3.91111Z"
            fill={props.color}
        />
        <path
            d="M23.1779 23.1465L17.467 32.5983L27.0294 32.6647C32.2755 32.6868 36.5919 32.6868 36.6361 32.6425C36.6583 32.6204 37.9642 28.459 39.4916 23.4343C41.041 18.3875 42.347 14.1375 42.4134 13.9604C42.5019 13.7169 41.3066 13.6727 35.7064 13.6727L28.8667 13.6948L23.1779 23.1465Z"
            fill={props.color}
        />
        <path
            d="M46.1764 13.8278C45.9993 14.0934 40.4876 32.2221 40.4876 32.4877C40.4876 32.6427 43.5644 32.687 50.1829 32.6648L59.8559 32.5984L59.9223 23.1246L59.9666 13.6728H53.1268C49.3417 13.6728 46.2207 13.7392 46.1764 13.8278Z"
            fill={props.color}
        />
        <path
            d="M63.5082 23.1906V32.7087H73.2477H82.9651L82.6552 31.6463C82.4781 31.0707 81.1721 26.7986 79.7555 22.1281L77.1657 13.6725H70.3259H63.5082V23.1906Z"
            fill={props.color}
        />
        <path
            d="M80.9951 13.9162C80.9951 14.204 86.5067 32.2663 86.6617 32.5319C86.8166 32.7754 105.831 32.7533 105.742 32.5098C105.72 32.3991 103.152 28.127 100.053 22.9917L94.4311 13.6727H87.702C82.9651 13.6727 80.9951 13.7392 80.9951 13.9162Z"
            fill={props.color}
        />
        <path
            d="M16.4931 36.7376C16.7809 37.7336 17.3121 38.5526 18.3082 39.6151C22.0269 43.5773 28.9553 43.9094 33.3159 40.3456C34.312 39.5266 35.5073 37.8222 35.7286 36.8482L35.8836 36.2506H26.0998H16.3382L16.4931 36.7376Z"
            fill={props.color}
        />
        <path
            d="M40.1777 36.7376C40.4655 37.7336 40.9967 38.5526 41.9928 39.6151C45.7115 43.5773 52.6399 43.9094 57.0005 40.3456C57.9966 39.5266 59.1919 37.8222 59.4132 36.8482L59.5682 36.2506H49.7844H40.0228L40.1777 36.7376Z"
            fill={props.color}
        />
        <path
            d="M63.8624 36.7376C64.1502 37.7336 64.6814 38.5526 65.6775 39.6151C69.3962 43.5773 76.3245 43.9094 80.6852 40.3456C81.6812 39.5266 82.8765 37.8222 83.0979 36.8482L83.2528 36.2506H73.4691H63.7075L63.8624 36.7376Z"
            fill={props.color}
        />
        <path
            d="M87.5471 36.7374C88.4989 39.8806 92.0627 42.3819 96.1356 42.8024C98.8803 43.0902 102.245 42.072 104.37 40.3454C105.366 39.5264 106.561 37.822 106.783 36.8481L106.938 36.2504H97.1538H87.3922L87.5471 36.7374Z"
            fill={props.color}
        />
        <path
            d="M36.2819 42.2491C34.9538 43.5994 34.2455 44.1085 32.7403 44.8389C30.4161 45.9678 27.7599 46.5212 25.5021 46.3884L23.8862 46.2777V48.8897V51.5016L29.8185 51.568C35.5736 51.6344 35.7507 51.6566 36.7246 52.1657C38.1634 52.9404 39.071 53.6709 39.8236 54.6891L40.4876 55.5966V54.5563V53.5159L61.6931 53.5602L82.8765 53.6266V56.0615V58.4964L62.158 58.5628L41.4394 58.607L41.2845 59.7359C40.9525 62.2815 37.8093 75.4962 37.3666 76.3152L36.8796 77.1563L37.6986 78.7943C38.8275 81.0964 38.9603 82.8893 38.1413 85.0365C38.0306 85.3464 38.2741 85.3906 40.2441 85.3906H42.4798V73.7696V62.1487H51.2232H59.9666V73.7696V85.3906H61.7374H63.5082V73.7696V62.1487H72.141H80.7737V73.7696V85.3906H90.1812H99.5886L99.5443 65.8895L99.4779 46.3884L96.9324 46.322C92.6824 46.2335 89.6278 44.9496 86.8609 42.1384L85.3114 40.5447L83.9612 42.0056C82.3453 43.71 80.4417 44.9053 78.0068 45.7022C76.4795 46.2113 75.8597 46.2777 73.4691 46.2777C71.0563 46.2777 70.4587 46.2113 68.9092 45.7022C66.6514 44.9496 64.128 43.3337 62.7556 41.7179L61.6931 40.5004L60.2986 41.9614C58.6606 43.71 56.757 44.9053 54.3221 45.7022C52.7948 46.2113 52.175 46.2777 49.7844 46.2777C47.3717 46.2777 46.774 46.2113 45.2246 45.7022C43.011 44.9718 40.5319 43.378 39.0488 41.7621L37.9421 40.5447L36.2819 42.2491Z"
            fill={props.color}
        />
        <path
            d="M0.533616 47.8715C-0.13044 48.5134 -0.17471 49.5095 0.400805 50.2178C0.777104 50.6826 1.04273 50.749 2.725 50.8376C3.76536 50.8597 4.62863 50.9261 4.62863 50.9483C4.62863 50.9483 5.71325 55.7737 7.01923 61.6617L9.43197 72.353L8.85645 72.7957C8.03745 73.4376 7.32912 74.6993 7.15204 75.7618C6.75361 78.4623 9.0778 80.9636 11.9554 80.9636H12.708L12.6416 82.0482C12.4866 84.1511 13.7926 85.4792 15.8733 85.3242C17.7105 85.1914 18.9501 83.7526 18.7288 81.9154L18.6181 80.9636H23.9084H29.1766L28.9773 81.6276C28.4682 83.4206 30.0177 85.3906 31.9213 85.3906C34.7546 85.3906 36.1934 81.9818 34.1791 80.0782C33.8914 79.8126 33.7143 79.4584 33.7586 79.3034C33.9135 78.905 33.2937 77.7318 32.851 77.5548C32.6518 77.4884 27.7156 77.422 21.9162 77.422C11.6012 77.422 11.3356 77.3998 10.9372 76.9792C10.6051 76.603 10.583 76.448 10.7822 75.9832L11.0478 75.4298H21.9826C28.3797 75.4298 33.1609 75.3412 33.4929 75.2084C33.8028 75.0978 34.157 74.81 34.2898 74.5444C34.7989 73.6147 37.8536 59.249 37.6986 58.4964C37.5215 57.4781 36.9018 56.5485 35.9278 55.818L35.1088 55.1761L22.1376 55.0654L9.18848 54.9547L8.92286 53.848C8.76791 53.2282 8.43588 51.7451 8.14813 50.5277C7.4398 47.3624 7.37339 47.3181 3.8539 47.3181C1.19767 47.3181 1.04273 47.3402 0.533616 47.8715Z"
            fill={props.color}
        />
        <path
            d="M50.8248 67.7268C50.5149 68.1252 49.4745 69.8739 48.4785 71.6004C46.5748 74.9429 46.3535 75.7619 47.1282 76.5366C47.7037 77.1121 49.1425 77.1342 49.6074 76.5809C50.2936 75.8061 53.9901 69.0992 53.9901 68.6565C53.9901 68.3908 53.7909 67.926 53.5253 67.594C53.1711 67.1513 52.8834 67.0185 52.2193 67.0185C51.511 67.0185 51.2675 67.1513 50.8248 67.7268Z"
            fill={props.color}
        />
        <path
            d="M70.3259 67.2842C69.7725 67.7048 65.7217 74.8544 65.7217 75.3857C65.7217 76.1604 66.6293 77.0016 67.4483 77.0237C68.4665 77.0458 68.8871 76.581 71.1227 72.6631C73.1592 69.0993 73.4027 68.2803 72.5837 67.4613C72.0745 66.9522 70.8792 66.8636 70.3259 67.2842Z"
            fill={props.color}
        />
        <path
            d="M54.7206 69.4312C53.968 70.1396 50.2271 76.8687 50.2271 77.5106C50.2271 78.2189 51.1125 79.1929 51.7544 79.1929C52.8169 79.1929 53.2818 78.6838 55.3846 75.0093C56.6685 72.8179 57.5317 71.0471 57.5317 70.693C57.5317 69.3427 55.6945 68.5237 54.7206 69.4312Z"
            fill={props.color}
        />
        <path
            d="M74.0003 69.3206C73.292 69.8297 69.4183 76.7137 69.4183 77.4885C69.4183 78.2853 70.348 79.1929 71.1449 79.1929C71.986 79.1929 72.6279 78.396 74.6865 74.788C76.8558 71.0028 77.0107 70.3609 76.0368 69.5198C75.3506 68.9443 74.6644 68.8779 74.0003 69.3206Z"
            fill={props.color}
        />
        <path
            d="M15.6076 89.2865C15.3199 89.5521 15.2535 90.1719 15.2535 92.4519C15.2535 94.9089 15.2977 95.3294 15.6519 95.6393C16.0282 95.9935 19.5477 96.0156 61.6046 96.0156C92.5717 96.0156 107.292 95.9492 107.579 95.7943C107.956 95.5729 108 95.2852 108 92.474C108 89.6628 107.956 89.3751 107.579 89.1537C107.292 88.9988 92.5496 88.9324 61.5382 88.9324C20.3888 88.9324 15.9175 88.9766 15.6076 89.2865Z"
            fill={props.color}
        />
    </svg>
)