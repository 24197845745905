import React from 'react'
import styles from './HudQuests.module.scss'
import { Quest } from './HudQuests.typings'
import arrow from './assets/arrow.svg'
import cn from 'classnames'

export default function HudQuests({quests}:{quests:Quest[]}){
    return(
        <div className={styles.wrapper}>
            {
                quests.map((item, index) => {
                    const colors = ['#FFD43E', '#FF6BFD', '#FF7B3E']

                    return(
                        <div className={styles.quest} key={item.id}>
                            <div className={styles.circleContainer}>
                                <div className={styles.circle} style={{background:colors[index]}}>{item.id}</div>
                                <div className={styles.arrowContainer} style={{rotate:`${item.direction}deg`}}>
                                    <img src={arrow} className={styles.arrow} />
                                </div>
                            </div>
                            <div className={styles.info}>
                                <div className={styles.title}>{item.title}</div>
                                <div className={styles.stages}>
                                    {
                                        item.stages.map(stage => {
                                            return(
                                                <div className={cn(styles.stage, stage.isCompleted && styles.stageCompleted)} key={stage.id}>{stage.title}</div>
                                            )
                                        })
                                    }
                                </div>
                            </div>
                        </div>
                    )
                })
            }
        </div>
    )
}