import headIcon from '../assets/headIcon.svg'
import backIcon from '../assets/backIcon.svg'
import bodyIcon from '../assets/bodyIcon.svg'
import leftArmIcon from '../assets/leftArmIcon.svg'
import rightArmIcon from '../assets/rightArmIcon.svg'
import leftLegIcon from '../assets/leftLegIcon.svg'
import rightLegIcon from '../assets/rightLegIcon.svg'
import tattooImage from '../assets/tattooImage.png'
import hamburgerPic from '../../Store/assets/hamburgerPic.png'

export const previewTattooShop = {
    categories: [
        {
            key: 'head',
            title: 'Голова',
            icon: headIcon
        },
        {
            key: 'back',
            title: 'Спина',
            icon: backIcon
        },
        {
            key: 'body',
            title: 'Перед',
            icon: bodyIcon
        },
        {
            key: 'leftArm',
            title: 'Левая рука',
            icon: leftArmIcon
        },
        {
            key: 'rightArm',
            title: 'Правая рука',
            icon: rightArmIcon
        },
        {
            key: 'leftLeg',
            title: 'Левая нога',
            icon: leftLegIcon
        },
        {
            key: 'rightLeg',
            title: 'Правая нога',
            icon: rightLegIcon
        }
    ],
    head:[
        { id: '1', price: 20000, image: tattooImage, description: 'Lorem Ipsum is simply dummy text of the', sale: { isSale: true, oldPrice: 54000 }, color: '2' },
        { id: '2', price: 54000, image: tattooImage, description: 'Lorem Ipsum is simply dummy text of the', sale: { isSale: false }, color: '2', unavailable: true },
        { id: '3', price: 4000, image: tattooImage, description: 'Lorem Ipsum is simply dummy text of the', sale: { isSale: false }, color: 'red', special: true },
        { id: '4', price: 34000, image: tattooImage, description: 'Lorem Ipsum is simply dummy text of the', sale: { isSale: false }, color: '2' },
        { id: '5', price: 54000, image: tattooImage, description: 'Lorem Ipsum is simply dummy text of the', sale: { isSale: false }, color: '2', bought: true },
        { id: '6', price: 54000, image: tattooImage, description: 'Lorem Ipsum is simply dummy text of the', sale: { isSale: false }, color: '2' },
        { id: '7', price: 54000, image: tattooImage, description: 'test for search', sale: { isSale: false }, color: '2' },
        { id: '8', price: 54000, image: tattooImage, description: 'Lorem Ipsum is simply dummy text of the', sale: { isSale: false }, color: '2' },
        { id: '9', price: 54000, image: tattooImage, description: 'Lorem Ipsum is simply dummy text of the', sale: { isSale: false }, color: '2' },
    ],
    back:[
        { id: '1', price: 20000, image: hamburgerPic, description: 'Lorem Ipsum is simply dummy text of the', sale: { isSale: true, oldPrice: 54000 }, color: '2' },
        { id: '2', price: 54000, image: hamburgerPic, description: 'Lorem Ipsum is simply dummy text of the', sale: { isSale: false }, color: '2' },
        { id: '3', price: 54000, image: hamburgerPic, description: 'Lorem Ipsum is simply dummy text of the', sale: { isSale: false }, color: 'red' },
        { id: '4', price: 34000, image: hamburgerPic, description: 'Lorem Ipsum is simply dummy text of the', sale: { isSale: false }, color: '2' },
        { id: '5', price: 54000, image: hamburgerPic, description: 'Lorem Ipsum is simply dummy text of the', sale: { isSale: false }, color: '2' },
        { id: '6', price: 54000, image: hamburgerPic, description: 'Lorem Ipsum is simply dummy text of the', sale: { isSale: false }, color: '2' },
        { id: '7', price: 54000, image: hamburgerPic, description: 'test for search', sale: { isSale: false }, color: '2' },
        { id: '8', price: 54000, image: hamburgerPic, description: 'Lorem Ipsum is simply dummy text of the', sale: { isSale: false }, color: '2' },
        { id: '9', price: 54000, image: hamburgerPic, description: 'Lorem Ipsum is simply dummy text of the', sale: { isSale: false }, color: '2' },
    ],
    storeType:'tattooShop',
    select:[{ key: 'fromLowerToUpper', title: 'От дешевых к дорогим' }, { key: 'fromUpperToLower', title: 'От дорогих к дешевым' }],
    playerBalance: 1000000
}
