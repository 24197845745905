import React from 'react'
import styles from './VoiceChat.module.scss'
import microphoneIconInactive from './assets/microphoneIconInactive.svg'
import microphoneIconActive from './assets/microphoneIconActive.svg'
//import telIconActive from './assets/telIconActive.svg'
import telIconInactive from './assets/telIconInactive.svg'
import { useVoiceChangeQuery, useVoiceSetQuery } from './api/VoiceChatApi'

enum VoiceTypes{
    talk = 'Разговор',
    scream = 'Крик',
    whisper = 'Шепот'
}

export default function VoiceChat() {
    const { data: isActive } = useVoiceChangeQuery()
    const { data: type = 'talk' } = useVoiceSetQuery()

    return (
        <div className={styles.wrapper}>
            <div className={styles.itemContainer}>
                <div className={styles.iconContainer}>
                    <div className={styles.buttonName}>N</div>
                    <img src={isActive ? microphoneIconActive : microphoneIconInactive} className={styles.icon}/>
                </div>
                <div className={styles.title}>{VoiceTypes[type as keyof typeof VoiceTypes]}</div>
            </div>
            <div className={styles.itemContainer}>
                <div className={styles.iconContainer}>
                    <div className={styles.buttonName}>R</div>
                    <img src={telIconInactive} className={styles.icon} />
                </div>
                <div className={styles.title}>TAC</div>
            </div>
        </div>
    )
}