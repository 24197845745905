interface GetWheelCoordsArgs {
    totalCount?: number
    radius: number
    bias?: number
    count: number
    rotate?: number
}

interface Item {
    x: number
    y: number
}

type GetWheelCoords = (args: GetWheelCoordsArgs) => Item[]

export const getWheelCoords: GetWheelCoords = ({
    totalCount = 15,
    radius,
    bias = 15,
    count,
    rotate = 0,
}) => {
    const angle = (2 * Math.PI) / totalCount
    const x0 = radius
    const y0 = radius

    const rotateStep = (rotate * totalCount) / 360

    return Array.from({ length: count }, (_, i) => {
        return {
            x:
                x0 +
                radius *
                    Math.cos((i - totalCount / 4 + 0.5 + rotateStep) * angle) +
                bias,
            y:
                y0 +
                radius *
                    Math.sin((i - totalCount / 4 + 0.5 + rotateStep) * angle) +
                bias,
        }
    })
}
