import React, { ReactElement } from 'react'
import styles from './PosScreen.module.scss'

export default function PosScreen({
    children,
}:{
    children:ReactElement
}){
    return(
        <div className={styles.wrapper}>
            {children}
        </div>
    )
}