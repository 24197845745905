import * as React from 'react'
import { SVGProps } from 'react'

export const MedInsurance = (props: SVGProps<SVGSVGElement>) => (
    <svg
        width="1em"
        height="1em"
        viewBox="0 0 34 44"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M33.0221 32.3305L21.9537 43.2725L3.94128 43.2725C2.28443 43.2725 0.941284 41.9293 0.941284 40.2725L0.941284 3.36927C0.941284 1.71242 2.28443 0.36927 3.94128 0.36927L30.0221 0.369269C31.6789 0.369269 33.0221 1.71242 33.0221 3.36927L33.0221 32.3305ZM14.9152 7.41819C14.9152 6.86591 15.3629 6.41819 15.9152 6.41819L18.0419 6.41819C18.5942 6.41819 19.0419 6.86591 19.0419 7.41819L19.0419 10.1892L21.8128 10.1892C22.365 10.1892 22.8128 10.6369 22.8128 11.1892L22.8128 13.3159C22.8128 13.8682 22.365 14.3159 21.8128 14.3159L19.0419 14.3159L19.0419 17.0867C19.0419 17.639 18.5942 18.0867 18.0419 18.0867L15.9152 18.0867C15.3629 18.0867 14.9152 17.639 14.9152 17.0867L14.9152 14.3159L12.1443 14.3159C11.592 14.3159 11.1443 13.8682 11.1443 13.3159L11.1443 11.1892C11.1443 10.6369 11.592 10.1892 12.1443 10.1892L14.9152 10.1892L14.9152 7.41819ZM5.33867 24.9135C5.33867 24.2979 5.83773 23.7988 6.45334 23.7988L27.4662 23.7988C28.0818 23.7988 28.5809 24.2979 28.5809 24.9135C28.5809 25.5291 28.0818 26.0282 27.4662 26.0282L6.45335 26.0282C5.83773 26.0282 5.33867 25.5291 5.33867 24.9135ZM6.41367 29.3626C5.79805 29.3626 5.299 29.8617 5.299 30.4773C5.299 31.0929 5.79805 31.592 6.41367 31.592L18.1771 31.592C18.7927 31.592 19.2917 31.0929 19.2917 30.4773C19.2917 29.8617 18.7927 29.3626 18.1771 29.3626L6.41367 29.3626ZM5.2592 36.0749C5.2592 35.4593 5.75826 34.9603 6.37387 34.9603L18.1373 34.9603C18.7529 34.9603 19.2519 35.4593 19.2519 36.0749C19.2519 36.6906 18.7529 37.1896 18.1373 37.1896L6.37387 37.1896C5.75826 37.1896 5.2592 36.6906 5.2592 36.0749ZM22.3828 38.9565L28.5917 32.8075L25.3828 32.8075C23.726 32.8075 22.3828 34.1507 22.3828 35.8075L22.3828 38.9565Z"
            fill={props.color}
        />
    </svg>
)
