import { useEffect, useState } from 'react'
import rpc from 'altv-rpc'
import { useNavigate } from 'react-router'

export const useRpcNavigation = () => {

    const navigate = useNavigate()
    const [params, setParams] = useState<any>(null)

    const callback = ({ data }: any) => {
        data.path && navigate(data.path)
        data.params && setParams(data.params)

    }
    const event = 'route'

    useEffect(() => {
        rpc.on(event, callback)
        return () => {
            rpc.off(event, callback)
        }
    })
    return(params)
}
