import React, {useState} from 'react'
import styles from './LifeGoalSelect.module.scss'
import { Props } from './LifeGoalSelect.typings'
import GoalSelectItem from 'pages/ui/CharacterPersonalization/commonComponents/GoalSelectItem/GoalSelectItem'
import LifeGoalWidget from '../LifeGoalWidget/LifeGoalWidget'
import { GoalsType } from '../../About.config'
import { lifeGoalArr } from './LifeGoalSelect.config'

const LifeGoalSelect = (props: Props) => {

    const [checkedGoal, setCheckedGoal] = useState<number>(0)

    const handleSelectItem = (type:GoalsType,id: number) => {
        props.onSelect(type, id)
        type === GoalsType.mainGoal && setCheckedGoal(id)
    }

    return (
        <div className={styles.wrapper}>
            <LifeGoalWidget
                isTitle={false}
                onMainGoalSelect={props.onMainGoalSelect}
                lifeGoals={props.lifeGoals}
                characterTraits={props.characterTraits}
                onTraitSelect={props.onTraitSelect}
            />
            <div className={styles.title}>Выбрать жизненную цель</div>
            <div className={styles.flexContainer}>
                {
                    lifeGoalArr.map(item => {
                        return (
                            <GoalSelectItem
                                key={item.id}
                                id={item.id}
                                img={item.icon}
                                title={item.title}
                                lifeGoals={props.lifeGoals}
                                onSelect={handleSelectItem}
                                subGoals={item.subGoals}
                                checkedGoal={checkedGoal}
                                switchComponents={props.onMainGoalSelect}
                            />
                        )
                    })
                }
            </div>
        </div>
    )
}

export default LifeGoalSelect