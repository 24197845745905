import { rpc } from 'altv-xrpc-webview'
import { baseApi } from 'app/api/baseApi'
import { Request, Response } from './types'

export const chatApi = baseApi.injectEndpoints({
    endpoints: (builder) => ({
        response: builder.mutation<Response, Response>({
            async queryFn(data) {
                try {
                    const res = await rpc.emitClient('interaction:response', data)
                    return { data: res }
                } catch (error: any) {
                    return { error }
                }
            },
        }),
        interactionRequest: builder.query<Request | null, void>({
            async queryFn() {
                return {
                    data: null
                }
            },
            async onCacheEntryAdded(
                _,
                { updateCachedData, cacheDataLoaded, cacheEntryRemoved }
            ) {
                try {
                    await cacheDataLoaded

                    const listener = (data: any) => {
                        updateCachedData(() => data as Request)
                    }
                    rpc.onServer('interaction:request', listener)
                } catch {
                    // ...
                }
                await cacheEntryRemoved
            },
        }),
        keycodeY: builder.query<{ isPressed: boolean }, void>({
            queryFn: async () => ({ data: { isPressed: false } }),
            async onCacheEntryAdded(
                _,
                { updateCachedData, cacheDataLoaded, cacheEntryRemoved }
            ) {
                try {
                    await cacheDataLoaded

                    rpc.onClient('keycode:y', () => {
                        updateCachedData((draft) => {
                            draft.isPressed = true
                        })
                    })
                } catch {
                    // ...
                }
                await cacheEntryRemoved
            },
        }),
        keycodeN: builder.query<{ isPressed: boolean }, void>({
            queryFn: async () => ({ data: { isPressed: false } }),
            async onCacheEntryAdded(
                _,
                { updateCachedData, cacheDataLoaded, cacheEntryRemoved }
            ) {
                try {
                    await cacheDataLoaded

                    rpc.onClient('keycode:n', () => {
                        updateCachedData((draft) => {
                            draft.isPressed = true
                        })
                    })
                } catch {
                    // ...
                }
                await cacheEntryRemoved
            },
        }),
    }),
})

export const {
    useResponseMutation,
    useInteractionRequestQuery,
    useKeycodeYQuery,
    useKeycodeNQuery,
} = chatApi
