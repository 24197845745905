import sportIcon from './assets/sportIcon.svg'
import createFamilyIcon from './assets/createFamilyIcon.svg'
import strengthIcon from './assets/strengthIcon.svg'
import stateIcon from './assets/stateIcon.svg'
import foodIcon from './assets/foodIcon.svg'
import criminalIcon from './assets/criminalIcon.svg'
import loveIcon from './assets/loveIcon.svg'
import hobbyIcon from './assets/hobbyIcon.svg'
import bookIcon from './assets/bookIcon.svg'
import subGoalExampleIcon from './assets/subGoalExampleIcon.svg'

export const lifeGoalArr = [
    {
        id: 1,
        icon: sportIcon,
        title: 'Спорт',
        description: 'lorem ipsum dolor sit amet',
        selected: false,
        subGoals: [
            {
                id: 1,
                icon: subGoalExampleIcon,
                description: 'lorem ipsum dolor sit amet',
            },
            {
                id: 2,
                icon: subGoalExampleIcon,
                description: 'lorem ipsum dolor sit amet',
            },
            {
                id: 3,
                icon: subGoalExampleIcon,
                description: 'lorem ipsum dolor sit amet',
            },
            {
                id: 4,
                icon: subGoalExampleIcon,
                description: 'lorem ipsum dolor sit amet',
            },
        ],
    },
    {
        id: 2,
        icon: createFamilyIcon,
        title: 'Создать семью',
        description: 'lorem ipsum dolor sit amet',
        selected: false,
        subGoals: [
            {
                id: 2,
                icon: subGoalExampleIcon,
                description: 'lorem ipsum dolor sit amet',
            },
        ],
    },
    {
        id: 3,
        icon: strengthIcon,
        title: 'Сила',
        description: 'lorem ipsum dolor sit amet',
        selected: false,
        subGoals: [
            {
                id: 3,
                icon: subGoalExampleIcon,
                description: 'lorem ipsum dolor sit amet',
            },
        ],
    },
    {
        id: 4,
        icon: stateIcon,
        title: 'Состояние',
        description: 'lorem ipsum dolor sit amet',
        selected: false,
        subGoals: [
            {
                id: 4,
                icon: subGoalExampleIcon,
                description: 'lorem ipsum dolor sit amet',
            },
        ],
    },
    {
        id: 5,
        icon: foodIcon,
        title: 'Еда',
        description: 'lorem ipsum dolor sit amet',
        selected: false,
        subGoals: [
            {
                id: 5,
                icon: subGoalExampleIcon,
                description: 'lorem ipsum dolor sit amet',
            },
        ],
    },
    {
        id: 6,
        icon: criminalIcon,
        title: 'Бандит',
        description: 'lorem ipsum dolor sit amet',
        selected: false,
        subGoals: [
            {
                id: 6,
                icon: subGoalExampleIcon,
                description: 'lorem ipsum dolor sit amet',
            },
        ],
    },
    {
        id: 7,
        icon: loveIcon,
        title: 'Любовь',
        description: 'lorem ipsum dolor sit amet',
        selected: false,
        subGoals: [
            {
                id: 7,
                icon: subGoalExampleIcon,
                description: 'lorem ipsum dolor sit amet',
            },
        ],
    },
    {
        id: 8,
        icon: hobbyIcon,
        title: 'Хобби',
        description: 'lorem ipsum dolor sit amet',
        selected: false,
        subGoals: [
            {
                id: 8,
                icon: subGoalExampleIcon,
                description: 'lorem ipsum dolor sit amet',
            },
        ],
    },
    {
        id: 9,
        icon: bookIcon,
        title: 'Кругозор',
        description: 'lorem ipsum dolor sit amet',
        selected: true,
        subGoals: [
            {
                id: 9,
                icon: subGoalExampleIcon,
                description: 'lorem ipsum dolor sit amet',
            },
        ],
    },
    {
        id: 10,
        icon: bookIcon,
        title: 'Кругозор',
        description: 'lorem ipsum dolor sit amet',
        selected: true,
        subGoals: [
            {
                id: 9,
                icon: subGoalExampleIcon,
                description: 'lorem ipsum dolor sit amet',
            },
        ],
    },
]
