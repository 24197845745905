import headIcon from './assets/headIcon.svg'
import backIcon from './assets/backIcon.svg'
import bodyIcon from './assets/bodyIcon.svg'
import leftArmIcon from './assets/leftArmIcon.svg'
import rightArmIcon from './assets/rightArmIcon.svg'
import leftLegIcon from './assets/leftLegIcon.svg'
import rightLegIcon from './assets/rightLegIcon.svg'

export const categories=[
    {
        key: 'head',
        title: 'Голова',
        icon: headIcon
    },
    {
        key: 'back',
        title: 'Спина',
        icon: backIcon
    },
    {
        key: 'body',
        title: 'Перед',
        icon: bodyIcon
    },
    {
        key: 'leftArm',
        title: 'Левая рука',
        icon: leftArmIcon
    },
    {
        key: 'rightArm',
        title: 'Правая рука',
        icon: rightArmIcon
    },
    {
        key: 'leftLeg',
        title: 'Левая нога',
        icon: leftLegIcon
    },
    {
        key: 'rightLeg',
        title: 'Правая нога',
        icon: rightLegIcon
    }
]