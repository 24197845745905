import React, { useState } from 'react'
import { TabsPanel } from 'shared/ui/TabsPanel/TabsPanel'
import styles from './AuthForm.module.scss'
// import { LoginForm } from './components/LoginForm/LoginForm'
import { Gap } from 'shared/ui/Gap/Gap'
import { RegistrationForm } from './components/RegistrationForm/RegistrationForm'
import { LoginForm } from './components/LoginForm/LoginForm'

enum AuthType {
    Login,
    Registation,
}

export const authTabs = [
    {
        title: 'Авторизация',
        value: AuthType.Login,
    },
    {
        title: 'Регистрация',
        value: AuthType.Registation,
    },
]

const authTitles = {
    [AuthType.Login]: {
        main: "Авторизация",
        description: "Добро пожаловать на сервер. Войдите под своим аккаунтом или зарегистрируйте новый"
    },
    [AuthType.Registation]: {
        main: "Регистрация",
        description: "Добро пожаловать на сервер, вам необходимо пройти регистрацию"
    }
}

export const AuthForm: React.FC = () => {
    const [activeTab, setActiveTab] = useState(AuthType.Login)
    const titles = authTitles[activeTab]

    return (
        <div className={styles.container}>
            <div className={styles.title}>{titles.main}</div>
            <div className={styles.description}>{titles.description}</div>
            <TabsPanel tabs={authTabs} value={activeTab} onChange={(value) => setActiveTab(value)} />
            <Gap size={24} />
            {activeTab === AuthType.Login ? <LoginForm /> :  <RegistrationForm onSuccess={() => setActiveTab(AuthType.Login)} />}
        </div>
    )
}
