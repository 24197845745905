import React from 'react'

export const handleMouseDownPhone = (
    e: React.MouseEvent,
    phoneRef: React.MutableRefObject<HTMLDivElement | null>,
    posRef: React.MutableRefObject<HTMLDivElement | null>,
    onSuccessPay:(isCash:boolean) => void,
) => {
    const phone = phoneRef.current
    const initialTop = phone?.getBoundingClientRect().top
    const initialLeft = phone?.getBoundingClientRect().left
    if (phone) {
        phone.ondragstart = function () {
            return false
        }
        const shiftX = e.clientX - phone.getBoundingClientRect().left
        const shiftY = e.clientY - phone.getBoundingClientRect().top
        phone.style.zIndex = '1000'
        phone.style.left = `${e.clientX - shiftX}px`
        phone.style.top = `${e.clientY - shiftY}px`

        const onMouseMove = (moveEvent: MouseEvent) => {
            phone.style.left = `${moveEvent.clientX - shiftX}px`
            phone.style.top = `${moveEvent.clientY - shiftY}px`
        }

        const onMouseUp = () => {
            if (posRef.current) {
                const posRect = posRef.current.getBoundingClientRect()
                const phoneRect = phone.getBoundingClientRect()
                const isOverPos =
                    phoneRect.top > posRect.top - 100 &&
                    phoneRect.left > posRect.left - 100 &&
                    phoneRect.bottom < posRect.bottom + 100 &&
                    phoneRect.right < posRect.right + 100

                if (isOverPos) {
                    onSuccessPay(false)
                } else {
                    phone.style.top = initialTop + 'px'
                    phone.style.left = initialLeft + 'px'
                }
            }
            document.removeEventListener('mousemove', onMouseMove)
            document.removeEventListener('mouseup', onMouseUp)
        }

        document.addEventListener('mousemove', onMouseMove)
        document.addEventListener('mouseup', onMouseUp)
    }
}
