import React, { ChangeEvent } from 'react'
import styles from './PlayerInfoForm.module.scss'
import Select from 'shared/ui/Input/Select/Select'
import { InputButton } from 'shared/ui/InputButton/InputButton'
import { Cubes } from 'shared/icons/generated'
import classNames from "classnames";
import Field from "shared/ui/Input/Field/Field";
import { GenderSelect } from "widgets/ui/GenderSelect/GenderSelect";
import { Gender } from "widgets/ui/GenderSelect/GenderSelect.typings";
import { weightRange, heightRange, monthArr, daysArr, nationalities } from './PlayerInfoForm.config'
import { OptionsType } from 'shared/ui/Input/Select/Select.typings'
import GaitSelect from 'pages/ui/CharacterPersonalization/screens/About/components/GaitSelect/GaitSelect'
import { gaitVariants } from './PlayerInfoForm.config'
import { Props } from './PlayerInfoForm.typings'
import LifeGoalWidget from '../../../pages/ui/CharacterPersonalization/screens/About/components/LifeGoalWidget/LifeGoalWidget'
import { getRandomFullName } from 'pages/ui/CharacterPersonalization/screens/About/utils/getRandomFullName'
import { createRange } from './utils/createRange'


export const PlayerInfoForm = (props: Props) => {

    const nationalityArr = props.aboutSettings.gender === Gender.male ? Array.from(nationalities, item => item.titles.male) : Array.from(nationalities, item => item.titles.female)

    const handleSelectDayOfBirth = (value: string) => {
        if (props.aboutSettings.monthOfBirth === 'Февраль') {
            if (+value <= 28) props.onChange('dayOfBirth', value)
        } else if (props.aboutSettings.monthOfBirth === 'Апрель' || props.aboutSettings.monthOfBirth === 'Июнь' || props.aboutSettings.monthOfBirth === 'Сентябрь' || props.aboutSettings.monthOfBirth === 'Ноябрь') {
            if (+value <= 30) props.onChange('dayOfBirth', value)
        } else props.onChange('dayOfBirth', value)
    }

    const handleSelectMonthOfBirth = (value: string) => {
        props.onChange('monthOfBirth', value)
    }

    const handleFirstNameInput = (value: string) => {
        props.onChange('firstName', value.length > 15 ? value = value.substring(0, 14) : value)
    }

    const handleLastNameInput = (value: string) => {
        props.onChange('lastName', value.length > 15 ? value = value.substring(0, 14) : value)
    }

    const handleGenderSelect = (gender: Gender) => {
        props.onChange('gender', gender)
    }

    const weight: OptionsType[] = createRange(weightRange, 1)
    const height: OptionsType[] = createRange(heightRange, 0.1)

    const handleSelectWeight = (value: number) => {
        props.onChange('weight', value)
    }

    const handleSelectedHeight = (value: number) => {
        props.onChange('height', value)
    }

    const handleGaitSelect = (id: number) => {
        const selectedGait = gaitVariants.find(item => item.id === id)
        selectedGait && props.onChange('gait', selectedGait)
    }

    const handleAgeSelect = (event: ChangeEvent<HTMLInputElement>) => {
        const input = event.target.value.replace(/[^\d]/g, '')
        if (input.length < 2) props.onChange('age', input)
        else {
            if (input.length > 0 && +input >= 18 && +input <= 65) props.onChange('age', input)
        }
    }

    const handleNationalitySelect = (value: string) => {
        props.onChange('nationality', value)
    }

    const getName = () => {
        const fullName = getRandomFullName(props.gender, props.nationality)
        props.randomName(fullName.firstName, fullName.lastName)
    }


    return (
        <div className={styles.container}>
            <div className={styles.block}>
                <div className={classNames(styles.column, styles.nameBlock)}>
                    <div className={styles.title}>Имя</div>
                    <Field onChange={(value) => { handleFirstNameInput(value) }} value={props.aboutSettings.firstName} placeholder={"Имя (на английском)"} />
                </div>
                <div className={styles.column}>
                    <div className={styles.title}>Фамилия</div>
                    <div className={styles.row}>
                        <div className={styles.name}>
                            <Field onChange={(value) => { handleLastNameInput(value) }} value={props.aboutSettings.lastName} placeholder={"Фамилия (на английском)"} />
                        </div>
                        <InputButton Icon={Cubes} onClick={getName} />
                    </div>
                </div>
            </div>
            <div className={styles.block}>
                <GenderSelect value={props.aboutSettings.gender} onSelect={handleGenderSelect} />
                <div>
                    <GaitSelect
                        selectedGait={props.aboutSettings.gait}
                        handleGaitSelect={handleGaitSelect}
                    />
                </div>
                <div className={styles.column}>
                    <div className={styles.title}>
                        Антропометрия
                    </div>
                    <div className={styles.row}>
                        <div>
                            <Select
                                options={weight}
                                onChange={handleSelectWeight}
                                placeholder={"Вес"}
                                value={props.aboutSettings.weight}
                            />
                        </div>
                        <div>
                            <Select
                                options={height}
                                placeholder={"Рост"}
                                onChange={handleSelectedHeight}
                                value={props.aboutSettings.height}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className={styles.block}>
                <div className={classNames(styles.column, styles.ageBlock)}>
                    <div className={styles.title}>Возраст</div>
                    <input
                        type='text'
                        placeholder='Возраст'
                        className={styles.age}
                        maxLength={2}
                        onChange={handleAgeSelect}
                        value={props.aboutSettings.age}
                    />
                </div>
                <div className={styles.column}>
                    <div className={styles.title}>Дата рождения</div>
                    <div className={styles.row}>
                        <Select
                            options={monthArr.map(item => {
                                return (
                                    { label: item, value: item }
                                )
                            })}
                            placeholder={"Месяц"}
                            onChange={handleSelectMonthOfBirth}
                            value={props.aboutSettings.monthOfBirth}
                            className={styles.month}
                        />
                        <Select
                            options={daysArr.map(item => {
                                return (
                                    { label: String(item), value: String(item) }
                                )
                            })}
                            placeholder={"День"}
                            onChange={handleSelectDayOfBirth}
                            value={props.aboutSettings.dayOfBirth}
                            className={styles.day}
                        />
                    </div>

                </div>
                <div className={styles.column}>
                    <div className={styles.title}>
                        Национальность
                    </div>
                    <div className={styles.nationalityBlock}>
                        <Select
                            options={nationalityArr.map(item => {
                                return (
                                    { label: item, value: item }
                                )
                            })}
                            placeholder={"Национальность"}
                            onChange={handleNationalitySelect}
                            value={props.aboutSettings.nationality}
                        />
                    </div>
                </div>
            </div>
            <div className={styles.block}>
                <div className={styles.column}>
                    <div className={styles.title}>
                        Место рождения
                    </div>
                    <div className={classNames(styles.row, styles.birthPlace)}>
                        <div className={styles.fullFlex}>
                            <Field onChange={(value) => { props.onChange('country', value) }} value={props.aboutSettings.country} placeholder={"Страна (на английском)"} />
                        </div>
                        <div className={styles.fullFlex}>
                            <Field onChange={(value) => { props.onChange('city', value) }} value={props.aboutSettings.city} placeholder={"Город (на английском)"} />
                        </div>
                    </div>
                </div>

            </div>
            <LifeGoalWidget
                isTitle={true}
                onMainGoalSelect={props.onSelect}
                lifeGoals={props.lifeGoals}
                characterTraits={props.characterTraits}
                onTraitSelect={props.onTraitSelect}
            />
        </div>
    )
}
