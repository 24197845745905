import React from 'react'
import styles from './Card.module.scss'
import chipImage from './assets/chipImage.png'
import { getFormatCardNumber } from './utils/getFormatCardNumber'

interface Props{
    background:string,
    number:number,
    date:string,
    name:string,
}

export default function CardItem(props:Props){
    return(
        <div className={styles.wrapper}>
            <img src={props.background} className={styles.background} />
            <img src={chipImage} className={styles.chipImage} />
            <div className={styles.number}>{getFormatCardNumber(props.number)}</div>
            <div className={styles.date}>{props.date}</div>
            <div className={styles.name}>{props.name}</div>
        </div>
    )
}