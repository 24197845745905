import React, { useState } from 'react'
import Slider from 'infinite-react-carousel'
import styles from './HintsCarousel.module.scss'
import classNames from 'classnames'

const items = [
    {
        text: `Role Play - игровой режим сервера, основой которого является
        отыгровка игроком выбранной роли (полицейский/бандит).
        Каждая отыгровка воспроизводится благодаря серверным
        командам: /me, /do, /todo. Также отыгровки должны не
        нарушать правила сервера, за это Вы можете получить
        наказание на сервере.`,
    },
    {
        text: `Role Play - игровой режим сервера, основой которого является
        отыгровка игроком выбранной роли (полицейский/бандит).
        Каждая отыгровка воспроизводится благодаря серверным
        командам: /me, /do, /todo. Также отыгровки должны не
        нарушать правила сервера, за это Вы можете получить
        наказание на сервере.`,
    },
    {
        text: `Role Play - игровой режим сервера, основой которого является
        отыгровка игроком выбранной роли (полицейский/бандит).
        Каждая отыгровка воспроизводится благодаря серверным
        командам: /me, /do, /todo. Также отыгровки должны не
        нарушать правила сервера, за это Вы можете получить
        наказание на сервере.`,
    },
]

export const HintsCarousel: React.FC = () => {
    const [activeIndex, setActiveIndex] = useState(0)

    return (
        <div className={styles.container}>
            <div className={styles.titleBlock}>Интересный факт</div>

            <Slider arrows={false} autoplay autoplaySpeed={7000} beforeChange={(_,newIndex) =>{
                if(newIndex) setActiveIndex(newIndex)
            }}>
                {items.map(({ text }, i) => {
                    return (
                        <div key={i} className={styles.item}>
                            {text}
                        </div>
                    )
                })}
            </Slider>
            <div className={styles.dots}>{
                items.map((_,index) => (<div key={index} className={classNames(styles.dot, index === activeIndex && styles.activeDot)}></div>))
            }</div>
        </div>
    )
}
