import { useEffect, useState } from 'react'
import { RpcResponse } from 'shared/typings/common'
import { HudInfoData } from '../HudInfo.typings'
import rpc from 'altv-rpc'

export const useHudInfoData = () => {
    const [data, setData] = useState<HudInfoData | null>(null)

    const callback = ({ data }: RpcResponse<HudInfoData>) => {
        setData(data)
    }

    const event = 'hud:info'

    useEffect(() => {
        rpc.on(event, callback)

        return () => {
            rpc.off(event, callback)
        }
    })

    return { data }
}
