import React from 'react'
import styles from './TraitSelect.module.scss'
import { Props } from './TraitSelect.typings'
import cn from 'classnames'

const TraitSelect = (props: Props) => {

    const handleSelectTrait = () => {
        props.onSelect(props.item)
    }

    return (
        <div
            className={cn(styles.wrapper, styles.hover)}
            onClick={() => handleSelectTrait()}
        >
            <div className={styles.imgContainer}>
                <img src={props.item.icon} className={styles.icon} />
            </div>
            <div className={cn(styles.title, styles.checked)}>{props.item.title}</div>
        </div>
    )
}

export default TraitSelect