import { Gender } from "widgets/ui/GenderSelect/GenderSelect.typings";
import {names} from './getRandomFullName.config'
import { nationalities } from "features/ui/PlayerInfoForm/PlayerInfoForm.config";

export const getRandomFullName = (gender:Gender, nationality:string) => {
    
    const getRandom = (arr:string[]) => {
        return arr[Math.floor(Math.random() * arr.length)]
    }

    const nationalityObj = nationalities.find(item => {
        if(gender === Gender.male){
            return nationality === item.titles.male
        } else {
            return nationality === item.titles.female
        }
    })
    
    const nationalitySign:string = nationalityObj ? nationalityObj.code : ''
    const firstName = getRandom(names[nationalitySign][gender === Gender.male ? 'male' : 'female'].name)
    const lastName = getRandom(names[nationalitySign][gender === Gender.male ? 'male' : 'female'].lastName)

    return {firstName, lastName}
}