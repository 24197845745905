interface HotKey {
    key: string
    title: string
    isDynamic:boolean
}

interface HotKeys {
    [key: string]: HotKey[]
}

const mainHotKeys:HotKey[] = [
    {
        key: 'F1',
        title: 'Телефон',
        isDynamic:false,
    },
    {
        key: 'F2',
        title: 'Инвентарь',
        isDynamic:false,
    },
    {
        key: 'F3',
        title: 'Меню игрока',
        isDynamic:false,
    },
    {
        key: 'F4',
        title: 'Помощь',
        isDynamic:false,
    },
    {
        key: 'F5',
        title: 'Чат',
        isDynamic:false,
    },
]

export const hotkeys: HotKeys = {
    inVehicle: [
        ...mainHotKeys,
        {
            key: 'X',
            title: 'Пристегнуть ремень',
            isDynamic:true,
        },
        {
            key: 'F7',
            title: 'Завести двигатель',
            isDynamic:true,
        },
        {
            key: 'F8',
            title: 'Замок транспорта',
            isDynamic:true,
        },
        {
            key: 'G',
            title: 'Взаимодействие с транспортом',
            isDynamic:true,
        },
        {
            key: 'F10',
            title: 'Окно транспорта',
            isDynamic:true,
        },
    ],
    nearVehicle: [
        ...mainHotKeys,
        {
            key: 'F6',
            title: 'Замок транспорта',
            isDynamic:true,
        },
        {
            key: 'F',
            title: 'Сесть на пассажирское сиденье',
            isDynamic:true,
        },
        {
            key: 'G',
            title: 'Взаимодействие с транспортом',
            isDynamic:true,
        },
    ],
    outVehicle: [
        ...mainHotKeys,
        {
            key: 'F6',
            title: 'Выбор анимации',
            isDynamic:true,
        },
        {
            key: 'F7',
            title: 'Помощь',
            isDynamic:true,
        },
        {
            key: 'F8',
            title: 'Курсор',
            isDynamic:true,
        },
        {
            key: 'F9',
            title: 'Чат',
            isDynamic:true,
        },
    ],
}
