import React, { useState, useRef, useEffect } from 'react'
import styles from './PoliceRadar.module.scss'
import cn from 'classnames'
import RemoteControl from './components/RemoteControl/RemoteControl'
import PlateReaderFrame from './components/PlateReaderFrame/PlateReaderFrame'
import PlateReaderBox from './components/PlateReaderBox/PlateReaderBox'
import UiSettingsBox from './components/UiSettingsBox/UiSettingsBox'
import { moveAt } from './utils/moveAt'
import { ContextParams } from './PoliceRadar.typings'
import Message from './components/Message/Message'
import { handleMessage } from './utils/handleMessage'
import { menuItemVariants, audioLibrary, Position } from './PoliceRadar.config'
import { playAudio } from './utils/playAudio'
import { handleMenuSet } from './utils/handleMenuSet'
import { usePoliceRadar } from './model/usePoliceRadar'
import PatrolCarSpeedWindow from './components/PatrolCarSpeedWindow/PatrolCarSpeedWindow'
import FastSpeedWindow from './components/FastSpeedWindow/FastSpeedWindow'
import SpeedWindow from './components/SpeedWindow/SpeedWindow'
import rpc from 'altv-rpc'

const PoliceRadar = () => {

    const radarData = usePoliceRadar()
    const [radarFrameVisible, setRadarFrameVisible] = useState<boolean>(false)
    const [remoteControlVisible, setRemoteControlVisible] = useState<boolean>(true)
    const [plateReaderFrameVisible, setPlateReaderFrameVisible] = useState<boolean>(false)
    const [plateReaderBoxVisible, setPlateReaderBoxVisible] = useState<boolean>(false)
    const [uiSettingsBoxVisible, setUiSettingsBoxVisible] = useState<boolean>(false)
    const [newUserVisible/*, setNewUserVisible*/] = useState<boolean>(false)
    const [helpWindowVisible, setHelpWindowVisible] = useState<boolean>(false)
    const [quickStartVisible/*, setQuickStartVisible*/] = useState<boolean>(false)
    const [keyLockLabelVisible/*, setKeyLockLabelVisible*/] = useState<boolean>(false)
    const [PWRon, setPWRon] = useState<boolean>(false)
    const [xMitFrontOn, setXMitFrontOn] = useState<boolean>(false)
    const [xMitRearOn, setXMitRearOn] = useState<boolean>(false)
    const [xMitFrontSameOn, setXMitFrontSameOn] = useState<boolean>(false)
    const [xMitRearSameOn, setXMitRearSameOn] = useState<boolean>(false)
    const [xMitFrontOppOn, setXMitFrontOppOn] = useState<boolean>(false)
    const [xMitRearOppOn, setXMitRearOppOn] = useState<boolean>(false)
    const [wrapperPadding, setWrapperPadding] = useState<number>(Number(localStorage.getItem('safeZone')) ?? 0)
    const [xMitFrontLock, setXMitFrontLock] = useState<boolean>(false)
    const [xMitRearLock, setXMitRearLock] = useState<boolean>(false)
    const [lockFrontPlate, setLockFrontPlate] = useState<string>('')
    const [lockRearPlate, setLockRearPlate] = useState<string>('')
    const [keyLock, setKeyLock] = useState<boolean>(false)
    const [message, setMessage] = useState<string | null>(null)
    const [frontSpeed, setFrontSpeed] = useState<string | null>(null)
    const [rearSpeed, setRearSpeed] = useState<string | null>(null)
    const [frontPlate, setFrontPlate] = useState<string>('')
    //const [frontPlateLock, setFrontPlateLock] = useState<string | null>(null)
    const [rearPlate, setRearPlate] = useState<string>('')
    //const [rearPlateLock, setRearPlateLock] = useState<string | null>(null)
    const [isTurningOn, setIsTurningOn] = useState<boolean>(false)

    const [radarScale, setRadarScale] = useState<number>(Number(localStorage.getItem('radarScale')) ?? 1)
    const [remoteScale, setRemoteScale] = useState<number>(Number(localStorage.getItem('remoteScale')) ?? 1)
    const [plateReaderScale, setPlateReaderScale] = useState<number>(Number(localStorage.getItem('plateReaderScale')) ?? 1)

    const [contextParams, setContextParams] = useState<ContextParams[]>(
        [
            {
                id: 'fasterSpeedDisplay',
                active: false,
                title: '',
                subTitle: 'FAS',
                speedWindow: localStorage.getItem('fasterSpeedDisplay') ?? menuItemVariants.fasterSpeedDisplay[0],
            },
            {
                id: 'sameLaneSensitivityAdjustment',
                active: false,
                title: ' SL',
                subTitle: 'SEn',
                speedWindow: localStorage.getItem('sameLaneSensitivityAdjustment') ?? menuItemVariants.sameLaneSensitivityAdjustment[0],
            },
            {
                id: 'oppositeLaneSensitivityAdjustment',
                active: false,
                title: ' OP',
                subTitle: 'SEn',
                speedWindow: localStorage.getItem('oppositeLaneSensitivityAdjustment') ?? menuItemVariants.oppositeLaneSensitivityAdjustment[0],
            },
            {
                id: 'beepAudioVolume',
                active: false,
                title: ' bEE',
                subTitle: 'P',
                speedWindow: localStorage.getItem('beepAudioVolume') ?? menuItemVariants.beepAudioVolume[0],
            },
            {
                id: 'voiceEnunciatorVolume',
                active: false,
                title: 'UOI',
                subTitle: 'CE',
                speedWindow: localStorage.getItem('voiceEnunciatorVolume') ?? menuItemVariants.voiceEnunciatorVolume[0],
            },
            {
                id: 'plateReaderVolume',
                active: false,
                title: 'PLE',
                subTitle: 'AUd',
                speedWindow: localStorage.getItem('plateReaderVolume') ?? menuItemVariants.plateReaderVolume[0],
            },
            {
                id: 'unitOfMeasurement',
                active: false,
                title: 'Uni',
                subTitle: ' tS',
                speedWindow: localStorage.getItem('unitOfMeasurement') ?? menuItemVariants.unitOfMeasurement[0],
            },
            {
                id: 'fastSpeedLock',
                active: false,
                title: 'FAS',
                subTitle: 'Loc',
                speedWindow: localStorage.getItem('fastSpeedLock') ?? menuItemVariants.fastSpeedLock[0],
            },
            {
                id: 'fastSpeedLockLimit',
                active: false,
                title: 'FAS',
                subTitle: 'SPd',
                speedWindow: localStorage.getItem('fastSpeedLockLimit') ?? menuItemVariants.fastSpeedLockLimit[0],
            },
            {
                id: 'menuOff',
                active: false,
                title: '',
                subTitle: '',
                speedWindow: '',
            }
        ]
    )
    const [menuIndex, setMenuIndex] = useState<number>(9)

    const wrapperRef = useRef<null | HTMLDivElement>(null)
    const radarFrameRef = useRef<null | HTMLDivElement>(null)
    const audioRef = useRef<null | HTMLAudioElement>(null)
    const scale = Number(localStorage.getItem('radarScale'))
    const wrapperRects = { x: 0, y: 0 }

    useEffect(() => {
        if (wrapperRef) {
            if (wrapperRef.current) {
                wrapperRects.x = wrapperRef.current.offsetWidth
                wrapperRects.y = wrapperRef.current.offsetHeight
            }
        }
    })

    useEffect(() => {
        rpc.triggerClient('policeRadar:sendData', {
            data: {
                radarIsOn: PWRon,
                xMitFrontIsOn: xMitFrontOn,
                xMitRearIsOn: xMitRearOn,
                xMitFrontSameOn: xMitFrontSameOn,
                xMitRearSameOn: xMitRearSameOn,
                xMitFrontOppOn: xMitFrontOppOn,
                xMitRearOppOn: xMitRearOppOn,
                fastSpeedLock: contextParams[8].active
            }
        })
    }, [PWRon, xMitFrontOn, xMitRearOn, xMitFrontSameOn, xMitFrontOppOn, xMitRearSameOn, xMitRearOppOn, contextParams[8].active])

    useEffect(() => {
        const radarFrame = radarFrameRef.current
        if (radarFrame) {
            const rectX = localStorage.getItem('radarRectX')
            if (rectX) {
                radarFrame.style.left = rectX
            }
            const rectY = localStorage.getItem('radarRectY')
            if (rectY) {
                radarFrame.style.top = rectY
            }
            radarFrame.onmousedown = (event) => {
                const shiftX = event.clientX - radarFrame.getBoundingClientRect().left
                const shiftY = event.clientY - radarFrame.getBoundingClientRect().top
                radarFrame.style.zIndex = '100'

                const onMouseMove = (event: any) => {
                    moveAt(event.pageX, event.pageY, shiftX, shiftY, 'radarRectX', 'radarRectY', wrapperRects, radarFrameRef, wrapperPadding, scale)
                }

                moveAt(event.pageX, event.pageY, shiftX, shiftY, 'radarRectX', 'radarRectY', wrapperRects, radarFrameRef, wrapperPadding, scale)

                window.addEventListener('mousemove', onMouseMove)

                window.onmouseup = () => {
                    window.removeEventListener('mousemove', onMouseMove)
                    radarFrame.style.zIndex = '12'
                    window.onmouseup = null
                }
            }
            radarFrame.ondragstart = () => false
        }
    })

    useEffect(() => {
        if (PWRon) {
            if (menuIndex === 9) {
                xMitFrontOn && setFrontPlate(radarData.getData.frontCarPlate)
                xMitRearOn && setRearPlate(radarData.getData.rearCarPlate)
                xMitFrontOn && setFrontSpeed(String(radarData.getData.xMitFrontSpeed))
                xMitRearOn && setRearSpeed(String(radarData.getData.xMitRearSpeed))
            } else {
                setFrontSpeed(null)
                setRearSpeed(null)
            }
        } else {
            setFrontPlate('')
            setRearPlate('')
            xMitFrontOn && setFrontSpeed('888')
            xMitRearOn && setRearSpeed('888')
            !xMitFrontOn && setFrontSpeed('')
            !xMitRearOn && setRearSpeed('')
        }
    }, [xMitFrontOn, xMitRearOn, PWRon, menuIndex])

    const handleTurnOn = () => {
        setIsTurningOn(true)
        setXMitFrontOn(true)
        setXMitRearOn(true)
        setFrontSpeed('888')
        setRearSpeed('888')

        setTimeout(() => {
            setIsTurningOn(false)
            setXMitFrontOn(false)
            setXMitRearOn(false)
            setFrontSpeed(null)
            setRearSpeed(null)
            setPWRon(true)
        }, 1500)
    }

    const onPWRon = () => {
        if (PWRon) {
            setPWRon(false)
            setXMitFrontOn(false)
            setXMitRearOn(false)
            setXMitFrontSameOn(false)
            setXMitRearSameOn(false)
            setXMitFrontOppOn(false)
            setXMitRearOppOn(false)
            setMenuIndex(9)
            setXMitFrontLock(false)
            setXMitRearLock(false)
            setContextParams((prev) => {
                prev.forEach(item => {
                    item.active = false
                })
                prev[9].speedWindow = ''
                return [...prev]
            })
        } else {
            handleTurnOn()
            setContextParams(prev => {
                prev[9].speedWindow = ' []'
                return [...prev]
            })
        }
    }

    const onXMitFrontOn = () => {
        if (PWRon) {
            if (menuIndex === 9) {
                if (xMitFrontOn) {
                    setXMitFrontOn(false)
                    setXMitFrontOppOn(false)
                    setXMitFrontSameOn(false)
                    setXMitFrontLock(false)
                    setFrontSpeed('')
                    playAudio(3, audioRef)
                } else {
                    setXMitFrontOn(true)
                    playAudio(3, audioRef)
                }
            } else {
                handleMenuSet('up', contextParams, menuIndex, setContextParams)
                playAudio(9, audioRef)
            }
        }
    }
    const onXMitRearOn = () => {
        if (PWRon) {
            if (menuIndex === 9) {
                if (xMitRearOn) {
                    setXMitRearOn(false)
                    setXMitRearOppOn(false)
                    setXMitRearSameOn(false)
                    setXMitRearLock(false)
                    setRearSpeed('')
                    playAudio(3, audioRef)
                } else {
                    setXMitRearOn(true)
                    playAudio(3, audioRef)
                }
            } else {
                handleMenuSet('down', contextParams, menuIndex, setContextParams)
                playAudio(9, audioRef)
            }
        }
    }
    const onXMitFrontSameOn = () => {
        if (PWRon && xMitFrontOn && menuIndex === 9) {
            playAudio(9, audioRef)
            if (xMitFrontOppOn) {
                setXMitFrontOppOn(false)
                setXMitFrontSameOn(true)
            } else if (xMitFrontSameOn) {
                setXMitFrontSameOn(false)
            } else {
                setXMitFrontSameOn(true)
            }
        } else if (PWRon && menuIndex !== 9) {
            playAudio(9, audioRef)
            setMenuIndex(9)
        }
    }
    const onXMitRearSameOn = () => {
        if (PWRon && xMitRearOn && menuIndex === 9) {
            playAudio(9, audioRef)
            if (xMitRearOppOn) {
                setXMitRearOppOn(false)
                setXMitRearSameOn(true)
                playAudio(9, audioRef)
            } else if (xMitRearSameOn) {
                setXMitRearSameOn(false)
            } else {
                setXMitRearSameOn(true)
                playAudio(9, audioRef)
            }
        } else if (PWRon && menuIndex !== 9) {
            playAudio(9, audioRef)
            setMenuIndex(9)
        }
    }
    const onXMitFrontOppOn = () => {
        if (PWRon && xMitFrontOn && menuIndex === 9) {
            playAudio(9, audioRef)
            if (xMitFrontSameOn) {
                setXMitFrontSameOn(false)
                setXMitFrontOppOn(true)
            } else if (xMitFrontOppOn) {
                setXMitFrontOppOn(false)
            } else {
                setXMitFrontOppOn(true)
            }
        } else if (PWRon && menuIndex !== 9) {
            playAudio(9, audioRef)
            setMenuIndex(9)
        }
    }
    const onXMitRearOppOn = () => {
        if (PWRon && xMitRearOn && menuIndex === 9) {
            playAudio(9, audioRef)
            if (xMitRearSameOn) {
                setXMitRearSameOn(false)
                setXMitRearOppOn(true)
            } else if (xMitRearOppOn) {
                setXMitRearOppOn(false)
            } else {
                setXMitRearOppOn(true)
            }
        } else if (PWRon && menuIndex !== 9) {
            playAudio(9, audioRef)
            setMenuIndex(9)
        }
    }

    const menuContextAction = (menuIndex: number) => {
        setContextParams((prev) => {
            prev.forEach((item, index) => {
                item.active = index === menuIndex
            })
            return [...prev]
        })
    }

    const onMenuChange = () => {
        if (PWRon) {
            playAudio(9, audioRef)
            if (menuIndex === 9) {
                setMenuIndex(0)
                menuContextAction(0)
            } else {
                setMenuIndex(prev => {
                    menuContextAction(prev + 1)
                    return prev + 1
                })
            }

        }
    }

    const handleKeyListener = (event: KeyboardEvent) => {
        event.preventDefault()
        if (!keyLock) {
            event.key === 'F5' && setRemoteControlVisible(true)
            event.key === 'Escape' && setRemoteControlVisible(false)
            if (PWRon) {
                if (xMitFrontOn && event.key === '8') {
                    setXMitFrontLock(prev => !prev)
                    playAudio(4, audioRef)
                    setTimeout(() => playAudio(xMitFrontLock ? 2 : 0, audioRef), 600)
                }
                if (xMitRearOn && event.key === '5') {
                    setXMitRearLock(prev => !prev)
                    playAudio(6, audioRef)
                    setTimeout(() => playAudio(xMitFrontLock ? 2 : 0, audioRef), 600)
                }
                if (event.key === '9') {
                    playAudio(9, audioRef)
                    setLockFrontPlate(lockFrontPlate ? '' : frontPlate)
                }
                if (event.key === '6') {
                    playAudio(9, audioRef)
                    setLockRearPlate(lockRearPlate ? '' : rearPlate)
                }
            }

            if (event.key === 'l') {
                setMessage(null)
                if (keyLock) handleMessage('Radar key lock enabled', setMessage)
                else handleMessage('Radar key lock disabled', setMessage)
                setKeyLock(prev => !prev)
            }
        }
    }

    const handleMouseListener = (event: MouseEvent) => {
        event.preventDefault()
        event.target && setRemoteControlVisible(false)
    }

    useEffect(() => {
        window.addEventListener('keydown', handleKeyListener)
        return () => window.removeEventListener('keydown', handleKeyListener)
    }, [PWRon, xMitFrontOn, xMitRearOn, keyLock, lockFrontPlate, lockRearPlate])
    useEffect(() => {
        window.addEventListener('contextmenu', handleMouseListener)
        return () => window.removeEventListener('contextmenu', handleMouseListener)
    }, [])

    return (
        <div
            className={styles.wrapper}
            ref={wrapperRef}

        >
            <audio ref={audioRef} src={audioLibrary[0]}></audio>
            {
                radarFrameVisible &&
                <div
                    style={{ transform: `scale(${radarScale})` }}
                    className={styles.radarFrame}
                    ref={radarFrameRef}
                >
                    <div className={styles.frame_border}></div>
                    <div className={styles.radar_container}>
                        <div className={cn(styles.panel_side, styles.panel_left)}></div>
                        <div className={styles.radar}>
                            <div className={cn(styles.label, styles.label_top)}>FRONT ANTENNA</div>
                            <div className={styles.pwr_button_container}>
                                <div
                                    data-nuitype="togglePower"
                                    className={styles.pwr_button}
                                    onClick={onPWRon}
                                >
                                    PWR
                                </div>
                            </div>
                            <div className={styles.modes_container}>
                                <div className={styles.modes}>
                                    <p className={cn((xMitFrontSameOn || isTurningOn) && styles.active)}>SAME</p>
                                    <p className={cn((xMitFrontOppOn || isTurningOn) && styles.active)}>OPP</p>
                                    <p className={cn((xMitFrontOn || isTurningOn) && styles.active)}>XMIT</p>
                                </div>
                                <div className={styles.modes}>
                                    <p className={cn((xMitRearSameOn || isTurningOn) && styles.active)}> SAME</p >
                                    <p className={cn((xMitRearOppOn || isTurningOn) && styles.active)}>OPP</p>
                                    <p className={cn(xMitRearOn && styles.active)}>XMIT</p>
                                </div>
                            </div>
                            <div className={styles.speeds_container}>
                                <SpeedWindow
                                    xMitSameOn={xMitFrontSameOn}
                                    xMitOppOn={xMitFrontOppOn}
                                    isTurningOn={isTurningOn}
                                    speed={frontSpeed}
                                    menuTitle={contextParams[menuIndex].title}
                                />
                                <SpeedWindow
                                    xMitSameOn={xMitRearSameOn}
                                    xMitOppOn={xMitRearOppOn}
                                    isTurningOn={isTurningOn}
                                    speed={rearSpeed}
                                />
                            </div>
                            <div className={styles.speed_arrows_container}>
                                <div className={styles.speed_arrows}>
                                    <div className={cn(
                                        styles.arrow,
                                        (isTurningOn || (xMitFrontSameOn || xMitFrontOppOn) && frontSpeed) && styles.active_arrow
                                    )}></div>
                                    <div className={cn(styles.arrow, styles.arrow_down, isTurningOn && styles.active_arrow)}></div>
                                </div>
                                <div className={styles.speed_arrows}>
                                    <div className={cn(
                                        styles.arrow,
                                        (isTurningOn || (xMitRearSameOn || xMitRearOppOn) && rearSpeed) && styles.active_arrow
                                    )}></div>
                                    <div className={cn(styles.arrow, styles.arrow_down, isTurningOn && styles.active_arrow)}></div>
                                </div>
                            </div>
                            <div className={styles.modes_container}>
                                <div className={cn(styles.modes, styles.fast_top)}>
                                    <p className={cn(PWRon || isTurningOn && styles.active)}>FAST</p>
                                    <p className={cn((isTurningOn || (PWRon && xMitFrontLock)) && styles.active)}>LOCK</p>
                                </div>
                                <div className={cn(styles.modes, styles.fast_bottom)}>
                                    <p className={cn(PWRon || isTurningOn && styles.active)}> FAST</p>
                                    <p className={cn(((PWRon && xMitRearLock) || isTurningOn) && styles.active)}>LOCK</p>
                                </div>
                            </div>
                            <div className={styles.speeds_container}>

                                <FastSpeedWindow
                                    PWRon={PWRon}
                                    menuIndex={menuIndex}
                                    xMitRearOn={xMitRearOn}
                                    xMitFrontOn={xMitFrontOn}
                                    isTurningOn={isTurningOn}
                                    menuTitle={contextParams[menuIndex].subTitle}
                                    position={Position.top}
                                />
                                <FastSpeedWindow
                                    PWRon={PWRon}
                                    menuIndex={menuIndex}
                                    xMitRearOn={xMitRearOn}
                                    xMitFrontOn={xMitFrontOn}
                                    isTurningOn={isTurningOn}
                                    menuTitle={contextParams[menuIndex].subTitle}
                                    position={Position.bottom}
                                />
                            </div>
                            <div className={styles.speed_arrows_container}>
                                <div className={cn(styles.speed_arrows, styles.fast_top)}>
                                    <div className={cn(styles.arrow, isTurningOn && styles.active_arrow)}></div>
                                    <div className={cn(styles.arrow, styles.arrow_down, isTurningOn && styles.active_arrow)}></div>
                                </div>
                                <div className={cn(styles.speed_arrows, styles.fast_bottom)}>
                                    <div className={cn(styles.arrow, isTurningOn && styles.active_arrow)}></div>
                                    <div className={cn(styles.arrow, styles.arrow_down, isTurningOn && styles.active_arrow)}></div>
                                </div>
                            </div>
                            <div className={styles.patrol_and_logo_container}>
                                <div className={styles.logo}>
                                    <span className={styles.name}> Wraith</span>
                                    ARS 2X
                                </div >
                                <PatrolCarSpeedWindow
                                    PWRon={PWRon}
                                    radarData={radarData.getData.patrolCarSpeed}
                                    contextParams={contextParams[menuIndex].speedWindow}
                                    isTurningOn={isTurningOn}
                                />
                                <div className={styles.speed_label}>PATROL SPEED</div>
                            </div>
                            <div className={cn(styles.label, styles.label_bottom)}>REAR ANTENNA</div>
                        </div>
                        <div className={cn(styles.panel_side, styles.panel_right)}></div>
                    </div>
                </div>
            }
            {
                remoteControlVisible &&
                <RemoteControl
                    wrapperRef={wrapperRef}
                    remoteScale={remoteScale}
                    onToggleDisplay={setRadarFrameVisible}
                    onXMitFrontOn={onXMitFrontOn}
                    onXMitRearOn={onXMitRearOn}
                    onXMitFrontSameOn={onXMitFrontSameOn}
                    onXMitRearSameOn={onXMitRearSameOn}
                    onXMitFrontOppOn={onXMitFrontOppOn}
                    onXMitRearOppOn={onXMitRearOppOn}
                    onUISettingsOpen={setUiSettingsBoxVisible}
                    onPlateReaderMenuOpen={setPlateReaderBoxVisible}
                    onHelpWindowOpen={setHelpWindowVisible}
                    onMenuChange={onMenuChange}
                    padding={wrapperPadding}
                />
            }
            {
                plateReaderFrameVisible &&
                <PlateReaderFrame
                    wrapperRef={wrapperRef}
                    plateReaderScale={plateReaderScale}
                    padding={wrapperPadding}
                    lockFrontPlate={lockFrontPlate}
                    lockRearPlate={lockRearPlate}
                    frontPlate={frontPlate}
                    rearPlate={rearPlate}
                />
            }
            {
                plateReaderBoxVisible &&
                <PlateReaderBox
                    onClose={setPlateReaderBoxVisible}
                    onPlateReaderOn={setPlateReaderFrameVisible}
                />
            }
            {
                uiSettingsBoxVisible &&
                <UiSettingsBox
                    onRadarScale={setRadarScale}
                    onRemoteScale={setRemoteScale}
                    onPlateReaderScale={setPlateReaderScale}
                    onClose={setUiSettingsBoxVisible}
                    onSafeZone={setWrapperPadding}
                    safeZone={wrapperPadding}
                />
            }
            {
                keyLockLabelVisible &&
                <p className={styles.keyLockLabel}>Radar key binds<span></span></p>
            }
            {
                helpWindowVisible &&
                <div className={styles.helpWindow}>
                    <iframe src="about:blank"></iframe>
                    <button
                        className={styles.close}
                        onClick={() => setHelpWindowVisible(false)}
                    >
                        CLOSE HELP
                    </button>
                </div>
            }

            {
                newUserVisible &&
                <div className={styles.newUser}>
                    <p className={styles.msg}>Hey, it appears this is your first time using the Wraith ARS 2X. Would you like to view the quick start video?</p>
                    <button className={styles.btn_yes}>Yes</button>
                    <button className={styles.btn_no}>No</button>
                </div>
            }
            {
                quickStartVisible &&
                <div className={styles.quickStart}>
                    <iframe
                        src="about:blank"
                        frameBorder="0"
                        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                    ></iframe>
                    <button id="closeQuickStart" className={styles.close}>CLOSE VIDEO</button>
                </div>
            }
            {
                message &&
                <Message text={message} />
            }

        </div >
    )
}

export default PoliceRadar