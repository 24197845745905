import * as React from 'react'
import { SVGProps } from 'react'

export const Engine = (props: SVGProps<SVGSVGElement>) => (
    <svg
        width="1em"
        height="1em"
        viewBox="0 0 20 15"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="M19.4143 5.76953H18.2424C17.9186 5.76953 17.6565 6.02764 17.6565 6.34645V6.92338H16.4846V12.6926H17.6565V13.2695C17.6565 13.5883 17.9186 13.8465 18.2424 13.8465H19.4143C19.7381 13.8465 20.0002 13.5883 20.0002 13.2695V6.34645C20.0002 6.02764 19.7381 5.76953 19.4143 5.76953Z"
            fill={props.color}
        />
        <path
            d="M12.3826 1.15385C12.7064 1.15385 12.9685 0.895733 12.9685 0.576923C12.9685 0.258113 12.7064 0 12.3826 0H5.27319C4.9494 0 4.68726 0.258113 4.68726 0.576923C4.68726 0.895733 4.9494 1.15385 5.27319 1.15385H7.07007V2.30769H5.27319C4.9494 2.30769 4.68726 2.56581 4.68726 2.88462C4.68726 3.07828 4.68726 13.058 4.68726 12.6919H5.03058L7.24173 14.831C7.35159 14.9392 7.50037 15 7.65601 15H12.9685C12.9685 14.4189 12.9685 3.26007 12.9685 2.88462C12.9685 2.56581 12.7064 2.30769 12.3826 2.30769H10.5857V1.15385H12.3826ZM10.4873 8.39694L9.3154 10.1277C9.13885 10.389 8.77692 10.4665 8.50287 10.2877C8.2334 10.1107 8.16061 9.75301 8.34036 9.48768L8.90509 8.65385H7.65601C7.43979 8.65385 7.24112 8.53666 7.13934 8.34901C7.03741 8.16136 7.04886 7.93374 7.16849 7.75691L8.34036 6.02614C8.51828 5.76082 8.88159 5.68915 9.15289 5.86614C9.42236 6.04297 9.49499 6.40084 9.3154 6.66617L8.75067 7.5H9.99976C10.216 7.5 10.4146 7.61719 10.5164 7.80484C10.6183 7.99249 10.6069 8.2201 10.4873 8.39694Z"
            fill={props.color}
        />
        <path
            d="M0.585937 12.6923C0.909729 12.6923 1.17187 12.4342 1.17187 12.1154V10.3846H2.34375V12.1154C2.34375 12.4342 2.6059 12.6923 2.92969 12.6923H3.51562V4.61536H2.92969C2.6059 4.61536 2.34375 4.87347 2.34375 5.19228V6.92305H1.17187V5.19228C1.17187 4.87347 0.909729 4.61536 0.585937 4.61536C0.262146 4.61536 0 4.87347 0 5.19228V12.1154C0 12.4342 0.262146 12.6923 0.585937 12.6923Z"
            fill={props.color}
        />
        <path
            d="M15.3123 14.4229V5.19216C15.3123 4.87335 15.0501 4.61523 14.7263 4.61523H14.1404V14.9999H14.7263C15.0501 14.9999 15.3123 14.7417 15.3123 14.4229Z"
            fill={props.color}
        />
    </svg>
)
