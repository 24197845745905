function polarToCartesian(
    centerX: number,
    centerY: number,
    radius: number,
    angleInDegrees: number
) {
    const angleInRadians = ((angleInDegrees - 90) * Math.PI) / 180.0

    return {
        x: centerX + radius * Math.cos(angleInRadians),
        y: centerY + radius * Math.sin(angleInRadians),
    }
}

function describeArc(
    x: number,
    y: number,
    radius: number,
    startAngle: number,
    endAngle: number
) {
    const start = polarToCartesian(x, y, radius, endAngle)
    const end = polarToCartesian(x, y, radius, startAngle)

    const largeArcFlag = endAngle - startAngle <= 180 ? '0' : '1'

    const d = [
        'M',
        start.x,
        start.y,
        'A',
        radius,
        radius,
        0,
        largeArcFlag,
        0,
        end.x,
        end.y,
    ].join(' ')

    return d
}

export const getSectors = (
    length: number,
    offsetAngle: number,
    diameter: number,
    width: number,
    count = length
) => {
    const numbers = Array.from(Array(length).keys())
    const sectorAngle = 360 / length
    const radius = diameter / 2

    const sectors = numbers
        .map((number) => {
            if (number + 1 > count) {
                return ''
            }
            const startAngle = sectorAngle * number + offsetAngle
            const endAngle = startAngle + sectorAngle - offsetAngle

            return describeArc(
                radius,
                radius,
                radius - width / 2,
                startAngle,
                endAngle
            )
        })
        .filter((path) => path)

    return sectors
}
