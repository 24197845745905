import * as React from 'react'
import { SVGProps } from 'react'

export const Female = (props: SVGProps<SVGSVGElement>) => (
    <svg
        width="1em"
        height="1em"
        viewBox="0 0 31 31"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="M30.9833 1.12516C30.9691 1.01384 30.9445 0.906626 30.9038 0.80603C30.9026 0.803507 30.9026 0.800039 30.9019 0.796885C30.9019 0.796254 30.9013 0.795624 30.9007 0.794993C30.8559 0.689036 30.7947 0.592855 30.7256 0.503611C30.7086 0.482483 30.6919 0.46167 30.6739 0.441803C30.6001 0.358551 30.52 0.281606 30.427 0.218852C30.4245 0.21696 30.4213 0.216329 30.4188 0.214437C30.3289 0.155151 30.2296 0.110372 30.1255 0.0750529C30.0997 0.0659078 30.0745 0.0580241 30.048 0.0510864C29.9389 0.0214437 29.8266 0 29.7083 0H20.6667C19.9537 0 19.375 0.578664 19.375 1.29167C19.375 2.00467 19.9537 2.58333 20.6667 2.58333H26.5895L18.8802 10.2927C16.8245 8.64843 14.2935 7.75 11.625 7.75C5.21523 7.75 0 12.9652 0 19.375C0 25.7848 5.21523 31 11.625 31C18.0348 31 23.25 25.7848 23.25 19.375C23.25 16.7078 22.3522 14.1774 20.7067 12.1198L28.4167 4.40983V10.3333C28.4167 11.0463 28.9953 11.625 29.7083 11.625C30.4213 11.625 31 11.0463 31 10.3333V1.29167C31 1.26455 30.9937 1.23869 30.9921 1.2122C30.9902 1.18256 30.9871 1.15417 30.9833 1.12516ZM11.625 28.4167C6.63903 28.4167 2.58333 24.361 2.58333 19.375C2.58333 14.389 6.63903 10.3333 11.625 10.3333C14.039 10.3333 16.3117 11.2731 18.0206 12.976C19.7269 14.6883 20.6667 16.961 20.6667 19.375C20.6667 24.361 16.611 28.4167 11.625 28.4167Z"
            fill={props.color}
        />
    </svg>
)
