import React from "react"
import styles from './Button.module.scss'
import classNames from "classnames"

interface ButtonProps {
    value: string
    className?: string
    type?:  'submit' | 'reset' | 'button' 
    full?: boolean
    variant?: "primary" | "secondary"
}

export const Button: React.FC<ButtonProps> = ({value, className, type = "button", full = false, variant = "primary"})=> {
    return(
        <button className={classNames(styles.container, className, full && styles.full, variant === "secondary" && styles.secondary)} type={type}>{value}</button>
    )
}