import React from 'react'
import stylesBase from 'shared/ui/Toast/Toast.module.scss'
import { ToastContainer, ToastContainerProps, ToastContent, ToastOptions, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { AlertWarning } from 'shared/icons/generated'
import cn from 'classnames';

export const Toast: React.FC = () => {
    const css = stylesBase

    const props: ToastContainerProps = {
        autoClose: 5000,
        hideProgressBar: true,
        position: "bottom-left",
        closeButton: false
    }

    return (
        <>
            <ToastContainer
                toastClassName={cn(css.toast, css[`toastInfo`])}
                containerId={"Info"}
                icon={<AlertWarning height={21} width={21} color="#fff" />}
                {...props}
                
            />
            <ToastContainer
                toastClassName={cn(css.toast, css[`toastWarning`])}
                containerId={"Warning"}
                icon={<AlertWarning height={21} width={21} color="#fff" />}
                {...props}
            />
            <ToastContainer
                toastClassName={cn(css.toast, css[`toastError`])}
                containerId={"Error"}
                icon={<AlertWarning height={21} width={21} color="#fff" />}
                {...props}
            />
            <ToastContainer
                toastClassName={cn(css.toast, css[`toastSuccess`])}
                containerId={"Success"}
                icon={<AlertWarning height={21} width={21} color="#fff" />}
                {...props}
            />
        </>
        
    
    )
}



export const toastify = (content: ToastContent<any>, options?: ToastOptions<any>) => toast(content, {...options, containerId: 'Info'})
toastify.warning = (content: ToastContent<any>, options?: ToastOptions<any>) => toast(content, {...options, containerId: 'Warning'})
toastify.info = (content: ToastContent<any>, options?: ToastOptions<any>) => toast(content, {...options, containerId: 'Info'})
toastify.error = (content: ToastContent<any>, options?: ToastOptions<any>) => toast(content, {...options, containerId: 'Error'})
toastify.success = (content: ToastContent<any>, options?: ToastOptions<any>) => toast(content, {...options, containerId: 'Success'})