import React, { /*SVGProps, */useEffect, useState } from 'react'
import styles from './InteractionWheel.module.scss'
import { ItemsWheel } from 'entities/ui/InteractionWheel/components/ItemsWheel/ItemsWheel'
import classNames from 'classnames'
import { characterInteractions } from 'entities/ui/InteractionWheel/InteractionWheel.config'
import { useInteractionOpenQuery, useInteractionCommandMutation } from './api/interactionWheelApi';
import { initialInteractionsParams } from "entities/ui/InteractionWheel/InteractionWheel.config";
import { InteractionsParams, /*Props,*/ HoverItem } from './InteractionWheel.typings'


export const InteractionWheel = () => {

    const { data: interactionShow = {
        isOpen: false,
        type: 'withPlayer',
        hasBag: false,
        hasCuff: false,
        hasRope: false,
        charactersInside: []
    } } = useInteractionOpenQuery()

    const show = interactionShow.isOpen
    const type = interactionShow.type ?? null
    const [params, setParams] = useState<InteractionsParams>(initialInteractionsParams)

    const items = characterInteractions[type]?.(params) || []
    const count = items.length
    const [hoverItem, setHoverItem] = useState<HoverItem | null>(null)
    const [hoverSubItem, setHoverSubItem] = useState<HoverItem | null>(null)

    const handleMouseOver = (index: number) => {
        setHoverItem({
            ...items[index],
            index,
        })
    }

    const [sendCommand] = useInteractionCommandMutation()

    const handleMouseOverSubItem = (index: number) => {
        const item = hoverItem?.items?.[index]

        if (item) {
            setHoverSubItem({
                ...item,
                index,
            })
        }
    }

    const outerSectors = 16
    const outerAngle = 360 / 16
    const innerAngle = 360 / count
    const rotateOuterDeg =
        hoverItem?.index && hoverItem.items
            ? (hoverItem.index + 0.5) * innerAngle -
            (outerAngle * hoverItem.items?.length) / 2
            : 0

    useEffect(() => {
        setHoverSubItem(null)
    }, [hoverItem])

    useEffect(() => {
        if (interactionShow.charactersInside?.length) {
            const characterNames = interactionShow.charactersInside.map(item => ({
                name: item.staticId,
                hasCuff: interactionShow.hasCuff,
                hasRope: interactionShow.hasRope,
                hasBag: interactionShow.hasBag,
            }));
            setParams(characterNames[0]);

            if (type === 'inVehicle') {
                const passengers = interactionShow.charactersInside.map((item, index) => ({
                    Icon: '',
                    key: `passenger_${index}`,
                    title: (
                        <>
                            {item.playerId}
                            <br />
                            {item.staticId}
                        </>
                    ),
                }));

                const existingInteractions = characterInteractions['inVehicle'] || (() => []);

                characterInteractions['inVehicle'] = (params: { passenger: typeof passengers }) => [
                    ...existingInteractions(params),
                    {
                        Icon: '',
                        key: 'getOutOfCar',
                        title: 'Высадить из машины',
                        items: passengers,
                    },
                ];
            }
        }
    }, [interactionShow.charactersInside]);


    return show ? (
        <div className={classNames(styles.wrapperOuter, styles.bigCircle)}>
            {hoverItem?.items && (
                <ItemsWheel
                    items={hoverItem?.items}
                    hoverItem={hoverSubItem}
                    onClick={() => {
                        if (hoverItem.key) sendCommand({ type: hoverItem.key })
                    }
                    }
                    onMouseOver={handleMouseOverSubItem}
                    sectorWidth={68}
                    length={outerSectors}
                    rotate={rotateOuterDeg}
                    containerSize={800}
                    iconSize={42}
                />
            )}
            <div className={styles.wrapperInner}>
                <ItemsWheel
                    items={items}
                    hoverItem={hoverItem}
                    onClick={() => {
                        if (hoverSubItem) {
                            if (hoverSubItem.key) sendCommand({ type: hoverSubItem.key, ...(hoverSubItem.items && { playerId: hoverSubItem.items[0].id }) })
                        }
                    }
                    }
                    onMouseOver={handleMouseOver}
                    hasCircle
                    containerSize={565}
                />
                <div className={styles.title}>
                    {hoverSubItem?.title || hoverItem?.title}
                </div>
            </div>
        </div>
    ) : null
}
