import * as React from 'react'
import { SVGProps } from 'react'

export const Kiss = (props: SVGProps<SVGSVGElement>) => (
    <svg
        width="1em"
        height="1em"
        viewBox="0 0 42 42"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="M38.52 6.37486C38.8597 6.27601 39.2273 6.16904 39.5968 6.0615C40.4306 5.81885 40.4307 4.63744 39.5969 4.3948L38.5205 4.0816C38.1213 3.96545 37.8092 3.65365 37.693 3.25441C37.5942 2.91472 37.4872 2.54714 37.3796 2.17767C37.137 1.34383 35.9557 1.34374 35.7129 2.17759L35.3998 3.25391C35.2836 3.65316 34.9718 3.96528 34.5726 4.08144C34.2265 4.18217 33.8602 4.28873 33.4957 4.3948C32.6619 4.63744 32.6619 5.81877 33.4958 6.06142L34.5721 6.37461C34.9714 6.49077 35.2835 6.80256 35.3997 7.20181C35.5004 7.5479 35.607 7.91416 35.713 8.27863C35.9557 9.11247 37.137 9.11239 37.3796 8.27854L37.6928 7.20222C37.809 6.80306 38.1207 6.49101 38.52 6.37486Z"
            fill={props.color}
        />
        <path
            d="M7.42751 37.9186C7.76719 37.8197 8.13477 37.7127 8.50424 37.6052C9.33808 37.3625 9.33817 36.1811 8.50432 35.9385L7.428 35.6253C7.02875 35.5091 6.71671 35.1973 6.60047 34.7981C6.50162 34.4584 6.39466 34.0908 6.28711 33.7214C6.04447 32.8875 4.86314 32.8874 4.62041 33.7213L4.30722 34.7977C4.19106 35.1969 3.87927 35.5091 3.48002 35.6252C3.13393 35.726 2.76767 35.8325 2.4032 35.9386C1.56936 36.1812 1.56936 37.3625 2.40329 37.6052L3.47961 37.9184C3.87885 38.0345 4.19098 38.3463 4.30714 38.7456C4.40787 39.0917 4.51443 39.4579 4.6205 39.8224C4.86314 40.6563 6.04447 40.6562 6.28711 39.8223L6.60031 38.746C6.71646 38.3468 7.02826 38.0348 7.42751 37.9186Z"
            fill={props.color}
        />
        <path
            d="M40.8754 18.2723L36.6285 15.6087C35.3818 14.8268 34.2926 13.8322 33.414 12.6733L31.9888 10.7935C30.2477 8.49698 26.668 7.65986 24.0337 8.93322C22.124 9.85639 19.876 9.85639 17.9663 8.93322L17.4132 8.66588C17.2276 8.57622 17.0368 8.49838 16.842 8.43209C16.1462 8.19535 14.2644 9.43139 13.6692 9.79364C12.3375 10.6041 12.4752 11.4297 11.4956 12.7219L9.97449 13.8171C9.09594 14.9759 7.93561 15.3473 6.68891 16.1292L3.41342 18.5564C2.71214 18.9962 3.00778 20.1921 3.00778 21.0002V21.929C3.00778 23.2238 3.01746 23.669 3.94072 24.6069C5.96589 26.6642 7.06404 28.0954 7.06404 28.0954C10.2448 31.3265 15.9781 33.8611 20.5918 33.8611H24.2988C28.9124 33.8611 33.3242 32.0323 36.5049 28.8012C37.8464 27.4385 39.2802 25.9819 40.562 24.6799C41.1208 24.1122 41.5299 23.432 41.7658 22.6963C41.9196 22.2167 39.1495 22.2988 39.1495 21.7878L41.9999 20.5966V20.2733C41.9999 19.4652 41.5767 18.7121 40.8754 18.2723ZM30.5409 20.5966C28.3609 20.5966 24.5714 22.3381 22.8449 23.6249C21.8095 24.2922 20.4605 24.2923 19.425 23.625C17.6986 22.3382 12.1545 20.5966 9.97449 20.5966C12.1087 20.5966 17.1927 19.6129 18.9 18.375L19.0071 18.2974C20.0203 17.5627 22.0867 17.5627 23.1 18.2974C24.8768 19.5857 28.3199 20.5966 30.5409 20.5966Z"
            fill={props.color}
        />
        <path
            d="M30.5409 20.5966C28.3609 20.5966 24.5714 22.3381 22.8449 23.6249C21.8316 24.3848 20.4383 24.3849 19.425 23.625C17.6986 22.3382 12.1545 20.5966 9.97449 20.5966H4.48474C4.05777 20.5966 3.7116 20.2504 3.7116 19.8235C3.7116 19.6934 3.74384 19.5655 3.80684 19.4516C3.96877 19.1592 4.33003 18.5896 4.83608 18.2722L9.08298 15.6086C10.3297 14.8267 11.419 13.8321 12.2974 12.6732L14.0881 10.3113C14.7628 9.4213 15.7496 8.77925 16.8415 8.43324C14.5887 7.66683 11.794 8.44161 10.3766 10.3113L8.58595 12.6732C7.7074 13.832 6.6182 14.8267 5.3715 15.6086L1.1246 18.2722C0.423319 18.7121 0.00012207 19.4652 0.00012207 20.2733L0.00012207 21.2022C0.00012207 22.4969 0.515029 23.7422 1.43837 24.6801C3.46354 26.7373 5.49479 28.8008 5.49479 28.8008C8.67553 32.0319 13.0875 33.8609 17.7013 33.8609H21.4129C16.799 33.8609 12.3871 32.0319 9.20635 28.8008C9.20635 28.8008 7.17502 26.7373 5.14993 24.6801C5.14444 24.6745 5.13894 24.6689 5.13344 24.6633C4.41756 23.9296 4.93878 22.6958 5.96384 22.6958H13.2651C15.445 22.6958 17.5636 23.3937 19.2901 24.6805C20.3034 25.4404 21.6967 25.4405 22.71 24.6806C24.4364 23.3938 26.5552 22.6958 28.7352 22.6958H41.7649C41.9187 22.2161 42.0001 21.7132 42.0001 21.2022L41.9999 20.5966H30.5409Z"
            fill={props.color}
        />
    </svg>
)
