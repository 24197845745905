import * as React from 'react'
import { SVGProps } from 'react'

export const Hugs = (props: SVGProps<SVGSVGElement>) => (
    <svg
        width="1em"
        height="1em"
        viewBox="0 0 42 42"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="M27.18 21.0005C27.18 19.8076 27.5497 18.6995 28.1804 17.7849L3.21599 17.7849C1.44289 17.7849 0.000366211 19.2274 0.000366211 21.0005C0.000366211 22.7806 1.44108 24.2162 3.21599 24.2162L13.5902 24.2162C14.2698 24.2162 14.8207 24.7671 14.8207 25.4466L14.8207 33.1083L21.0004 33.1083C24.1304 33.1083 26.6769 35.6548 26.6769 38.7848C26.6769 39.9777 26.3071 41.0858 25.6761 42.0005L36.0722 42.0005L36.0722 26.6771L32.8566 26.6771C29.7265 26.6771 27.18 24.1306 27.18 21.0005Z"
            fill={props.color}
        />
        <path
            d="M38.7846 17.7849L32.8565 17.7849C31.0832 17.7849 29.6409 19.2189 29.6409 21.0005C29.6409 22.7774 31.0781 24.2162 32.8565 24.2162L38.7846 24.2162C40.5577 24.2162 42.0003 22.7823 42.0003 21.0005C42.0003 19.2237 40.563 17.7849 38.7846 17.7849Z"
            fill={props.color}
        />
        <path
            d="M21.0003 35.5697L13.5902 35.5697C12.9106 35.5697 12.3597 35.0188 12.3597 34.3392L12.3597 26.6775L5.92847 26.6775L5.92847 34.3392C5.92847 38.5639 9.36549 42.0008 13.5902 42.0008L21.0003 42.0008C22.7732 42.0008 24.2159 40.5672 24.2159 38.7852C24.2159 37.0122 22.7734 35.5697 21.0003 35.5697Z"
            fill={props.color}
        />
        <path
            d="M16.5542 15.3242C20.7856 15.3242 24.2159 11.8939 24.2159 7.66245C24.2159 3.431 20.7856 0.000732421 16.5542 0.000732421C12.3227 0.000732422 8.89246 3.431 8.89246 7.66245C8.89246 11.8939 12.3227 15.3242 16.5542 15.3242Z"
            fill={props.color}
        />
        <path
            d="M26.677 7.66245C26.677 10.489 25.4815 13.1671 23.4572 15.0619C24.1027 15.2351 24.7717 15.3241 25.4465 15.3241C29.6712 15.3241 33.1082 11.8871 33.1082 7.66245C33.1082 3.43776 29.6712 0.000732421 25.4465 0.000732422C24.7716 0.000732422 24.1026 0.0897363 23.4572 0.262986C25.4814 2.15774 26.677 4.83582 26.677 7.66245Z"
            fill={props.color}
        />
    </svg>
)
