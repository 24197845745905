import React, { useState, useEffect } from 'react'
import styles from './Suppression.module.scss'
import classNames from 'classnames'
import rpc from 'altv-rpc'

const Suppression = () => {

    const shootStyle:{[key:number]:string} = {
        1: 'radius1',
        2: 'radius2',
        3: 'radius3',
        4: 'radius4'
    }

    const [shoot, setShoot] = useState<null | number>(null);

    const callback = ({ data }: any) => {
        setShoot(null)
        setTimeout(() => setShoot(data.level), 5)
    }

    const event = "supression:show"

    useEffect(() => {
        rpc.on(event, callback)

        return () => {
            rpc.off(event, callback)
        }
    },[])

    return (
        <div className={styles.suppression}>
            <div
                className={classNames(
                    styles.radius,
                    shoot && styles[shootStyle[shoot]]
                )}
            ></div>
        </div>
    )
}

export default Suppression
