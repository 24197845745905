import React, {useRef} from 'react'
import styles from './GYMGame.module.scss'
import { motion } from 'framer-motion'

const GYMGame = ({duration}:{duration:number}) =>{

    const circleRef = useRef<HTMLDivElement | null>(null)
    const targetRef = useRef<HTMLDivElement>(null)

 //   const [/*currentWidth,*/ setCurrentWidth] = useState<number | null>(null)

    // useEffect(() => {
    //     let animationFrameId:number
    
    //     const animate = () => {
    //       if (circleRef.current) {
    //         const currentWidth = circleRef.current.clientWidth;
    //         //setCurrentWidth(currentWidth);
    //       }
    
    //       animationFrameId = requestAnimationFrame(animate);
    //     };
    
    //     animate();
    
    //     return () => {
    //       cancelAnimationFrame(animationFrameId);
    //     };
    //   }, []);

      // const keyPressHandler = (event:KeyboardEvent) => {
      //   event.preventDefault()
      //   const target = targetRef.current?.clientWidth
      //   if(event.key === ' ') {
      //       if(target && currentWidth){
      //           if(currentWidth > target - target*0.05 && currentWidth < target + target*0.05) console.log('true')
      //           else console.log(currentWidth + '   ' + target)
      //       }
      //   }
      // }

      // useEffect(() => {
      //   window.addEventListener('keydown', keyPressHandler)
      //   return () => window.removeEventListener('keydown', keyPressHandler)
      // })

    return(
        <div className={styles.wrapper}>
            <div className={styles.target} ref={targetRef}>
                <motion.div
                    className={styles.circle}
                    ref={circleRef}
                    initial={{width:"18.75rem", opacity:1}}
                    animate={{
                        width: ["18.75rem", "3.75rem", "0rem"],
                        opacity: [1, 1, 0]}}
                    transition={{duration: duration, times: [0, 0.8, 1], ease: "linear"}}
                >
                </motion.div>
            </div>
            <div className={styles.targetBlur}></div>

        </div>
    )
}

export default GYMGame