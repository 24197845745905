import * as React from 'react'
import { SVGProps } from 'react'

export const Dating = (props: SVGProps<SVGSVGElement>) => (
    <svg
        width="1em"
        height="1em"
        viewBox="9 9 48 48"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="M19.2788 18.83C19.0807 19.2214 19.0458 19.9239 19.1958 20.3591L19.3237 20.7313L24.2334 25.9864C28.5307 30.5823 29.1379 31.2557 29.1111 31.3555C29.0727 31.5218 28.9551 31.6141 28.7676 31.6421C28.6141 31.6568 28.507 31.5531 24.6534 27.4967L20.7023 23.337L20.3379 23.1971C19.3629 22.8239 18.2914 23.2951 17.9331 24.2562C17.7666 24.7107 17.7581 25.1652 17.9119 25.6339C18.0221 25.9578 18.0592 25.9972 21.9349 30.1164C25.6478 34.073 25.8425 34.2893 25.8192 34.4322C25.7797 34.6368 25.608 34.7802 25.417 34.765C25.2643 34.751 24.9852 34.4748 22.4322 31.7678C19.6749 28.8442 19.6145 28.7802 19.2512 28.6021C18.5669 28.271 17.7889 28.3631 17.1956 28.8482C16.5876 29.3473 16.3496 30.1205 16.5668 30.8734L16.6712 31.2307L22.1951 37.0876C28.1042 43.3531 27.8762 43.131 29.1211 43.9285C31.9773 45.7637 35.4762 45.914 38.4134 44.3249C39.7313 43.612 41.3018 42.2797 42.0942 41.2069C43.6989 39.0331 44.3403 36.5776 43.9754 34.0016C43.6501 31.7044 42.6916 28.1539 41.4121 24.4504C39.9951 20.3744 39.4181 19.3189 38.4644 19.0374C37.7478 18.8297 36.6825 19.416 36.4262 20.1598C36.1749 20.8991 36.2995 21.7116 37.0641 24.3662C37.8819 27.166 37.8685 27.1321 37.7099 27.3189C37.6355 27.4077 37.5188 27.4713 37.4521 27.4597C37.3614 27.4523 35.9448 25.9798 32.0543 21.8745C26.7849 16.3169 26.7757 16.3071 26.4588 16.1781C25.5647 15.8217 24.569 16.1563 24.0855 16.9749C23.8287 17.4125 23.7659 18.0855 23.938 18.5835L24.0659 18.9557L28.3033 23.4685C32.2716 27.6958 32.5359 27.9859 32.5129 28.1192C32.4786 28.3096 32.3164 28.4533 32.1194 28.481C31.9705 28.5006 31.8126 28.3332 27.227 23.4513C22.3814 18.2938 22.1723 18.0819 21.6655 17.8996C20.794 17.5869 19.6805 18.0234 19.2788 18.83Z"
            fill={props.color}
        />
        <path
            id="Vector_2"
            d="M9.98447 35.5094C9.85695 35.7785 10.4228 37.8674 10.9169 38.973C11.6309 40.5781 12.3005 41.574 13.6048 42.9571C14.6957 44.1137 15.1719 44.5201 16.3653 45.2825C17.8309 46.2251 20.3917 47.195 20.9047 46.9995C21.1177 46.9196 21.2823 46.6899 21.2745 46.4647C21.269 46.163 21.0344 46.003 20.3651 45.8159C18.6084 45.3338 17.1656 44.5928 15.6646 43.3859C14.762 42.6655 13.4604 41.1868 12.8386 40.1923C12.0708 38.9542 11.5653 37.7477 11.2722 36.4804C11.0175 35.3722 10.991 35.2948 10.8023 35.2031C10.5097 35.0606 10.1227 35.2024 9.98447 35.5094Z"
            fill={props.color}
        />
        <path
            id="Vector_3"
            d="M36.555 10.5342C36.5328 10.6389 36.5426 10.7971 36.5829 10.8892C36.6634 11.083 36.7391 11.1139 37.8305 11.433C40.8485 12.3158 43.5927 14.5451 45.2728 17.4802C45.901 18.5802 46.3407 19.7465 46.6182 21.0564C46.7659 21.7355 46.9119 21.9791 47.2127 22.0022C47.4371 22.0232 47.676 21.8722 47.7682 21.6643C47.9935 21.1636 47.1796 18.5554 46.3196 17.0322C45.6284 15.7963 45.2505 15.2971 44.1597 14.1405C42.8553 12.7575 41.9002 12.0308 40.3397 11.2241C39.2178 10.6361 37.2126 9.97917 36.9218 10.1047C36.7329 10.1805 36.5943 10.3392 36.555 10.5342Z"
            fill={props.color}
        />
        <path
            id="Vector_4"
            d="M13.8149 35.1335C13.7696 35.3715 13.9693 36.234 14.2527 37.0177C15.3158 39.9395 17.925 42.361 20.8221 43.1254C21.4775 43.2977 21.7328 43.243 21.8811 42.9171C22.0653 42.5109 21.8436 42.2364 21.2076 42.055C19.7547 41.6583 18.5733 40.9777 17.4849 39.9025C16.145 38.5902 15.3254 37.1591 15.0076 35.5896C14.8739 34.9252 14.727 34.7103 14.3682 34.7046C14.0958 34.6919 13.8752 34.8721 13.8149 35.1335Z"
            fill={props.color}
        />
        <path
            id="Vector_5"
            d="M35.8766 14.4195C35.8613 14.778 36.0672 14.9372 36.7226 15.1095C38.2709 15.5186 39.6562 16.4254 40.8832 17.8349C41.9251 19.0283 42.4813 20.1406 42.8187 21.6916C42.9582 22.3226 43.2242 22.5553 43.6355 22.3999C43.9646 22.2755 44.0345 22.0143 43.9182 21.4079C43.6272 19.9061 42.8399 18.3516 41.7153 17.0408C40.5028 15.627 38.7883 14.5191 37.0417 14.0181C36.7521 13.9378 36.4379 13.8808 36.3371 13.8922C36.0726 13.9371 35.8798 14.1468 35.8766 14.4195Z"
            fill={props.color}
        />
    </svg>
)
