import * as React from 'react'
import { SVGProps } from 'react'

export const Transmission = (props: SVGProps<SVGSVGElement>) => (
    <svg
        width="1em"
        height="1em"
        viewBox="0 0 15 15"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="M13.351 10.8711V4.12894C14.2923 3.92661 15 3.08817 15 2.08777C15 0.936584 14.063 0 12.9111 0C11.7594 0 10.8223 0.936584 10.8223 2.08777C10.8223 3.08817 11.53 3.92661 12.4714 4.12894V7.35361H7.93933V4.12894C8.88073 3.92661 9.58846 3.08817 9.58846 2.08777C9.58846 0.936584 8.65145 0 7.49954 0C6.34773 0 5.41081 0.936584 5.41081 2.08777C5.41081 3.08817 6.11853 3.92661 7.05985 4.12894V7.35361H2.52861V4.12894C3.47002 3.92661 4.17783 3.08817 4.17783 2.08777C4.17783 0.936584 3.24073 0 2.08892 0C0.937099 0 0 0.936584 0 2.08777C0 3.08817 0.707725 3.92661 1.64922 4.12894V7.79306C1.64922 8.03577 1.84608 8.23251 2.08892 8.23251H7.05985V10.8711C6.11853 11.0734 5.41081 11.9118 5.41081 12.9122C5.41081 14.0634 6.34773 15 7.49954 15C8.65145 15 9.58846 14.0634 9.58846 12.9122C9.58846 11.9118 8.88073 11.0734 7.93933 10.8711V8.23251H12.4714V10.8711C11.5301 11.0734 10.8223 11.9118 10.8223 12.9122C10.8223 14.0634 11.7594 15 12.9111 15C14.063 15 15 14.0634 15 12.9122C15 11.9118 14.2923 11.0734 13.351 10.8711Z"
            fill={props.color}
        />
    </svg>
)
