import * as React from 'react'
import { SVGProps } from 'react'

export const Pizza = (props: SVGProps<SVGSVGElement>) => (
    <svg
        width="1em"
        height="1em"
        viewBox="0 0 31 30"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="M29.8926 19.2061C27.0576 10.0851 19.9207 2.94808 10.7971 0.112398C9.39886 -0.31298 7.97073 0.512337 7.55784 1.85776C7.11089 3.22469 7.83955 4.67737 9.17277 5.1296C9.20542 5.14419 9.23889 5.15621 9.27236 5.1674C16.6893 7.4974 22.5084 13.3165 24.8384 20.7334C24.8488 20.7661 24.8616 20.7987 24.8754 20.8305C25.3378 22.1974 26.8207 22.8829 28.1292 22.454C29.5747 22.0175 30.3171 20.4644 29.8926 19.2061Z"
            fill={props.color}
        />
        <path
            d="M7.39979 10.706C7.10501 10.576 6.79645 10.5144 6.48256 10.4977L4.83867 15.2727C5.27372 15.5749 5.78949 15.7576 6.33608 15.7576C7.79041 15.7575 8.97352 14.5744 8.97352 13.1201C8.97346 12.0753 8.35623 11.1275 7.39979 10.706Z"
            fill={props.color}
        />
        <path
            d="M12.2178 24.2596C11.7688 23.3538 10.8631 22.7906 9.85261 22.7906C8.39829 22.7906 7.21523 23.9737 7.21523 25.4281C7.21523 26.1544 7.50456 26.8309 8.02912 27.3331C8.06828 27.3706 8.11423 27.3971 8.1552 27.4319L12.4215 25.9631C12.5364 25.3742 12.481 24.7909 12.2178 24.2596Z"
            fill={props.color}
        />
        <path
            d="M16.8858 17.516C16.4007 17.516 16.0066 17.9101 16.0066 18.3951C16.0066 18.8802 16.4007 19.2743 16.8858 19.2743C17.3708 19.2743 17.7649 18.8802 17.7649 18.3951C17.7649 17.9101 17.3708 17.516 16.8858 17.516Z"
            fill={props.color}
        />
        <path
            d="M23.2099 21.3938C20.8026 13.754 15.7093 9.48233 8.45515 6.73522C8.25668 6.66951 8.06184 6.57391 7.87028 6.46758L7.06759 8.79904C7.42163 8.8583 7.77192 8.94845 8.11008 9.09704C9.70268 9.80015 10.732 11.379 10.732 13.1202C10.732 15.5438 8.76002 17.5159 6.33635 17.5159C5.59462 17.5159 4.89199 17.2975 4.25987 16.9547L0.0481766 28.8351C-0.0617289 29.1528 0.0198649 29.5048 0.257671 29.7426C0.499229 29.9841 0.854853 30.0602 1.16511 29.9521L6.34491 28.0552C5.77592 27.3003 5.45722 26.3944 5.45722 25.4281C5.45722 23.0044 7.42925 21.0324 9.85291 21.0324C11.5365 21.0324 13.0466 21.9699 13.7936 23.4792C14.0865 24.0706 14.1887 24.7076 14.1985 25.3514L23.5422 22.1346C23.4102 21.9004 23.2976 21.6533 23.2099 21.3938ZM12.2329 19.0165C11.8895 19.3599 11.3329 19.3599 10.9895 19.0165C10.6462 18.6732 10.6462 18.1166 10.9895 17.7732C11.3329 17.4299 11.8894 17.4299 12.2329 17.7732C12.5762 18.1166 12.5762 18.6732 12.2329 19.0165ZM12.7479 11.9834C12.4045 11.64 12.4045 11.0837 12.7479 10.7403C13.0912 10.3969 13.6476 10.3969 13.991 10.7403L15.7492 12.4985C16.0926 12.8419 16.0926 13.3983 15.7492 13.7417C15.4058 14.0851 14.8495 14.0851 14.5061 13.7417L12.7479 11.9834ZM16.886 21.0324C15.4317 21.0324 14.2485 19.8493 14.2485 18.3949C14.2485 16.9406 15.4317 15.7575 16.886 15.7575C18.3403 15.7575 19.5234 16.9406 19.5234 18.3949C19.5234 19.8493 18.3403 21.0324 16.886 21.0324Z"
            fill={props.color}
        />
    </svg>
)
