import { useEffect, useState } from "react"

export const useSharedStore = <I>(reducerName: string, initialState: I)=>{
    const [state, setState] = useState<I>(initialState)
    const [isInited, setIsInited] = useState(false)


    const callback = (state: I) => {
        setIsInited(true)
        setState(state)
    }

    const event = `store:${reducerName}:change`

    useEffect(() => {
        if(typeof window.alt.on === 'function'){
            window.alt.on(event, callback)
        }

        return () => {
            if(typeof window.alt.off === 'function'){
                window.alt.off(event, callback)
            }
        }
    }, [])

    useEffect(() => {
        if(typeof window.alt.emit === 'function'){
            window.alt.emit(`store:${reducerName}:get`)
        }
    }, [])

    return { state, isInited }
}