import * as React from 'react'
import { SVGProps } from 'react'

export const FuelStation = (props: SVGProps<SVGSVGElement>) => (
    <svg
        width="1em"
        height="1em"
        viewBox="0 0 13 15"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="M12.9278 2.43018L12.1346 0.886935C11.9686 0.564025 11.5704 0.435733 11.245 0.600528C10.9197 0.765294 10.7906 1.16069 10.9565 1.48363L11.4968 2.53498C10.632 2.82003 10.0063 3.63012 10.0063 4.58312V6.19302C10.0063 6.46492 10.2283 6.68538 10.5023 6.68538H11.6774V8.33468C11.6774 9.06681 11.0774 9.66242 10.34 9.66242H9.53492V1.20967C9.53489 0.542667 8.9883 0 8.3164 0H2.46835C1.62174 0 0.93296 0.683732 0.93296 1.52417V12.6004C0.398411 12.7284 0 13.2068 0 13.7762V14.5077C0 14.7796 0.222048 15 0.495948 15H9.97196C10.2459 15 10.4679 14.7796 10.4679 14.5077V13.7762C10.4679 13.2068 10.0695 12.7284 9.53492 12.6004V10.9753H10.34C11.8067 10.9753 13 9.79068 13 8.3346V2.72851C13 2.62477 12.9752 2.52252 12.9278 2.43018ZM7.43807 4.90167H3.02984C2.80157 4.90167 2.61653 4.71798 2.61653 4.49137V2.09945C2.61653 1.87284 2.80157 1.68914 3.02984 1.68914H7.43807C7.66634 1.68914 7.85138 1.87284 7.85138 2.09945V4.4914C7.85138 4.71798 7.66634 4.90167 7.43807 4.90167Z"
            fill={props.color}
        />
    </svg>
)
