import { MutableRefObject } from 'react';
import { audioLibrary } from '../PoliceRadar.config';

export const playAudio = (audioIndex: number, audioRef:MutableRefObject<HTMLAudioElement | null>) => {
    let beepVolume = 3
    if(localStorage.getItem('beepAudioVolume') === 'OFF'){
        beepVolume = 0
    } else {
        beepVolume = (Number(localStorage.getItem('beepAudioVolume')) ?? 3) / 5
    }
    const audio = audioRef.current
    if (audio) {
        audio.volume = beepVolume
        audio.src = audioLibrary[audioIndex]
        audio.pause()
        audio.currentTime = 0
        audio.play()
    }
}