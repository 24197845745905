import React from 'react'
import styles from './AfterPaymentScreen.module.scss'
import paymentSuccess from './assets/paymentSuccess.svg'
import { formatNumbers } from 'pages/ui/PaymentSystem/utils/formatNumbers'

export default function AfterPaymentScreen({
    summ,
}:{
    summ:number,
}){
    return(
        <div className={styles.wrapper}>
            <img src={paymentSuccess} className={styles.paymentSuccess} />
            <div className={styles.text}>Платеж прошел успeшно</div>
            <div className={styles.value}>$ {formatNumbers(summ)}</div>
        </div>
    )
}