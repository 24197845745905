import * as React from 'react'
import { SVGProps } from 'react'

export const Toilet = (props: SVGProps<SVGSVGElement>) => (
    <svg
        width="1em"
        height="1em"
        viewBox="0 0 23 30"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="M8.78906 0H0.878906C0.393516 0 0 0.393398 0 0.878906V3.51562H8.78906C9.7599 3.51562 10.5469 2.72854 10.5469 1.75781C10.5469 0.786973 9.7599 0 8.78906 0Z"
            fill={props.color}
        />
        <path
            d="M20.2148 14.1211H8.78906V17.6367H22.8516V16.7578C22.8516 15.3016 21.6711 14.1211 20.2148 14.1211Z"
            fill={props.color}
        />
        <path
            d="M0 5.27344V15C0 16.4562 1.18049 17.6367 2.63672 17.6367H7.03125V5.27344H0Z"
            fill={props.color}
        />
        <path
            d="M20.2148 28.2422H17.5781V27.1882C20.5381 25.6775 22.4889 22.6897 22.7901 19.3945H1.75781V22.0312C1.75781 23.644 2.39379 25.2502 3.51562 26.396V29.1211C3.51562 29.6069 3.90873 30 4.39453 30C4.60453 30 20.0501 30 20.2148 30C20.7006 30 21.0937 29.6069 21.0937 29.1211C21.0937 28.6353 20.7006 28.2422 20.2148 28.2422Z"
            fill={props.color}
        />
    </svg>
)
