import type { SignupProps, LoginProps } from './types';
import { rpc } from "altv-xrpc-webview"
import { baseApi } from './baseApi';

export const authApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    register: builder.mutation<SignupProps, SignupProps>({
      async queryFn(data) {
        try {
          const res = await rpc.emitServerWithTimeout("auth:sign-up", 2000, data);
          return { data:res };
        } catch (error: any) {
          return { error };
        }
      },
    }),
    login: builder.mutation<LoginProps, LoginProps>({
      async queryFn(data) {
        try {
          const res = await rpc.emitServerWithTimeout("auth:login", 2000, data);
          return { data:res };
        } catch (error: any) {
          return { error };
        }
      },
    }),
  }),
});

export const { useRegisterMutation, useLoginMutation } = authApi;
