import React, { useState } from "react";
import { Props } from './ProductCard.typings'
import classNames from "classnames";

import supermarketProductCard from './SupermarketProductCard.module.scss'
import buildingStoreProductCard from './BuildingStoreProductCard.module.scss'
import clothesStoreProductCard from './ClothesStoreProductCard.module.scss'
import electronicsStoreProductCard from './ElectronicsStoreProductCard.module.scss'
import furnitureStoreProductCard from './FurnitureStoreProductCard.module.scss'
import jewelryStoreProductCard from './JewelryStoreProductCard.module.scss'
import pharmacyProductCard from './PharmacyProductCard.module.scss'
import tattooShopProductCard from './FurnitureStoreProductCard.module.scss'
import { SubCategories } from "../../../TattooShop/TattooShop";

const stylesObject: any = {            //ProductCard styles
    supermarket: supermarketProductCard,
    pharmacy: pharmacyProductCard,
    electronicsStore: electronicsStoreProductCard,
    furnitureStore: furnitureStoreProductCard,
    jewelryStore: jewelryStoreProductCard,
    buildingStore: buildingStoreProductCard,
    clothesStore: clothesStoreProductCard,
    tattooShop: tattooShopProductCard
}

const ProductCard = (props: Props) => {

    const mainStyles = stylesObject[props.storeType]

    const [cardStyle, setCardStyle] = useState(mainStyles.mainShape)
    const [toCart, setToCart] = useState<boolean>(false)
    const [greenVisible, setGreenVisible] = useState<boolean>(false)
    const isUnAvailable = ((props.bought && props.subCategory !== SubCategories.delete) || props.unavailable)
    const product = {
        productId: props.id,
        productIcon: props.image,
        productDescription: props.description,
        productValue: props.value,
        productPrice: props.price
    }


    const mouseEnter = () => {
        setGreenVisible(true)
        if (props.value == 0) {
            !isUnAvailable && setCardStyle(`${mainStyles.mainShape} ${mainStyles.mainShapeHover}`);
            !isUnAvailable && setToCart(true)
        }
    }

    const mouseLeave = () => {
        setGreenVisible(false)
        props.value == 0 && setCardStyle(mainStyles.mainShape)
        setToCart(false)

    }


    const addToCart = () => {

        setToCart(false)
        props.onSelect({
            ...product,
            productValue: '1',
        })
    }

    return (
        <div className={cardStyle} onMouseEnter={mouseEnter} onMouseLeave={mouseLeave}>
            {!isUnAvailable && <div className={mainStyles.priceContainer}>
                {props.sale.isSale && <div className={classNames(mainStyles.price, props.sale.isSale && mainStyles.oldPrice)}>{props.sale.oldPrice?.toLocaleString()} $</div>}
                <div className={classNames(mainStyles.price, props.sale.isSale && mainStyles.priceSale)}>{props.price.toLocaleString()} $</div>

            </div>}
            {(props.unavailable || (props.bought && props.subCategory !== SubCategories.delete)) &&
                <div className={mainStyles.cardTitle}>
                    <img src={props.icon} className={mainStyles.icon} />
                    <span>
                        {props.bought
                            ? "Куплено"
                            : "Недоступно"
                        }
                    </span>
                </div>

            }
            {props.sale.isSale && <div className={mainStyles.saleLabel}>Sale</div>}
            <div className={classNames(props.storeType == 'tattooShop' ? mainStyles.imageContainerTattoo : mainStyles.imageContainer)}>
                <img
                    className={classNames(props.storeType == 'tattooShop'
                        ? isUnAvailable
                            ? mainStyles.imageTattooFiltered
                            : mainStyles.imageTattoo
                        : mainStyles.image)}
                    src={props.image}
                />
            </div>
            <div className={mainStyles.description}>{props.description}</div>

            {greenVisible && <div className={mainStyles.greenBackground}></div>}
            {toCart && //объединить блоки
                <div
                    className={mainStyles.toCart}
                    onClick = {() => props.storeType !== 'tattooShop' ? addToCart() : props.onSelect({...product})}>
                            {props.buyButtonTitle}
                </div>}
            {props.value >0 && <div className={mainStyles.addToCart}>
                <div
                    className={mainStyles.valueButton}
                    onClick = {() => props.onPlusOrMinus(product, false)}>-
                </div>
                <div className={mainStyles.productValue}>{props.value}</div>
                <div
                    className={mainStyles.valueButton}
                    onClick = {() => props.onPlusOrMinus(product, true)}>+
                </div>
            </div>}
        </div>
    )

}

export default ProductCard