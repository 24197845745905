import React, { useContext } from 'react'
import styles from './DeathScreen.module.scss'
import {Button} from '../Button/Button'
import { returnToTheLobby } from './returnToTheLobby'
import cn from 'classnames'
import { NavigationContext } from '../../context/context'

const DeathScreen = () => {

    const params = useContext(NavigationContext)
    const currentYear = new Date().getFullYear()

    const rip = String(params.dateOfBirth) + '-' + String(currentYear)
    return(
        <div className={styles.deathScreen}>
            <div className={styles.radius1}></div>
            <div className={styles.info}>
                <div className={styles.death}>УМЕР</div>
                <div className={styles.person}>{params.name}<br/>{rip}</div>
                <Button
                    size = 'M'
                    onClick = {() => returnToTheLobby(true)}
                    className={cn(styles.btn)}
                >Вернуться в лобби</Button>
            </div>

        </div>
    )
}

export default DeathScreen