import * as React from 'react'
import { SVGProps } from 'react'

export const Unload = (props: SVGProps<SVGSVGElement>) => (
    <svg
        width="109"
        height="91"
        viewBox="0 0 109 91"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
        >
        <path
            d="M68.6415 11.0922L59.8289 6.3032C52.0914 2.10259 48.2227 0 44.0631 0C39.9036 0 36.0348 2.09803 28.2973 6.3032L26.8829 7.074L66.2005 30.3302L83.8962 21.1627C81.0497 17.8241 76.4584 15.3293 68.6415 11.0831V11.0922ZM87.0159 27.2014L69.3994 36.3232V50.1702C69.3994 51.0774 69.0513 51.9475 68.4315 52.589C67.8118 53.2305 66.9712 53.5909 66.0947 53.5909C65.2182 53.5909 64.3777 53.2305 63.7579 52.589C63.1381 51.9475 62.79 51.0774 62.79 50.1702V39.7394L47.3679 47.721V90.7807C50.5316 89.9643 54.1316 88.0122 59.8289 84.9153L68.6415 79.3667C67.9528 59.9896 69.0653 60.0481 71.1381 55.2116C72.7307 51.4955 75.3851 49.3719 87.9481 45.9212C87.9481 37.2874 88.1263 31.3153 87.0159 27.2014ZM40.7584 90.7807V47.7255L1.11039 27.2014C5.06288e-07 31.3153 0 36.7155 0 45.3402V45.8738C0 57.3901 -5.06288e-07 63.1506 2.63057 67.7754C5.26554 72.4047 10.0067 74.9816 19.4847 80.1309L28.2973 84.9153C33.9947 88.0122 37.5947 89.9643 40.7584 90.7807ZM4.23006 21.1673L44.0631 41.7826L59.0931 34.0063L19.9386 10.8459L19.4847 11.0922C11.6723 15.3338 7.07654 17.8287 4.23006 21.1718V21.1673Z"
            fill={props.color}
        />
        <path
            d="M80.2644 59.064C81.0645 59.313 82.3833 59.8985 83.2003 60.3707C89.0649 63.7699 93.37 71.2436 94.5041 79.9753C94.5643 80.447 94.6179 80.9124 94.6194 81.0081L94.6223 81.1869L91.5503 81.2364L88.4718 81.2859L93.4824 85.9954L98.4931 90.7049L103.337 85.856L108.174 81.0007L105.115 81.0308C102.562 81.0591 102.055 81.0545 102.054 80.9779C102.051 80.7608 101.879 79.4926 101.747 78.6772C100.85 73.1989 98.7449 68.334 95.6312 64.5648C94.8997 63.676 93.1222 61.9802 92.2499 61.3364C88.461 58.5424 84.222 57.6271 80.0018 58.6914C79.8313 58.7324 79.6678 58.7925 79.6418 58.8121C79.6159 58.8381 79.894 58.9486 80.2644 59.064Z"
            fill={props.color}
        />
    </svg>
)