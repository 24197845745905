export const formatNumbers = (value: number): string => {
    // Преобразуем число в строку и разбиваем на части целое и дробное
    const [integerPart, fraction] = value.toString().split('.');

    // Разбиваем целую часть на группы по три разряда
    const formattedInteger = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ' ');

    // Собираем обратно целую и дробную часть
    return fraction ? `${formattedInteger}.${fraction}` : formattedInteger;
}
