import React, { useState } from 'react'
import styles from './GaitSelect.module.scss'
import { gaitVariants } from '../../../../../../../features/ui/PlayerInfoForm/PlayerInfoForm.config'
import { CarouselArrow } from 'shared/icons/generated'
import { AnimatePresence, motion } from 'framer-motion'
import {Props} from './GaitSelect.typings'
import GaitVariant from '../GaitVariant/GaitVariant'

const GaitSelect = (props:Props) => {

    const [clicked, setClicked] = useState<boolean>(false)

    const handleClick = () => {
        setClicked(prev => !prev)
    }

    return (
        <div
            className={styles.wrapper}
            onClick={handleClick}
        >
            <img className={styles.selectedIcon} src={props.selectedGait.icon} />
            <AnimatePresence>
                <motion.div
                    key="arrow"
                    className={styles.arrow}
                    initial={{ transform: 'rotate(90deg)' }}
                    animate={clicked ? { transform: 'rotate(270deg)' } : { transform: 'rotate(90deg)' }}
                    exit={{ transform: 'rotate(90deg)' }}
                    transition={{ duration: 0.5 }}
                >
                    <CarouselArrow width={8} height={8} color="#fff" />
                </motion.div>
                <motion.div
                    className={styles.optionsContainer}
                    initial={{ transform: 'scaleY(0%)' }}
                    animate={clicked ? { transform: 'scaleY(100%)', originY: '0px' } : { transform: 'scaleY(0%)', originY: '0px'}}
                    transition={{ duration: 0.2 }}
                >
                    {
                        gaitVariants.map(item => {
                            return (
                                <GaitVariant
                                    key={item.id}
                                    id={item.id}
                                    icon={item.icon}
                                    title={item.title}
                                    selectedGait={props.selectedGait.id}
                                    onSelect={props.handleGaitSelect}
                                />
                            )
                        })
                    }
                </motion.div>

            </AnimatePresence>
        </div>
    )
}

export default GaitSelect