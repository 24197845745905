import * as React from 'react'
import { SVGProps } from 'react'

export const HeadBag = (props: SVGProps<SVGSVGElement>) => (
    <svg
        width="1em"
        height="1em"
        viewBox="0 0 33 45"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="M9.09254 6.04807C9.561 6.35539 10.0507 6.62907 10.5578 6.86767C10.7283 6.73858 10.9084 6.62347 11.0962 6.52232C11.3712 6.38525 11.7002 6.41762 11.9432 6.60473C12.1868 6.79184 12.3016 7.10137 12.24 7.4024C12.24 7.4024 12.2303 7.4553 12.2195 7.52792C13.1787 7.83664 14.1702 8.03333 15.1745 8.1146L13.9079 4.29212C13.7682 3.87093 13.9966 3.41628 14.4183 3.27661C14.8395 3.13741 15.2941 3.36581 15.4338 3.78699L16.2709 6.31654L17.1194 3.7868C17.2602 3.36614 17.7157 3.13945 18.1364 3.28023C18.5575 3.42149 18.7842 3.877 18.6429 4.29766L17.3672 8.1014C19.5238 7.87936 21.6028 7.17323 23.4479 6.03467C23.4201 4.95848 23.7626 2.63802 26.6866 0.254577C25.4566 -0.0123194 24.1718 0.166142 23.0604 0.757198C21.0525 1.75402 20.2214 1.42294 19.1753 0.801363C18.3162 0.245567 17.3041 -0.0252493 16.2827 0.026227C15.2611 -0.0290923 14.2484 0.237915 13.3872 0.790475C12.3427 1.40715 11.5035 1.73606 9.5023 0.731694C8.39314 0.136943 7.10905 -0.0458698 5.87801 0.215435C8.83052 2.64216 9.13062 4.98885 9.09254 6.04807Z"
            fill={props.color}
        />
        <path
            d="M30.3151 37.6849C32.5347 32.7933 32.6183 25.4598 32.2973 20.1709C31.6426 9.37129 25.704 7.56337 24.4494 7.30172C21.9762 8.87013 19.1128 9.71381 16.1841 9.73717C15.0324 9.73692 13.8854 9.60007 12.766 9.32953C12.5443 9.27524 12.3433 9.21136 12.1347 9.15083C12.1189 10.6055 12.6469 12.0141 13.6152 13.0997C13.9186 13.4154 13.9133 13.9157 13.6034 14.2249C13.293 14.5337 12.7927 14.537 12.4782 14.2324C11.2194 12.8653 10.5219 11.074 10.5244 9.21515C9.74606 10.5097 9.49541 12.0544 9.82553 13.5285C9.89734 13.9659 9.6012 14.3781 9.16426 14.4504C9.12047 14.457 9.07669 14.4598 9.03243 14.4588C8.64037 14.4576 8.30705 14.1731 8.24332 13.7867C7.79774 11.802 8.20959 9.72168 9.37785 8.05653C8.91984 7.82332 8.47583 7.56271 8.04919 7.27664C6.69305 7.54974 0.885686 9.34952 0.192923 20.1105C-0.147994 25.3991 -0.0920335 32.732 2.11066 37.6333C2.263 37.9727 2.28922 38.3552 2.18512 38.712L1.1419 44.4885C1.13509 44.527 1.1254 44.5649 1.11282 44.602L6.41186 43.4882C6.53026 43.4547 6.65248 43.4381 6.77563 43.4393C7.11525 43.4443 7.44553 43.5536 7.72129 43.7523C9.12844 44.7469 13.1083 44.9637 16.1977 44.9695C19.2875 44.9753 23.2682 44.7735 24.6834 43.7818C25.0774 43.4973 25.5798 43.4078 26.0481 43.5381L31.2516 44.6125L30.2317 38.7671C30.129 38.4081 30.1586 38.0238 30.3151 37.6849Z"
            fill={props.color}
        />
    </svg>
)
