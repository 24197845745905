import * as React from 'react'
import { SVGProps } from 'react'

export const Headlights = (props: SVGProps<SVGSVGElement>) => (
    <svg
        width="1em"
        height="1em"
        viewBox="0 0 15 15"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="M9.36501 0.513135C9.31063 0.215625 9.05188 0 8.75001 0C3.92502 0 0 3.36437 0 7.5C0 11.635 3.92499 15 8.75001 15C9.05188 15 9.31063 14.7838 9.36501 14.4862L9.42937 14.1331C10.1931 9.64002 10.1925 5.17439 9.42811 0.859395L9.36501 0.513135Z"
            fill={props.color}
        />
        <path
            d="M10.9281 4.28568L14.0531 6.16068C14.1537 6.2213 14.2649 6.25006 14.3743 6.25006C14.5868 6.25006 14.7937 6.14193 14.9106 5.94631C15.0881 5.65068 14.9924 5.2663 14.6962 5.08882L11.5712 3.21382C11.2749 3.0357 10.8906 3.13258 10.7137 3.42819C10.5362 3.72441 10.6318 4.10817 10.9281 4.28568Z"
            fill={props.color}
        />
        <path
            d="M14.6964 8.83895L11.5714 6.96395C11.2746 6.78582 10.8908 6.88271 10.7139 7.17831C10.5364 7.47395 10.6321 7.85832 10.9283 8.0358L14.0533 9.9108C14.154 9.97142 14.2652 9.99954 14.3739 9.99954C14.5864 9.99954 14.7933 9.89205 14.9108 9.69641C15.0883 9.40022 14.9927 9.01646 14.6964 8.83895Z"
            fill={props.color}
        />
        <path
            d="M14.6964 12.5889L11.5714 10.7139C11.2746 10.5358 10.8908 10.6327 10.7139 10.9283C10.5364 11.2239 10.6321 11.6083 10.9283 11.7858L14.0533 13.6608C14.154 13.7214 14.2652 13.7495 14.3739 13.7495C14.5864 13.7495 14.7933 13.6421 14.9108 13.4464C15.0883 13.1502 14.9927 12.7665 14.6964 12.5889Z"
            fill={props.color}
        />
    </svg>
)
