import * as React from 'react'
import { SVGProps } from 'react'

export const Sleeping = (props: SVGProps<SVGSVGElement>) => (
    <svg
        width="78"
        height="55"
        viewBox="0 0 78 55"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="M0.851571 0.148975C0.578425 0.261448 0.273145 0.50246 0.176741 0.695267C0.0482021 0.920212 0 8.87356 0 27.5438V54.0872L0.465954 54.5531C0.996177 55.0673 1.39786 55.1315 2.08876 54.7781C2.79572 54.4085 2.89213 53.7819 2.89213 49.1063V44.8966H38.5617H74.2313V49.1063C74.2313 53.7819 74.3277 54.4085 75.0346 54.7781C75.7255 55.1315 76.1272 55.0673 76.6574 54.5531L77.1234 54.0872V34.5332V14.9792L76.6574 14.5132C75.8219 13.6938 74.7133 13.9991 74.3919 15.1398C74.3116 15.4773 74.2313 17.5178 74.2313 19.8636V24.009H38.5617H2.90819L2.85999 12.4405L2.81179 0.872009L2.41011 0.486393C1.87988 -0.0277634 1.46213 -0.124168 0.851571 0.148975ZM74.2313 40.5584V42.1652H38.5617H2.89213V40.5584V38.9517H38.5617H74.2313V40.5584Z"
            fill={props.color}
        />
        <path
            d="M5.94491 11.1875C5.20581 11.5892 5.14154 11.9105 5.14154 16.2648C5.14154 20.3941 5.18974 20.7797 5.76817 21.2296C5.96098 21.3742 8.38715 21.4224 15.9227 21.4385C25.6274 21.4385 25.8202 21.4385 26.1577 21.1171C26.6558 20.6512 26.7522 20.1049 26.5272 18.7392C26.0131 15.365 23.5548 12.5372 20.1324 11.3642C19.4736 11.1393 18.365 11.0911 12.8539 11.0429C7.76052 10.9947 6.26626 11.0268 5.94491 11.1875Z"
            fill={props.color}
            />
    </svg>
)