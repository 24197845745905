import * as React from 'react'
import { SVGProps } from 'react'

export const EmergencySignal = (props: SVGProps<SVGSVGElement>) => (
    <svg
        width="1em"
        height="1em"
        viewBox="0 0 20 15"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="M10 0C5.92926 0 2.61743 3.3645 2.61743 7.50009C2.61743 11.6356 5.92926 15 10 15C14.0708 15 17.3826 11.6356 17.3826 7.50009C17.3827 3.36459 14.0708 0 10 0ZM10 13.6999C6.63495 13.6999 3.89725 10.9188 3.89725 7.50017C3.89725 4.0815 6.63495 1.30026 10 1.30026C13.3651 1.30026 16.1028 4.0815 16.1028 7.50017C16.1029 10.9187 13.3652 13.6999 10 13.6999Z"
            fill={props.color}
        />
        <path
            d="M3.83053 1.23255C4.08044 0.978667 4.08044 0.567032 3.83053 0.313152C3.58054 0.0592716 3.17544 0.0592716 2.92553 0.313152C-0.975177 4.27599 -0.975177 10.7242 2.92553 14.6871C3.05053 14.8141 3.21426 14.8775 3.37807 14.8775C3.54181 14.8775 3.70562 14.8141 3.83053 14.6871C4.08052 14.4332 4.08052 14.0216 3.83062 13.7678C0.428781 10.3117 0.428781 4.68849 3.83053 1.23255Z"
            fill={props.color}
        />
        <path
            d="M17.0746 0.312969C16.8246 0.0590885 16.4195 0.0590885 16.1696 0.312969C15.9197 0.566849 15.9197 0.978483 16.1696 1.23236C19.5715 4.68831 19.5715 10.3116 16.1696 13.7676C15.9197 14.0215 15.9197 14.4331 16.1696 14.6869C16.2946 14.8138 16.4584 14.8773 16.6221 14.8773C16.7859 14.8773 16.9497 14.8139 17.0747 14.6869C20.9755 10.7239 20.9755 4.27581 17.0746 0.312969Z"
            fill={props.color}
        />
        <path
            d="M9.99977 9.49391C10.3533 9.49391 10.6397 9.20285 10.6397 8.84382V3.30682C10.6397 2.9478 10.3533 2.65674 9.99977 2.65674C9.64629 2.65674 9.35986 2.9478 9.35986 3.30682V8.84382C9.35986 9.20285 9.64637 9.49391 9.99977 9.49391Z"
            fill={props.color}
        />
        <path
            d="M10.0001 11.6934C10.4397 11.6934 10.796 11.3314 10.796 10.8848C10.796 10.4382 10.4397 10.0762 10.0001 10.0762C9.56047 10.0762 9.2041 10.4382 9.2041 10.8848C9.2041 11.3314 9.56047 11.6934 10.0001 11.6934Z"
            fill={props.color}
        />
    </svg>
)
