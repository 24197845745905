import React from 'react'
import { HintsCarousel } from 'shared/ui/HintsCarousel/HintsCarousel'
import { PageLayout } from 'shared/ui/PageLayout/PageLayout'
import { AuthForm } from 'widgets/ui/AuthForm/AuthForm'
import styles from './Auth.module.scss'
//import { Logo } from 'shared/icons/Logo'

export const Auth: React.FC = () => {
    return (
        <PageLayout className={styles.container}>
            <div className={styles.content}>
                <div className={styles.col}><HintsCarousel /></div>
                <div className={styles.col}><AuthForm /></div>
            </div>
            {/* <Logo className={styles.logo} /> */}
        </PageLayout>
    )
}
