import * as React from 'react'
import { SVGProps } from 'react'

export const Abs = (props: SVGProps<SVGSVGElement>) => (
    <svg
        width="1em"
        height="1em"
        viewBox="0 0 20 15"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="M10 0C5.92926 0 2.61743 3.3645 2.61743 7.50009C2.61743 11.6356 5.92926 15 10 15C14.0708 15 17.3826 11.6356 17.3826 7.50009C17.3827 3.36459 14.0708 0 10 0ZM10 13.6999C6.63495 13.6999 3.89725 10.9188 3.89725 7.50017C3.89725 4.0815 6.63495 1.30026 10 1.30026C13.3651 1.30026 16.1028 4.0815 16.1028 7.50017C16.1029 10.9187 13.3652 13.6999 10 13.6999Z"
            fill={props.color}
        />
        <path
            d="M3.83053 1.23255C4.08044 0.978667 4.08044 0.567032 3.83053 0.313152C3.58054 0.0592716 3.17544 0.0592716 2.92553 0.313152C-0.975177 4.27599 -0.975177 10.7242 2.92553 14.6871C3.05053 14.8141 3.21426 14.8775 3.37807 14.8775C3.54181 14.8775 3.70562 14.8141 3.83053 14.6871C4.08052 14.4332 4.08052 14.0216 3.83062 13.7678C0.428781 10.3117 0.428781 4.68849 3.83053 1.23255Z"
            fill={props.color}
        />
        <path
            d="M17.0746 0.312969C16.8246 0.0590885 16.4195 0.0590885 16.1696 0.312969C15.9197 0.566849 15.9197 0.978483 16.1696 1.23236C19.5715 4.68831 19.5715 10.3116 16.1696 13.7676C15.9197 14.0215 15.9197 14.4331 16.1696 14.6869C16.2946 14.8138 16.4584 14.8773 16.6222 14.8773C16.7859 14.8773 16.9497 14.8139 17.0747 14.6869C20.9755 10.7239 20.9755 4.27581 17.0746 0.312969Z"
            fill={props.color}
        />
        <path
            d="M8.245 9H7.44L7.195 8.245H6.085L5.835 9H5.075V8.72L6.235 5.5H7.08L8.245 8.72V9ZM6.955 7.525L6.645 6.57L6.325 7.525H6.955ZM10.1303 9H8.49531V5.5H9.98031C10.3236 5.5 10.5903 5.585 10.7803 5.755C10.9736 5.925 11.0703 6.17 11.0703 6.49C11.0703 6.80667 10.952 7.03833 10.7153 7.185C10.882 7.27167 11.007 7.37833 11.0903 7.505C11.1736 7.63167 11.2153 7.79667 11.2153 8C11.2153 8.33 11.1203 8.58 10.9303 8.75C10.7436 8.91667 10.477 9 10.1303 9ZM9.35531 6.25V6.885H9.93031C10.0036 6.885 10.0653 6.85833 10.1153 6.805C10.1686 6.75167 10.1953 6.675 10.1953 6.575C10.1953 6.47167 10.1686 6.39167 10.1153 6.335C10.0653 6.27833 10.0036 6.25 9.93031 6.25H9.35531ZM9.35531 7.605V8.25H10.0753C10.1486 8.25 10.212 8.22333 10.2653 8.17C10.3186 8.11333 10.3453 8.035 10.3453 7.935C10.3453 7.82833 10.3186 7.74667 10.2653 7.69C10.212 7.63333 10.1486 7.605 10.0753 7.605H9.35531ZM12.8145 9.075C12.4011 9.075 12.0595 8.97 11.7895 8.76C11.5228 8.55 11.3895 8.25167 11.3895 7.865H12.2495C12.2495 8.01833 12.3128 8.12833 12.4395 8.195C12.5661 8.255 12.7128 8.285 12.8795 8.285C13.1761 8.285 13.3245 8.19333 13.3245 8.01C13.3245 7.94 13.2878 7.88333 13.2145 7.84C13.1445 7.79667 13.0095 7.74167 12.8095 7.675L12.2395 7.485C11.7328 7.30833 11.4795 6.99 11.4795 6.53C11.4795 6.19333 11.5978 5.925 11.8345 5.725C12.0745 5.525 12.4011 5.425 12.8145 5.425C13.2145 5.425 13.5378 5.52833 13.7845 5.735C14.0311 5.94167 14.1545 6.23833 14.1545 6.625H13.2895C13.2895 6.495 13.2411 6.395 13.1445 6.325C13.0511 6.25167 12.9278 6.215 12.7745 6.215C12.4945 6.215 12.3545 6.305 12.3545 6.485C12.3545 6.55833 12.3778 6.61333 12.4245 6.65C12.4711 6.68667 12.5661 6.73 12.7095 6.78L13.2645 6.97C13.5578 7.06667 13.7795 7.18167 13.9295 7.315C14.1028 7.455 14.1895 7.67167 14.1895 7.965C14.1895 8.30833 14.0711 8.58 13.8345 8.78C13.5978 8.97667 13.2578 9.075 12.8145 9.075Z"
            fill={props.color}
        />
    </svg>
)
