export const CHAT_TAGS = [
    { id: 1, text: 'GOV' },
    { id: 2, text: 'DO' },
    { id: 3, text: 'RP' },
    { id: 4, text: 'ME' },
    { id: 5, text: 'TODO' },
    { id: 6, text: 'B' },
    { id: 7, text: 'S' },
    { id: 8, text: 'W' },
]
