import React from 'react'
import styles from './LifeGoalWidget.module.scss'
import hexagonGroup from '../../assets/hexagonGroup.svg'
import questionSign from '../../../../assets/questionSign.svg'
import { Props } from './LifeGoalWidget.typings'
import { lifeGoalArr } from '../LifeGoalSelect/LifeGoalSelect.config'
import { TraitsSlot, VisibleComponent } from '../../About.config'

const LifeGoalWidget = (props: Props) => {

    const mainGoal = props.lifeGoals.mainGoalId ? lifeGoalArr.find(item => item.id === props.lifeGoals.mainGoalId) : null
    const mainGoalIcon = mainGoal ? mainGoal.icon : questionSign
    const additionalGoal = mainGoal ? mainGoal.subGoals.find(item => item.id === props.lifeGoals.additionalGoalId) : null
    const additionalGoalIcon = additionalGoal ? additionalGoal.icon : questionSign

    const trait1 = props.characterTraits.trait1?.icon ?? questionSign
    const trait2 = props.characterTraits.trait2?.icon ?? questionSign
    const trait3 = props.characterTraits.trait3?.icon ?? questionSign

    return (
        <div className={styles.wrapper} style={{background:props.isTitle ? 'rgba(32, 32, 32, 1)' : 'transparent'}}>
            {
                props.isTitle &&
                <div className={styles.title}>Черты характера и жизненная цель:</div>
            }
            <img src={hexagonGroup} className={styles.hexagonGroup} />
            <div
                className={styles.mainGoal}
                onClick={() => props.onMainGoalSelect(VisibleComponent.goalSelect)}
            >
                <img src={mainGoalIcon} className={styles.mainGoalIcon} />
            </div>
            <div
                className={styles.additionalGoal}
            >
                <img src={additionalGoalIcon} className={styles.additionalGoalIcon} />
            </div>
            <div
                className={styles.trait1}
                onClick={() => props.onTraitSelect(TraitsSlot.trait1)}
            >
                <img src={trait1} className={styles.trait1Icon} />
            </div>
            <div
                className={styles.trait2}
                onClick={() => props.onTraitSelect(TraitsSlot.trait2)}
            >
                <img src={trait2} className={styles.trait2Icon} />
            </div>
            <div
                className={styles.trait3}
                onClick={() => props.onTraitSelect(TraitsSlot.trait3)}
            >
                <img src={trait3} className={styles.trait3Icon} />
            </div>
        </div>
    )
}

export default LifeGoalWidget