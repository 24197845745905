import React from 'react'
import styles from './SubGoals.module.scss'
import { Props } from './SubGoals.typings'
import { GoalsType } from 'pages/ui/CharacterPersonalization/screens/About/About.config'
import { VisibleComponent } from 'pages/ui/CharacterPersonalization/screens/About/About.config'

const SubGoals = (props: Props) => {

    const handleSelect = (event:React.MouseEvent, id:number) => {
        event.stopPropagation()
        props.onSelect(GoalsType.additionalGoal, id)
        props.switchComponents(VisibleComponent.traitSelect)
        props.closeTiles(false)
    }

    return (
        <div className={styles.wrapper}>
            {props.subGoals.map(item => {
                return (
                    <div
                        key={item.id}
                        className={styles.tile}
                        onClick={(event) => handleSelect(event,item.id)}
                    >
                        <img src={item.icon} />
                    </div>
                )
            })}
        </div>
    )
}

export default SubGoals