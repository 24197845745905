import * as React from 'react'
import { SVGProps } from 'react'

export const DriverLicense = (props: SVGProps<SVGSVGElement>) => (
    <svg
        width="1em"
        height="1em"
        viewBox="0 0 39 40"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="M9.03489 23.0891L6.98083 22.6362C7.20775 24.0274 8.20614 25.1624 9.52132 25.5864V23.6869C9.31984 23.5249 9.15333 23.3212 9.03489 23.0891Z"
            fill={props.color}
        />
        <path
            d="M11.8065 23.6869V25.5864C13.1217 25.1624 14.1201 24.0274 14.347 22.6362L12.293 23.0891C12.1745 23.3212 12.008 23.5249 11.8065 23.6869Z"
            fill={props.color}
        />
        <path
            d="M14.0046 20.3718C12.6582 17.6279 8.66859 17.6294 7.32324 20.3718L9.50244 20.8522C10.1287 20.3013 11.199 20.3014 11.8253 20.8522L14.0046 20.3718Z"
            fill={props.color}
        />
        <path
            d="M37.8573 6.79932H1.14246C0.511448 6.79932 -0.00012207 7.31089 -0.00012207 7.94189V31.479C-0.00012207 32.11 0.511448 32.6216 1.14246 32.6216H37.8573C38.4883 32.6216 38.9999 32.11 38.9999 31.479V7.94189C38.9999 7.31089 38.4883 6.79932 37.8573 6.79932ZM5.72762 11.3696H33.2721C34.7864 11.4258 34.7853 13.5991 33.2721 13.6548H5.72762C4.21333 13.5986 4.21447 11.4253 5.72762 11.3696ZM10.6639 28.0513C7.34582 28.0513 4.64636 25.3518 4.64636 22.0337C4.94892 14.0614 16.3802 14.0636 16.6815 22.0338C16.6815 25.3518 13.9821 28.0513 10.6639 28.0513ZM33.2869 27.6704H20.7748C19.2605 27.6142 19.2616 25.4409 20.7748 25.3853H33.2869C34.8013 25.4415 34.8001 27.6147 33.2869 27.6704ZM19.6323 21.9574C19.6323 21.3264 20.1438 20.8149 20.7748 20.8149H29.7083C31.2227 20.8711 31.2215 23.0443 29.7083 23.1H20.7748C20.1438 23.1 19.6323 22.5885 19.6323 21.9574ZM33.2869 18.5297H20.7748C19.2605 18.4735 19.2616 16.3002 20.7748 16.2446H33.2869C34.8013 16.3008 34.8001 18.474 33.2869 18.5297Z"
            fill={props.color}
        />
    </svg>
)
