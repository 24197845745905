import React from 'react'
import styles from './ActionButtons.module.scss'
import { actionButtons } from './ActionButtons.config'
import {Props} from './ActionButtons.typings'
import { VisibleComponent } from '../../screens/About/About.config'

const ActionButtons = (props:Props) => {

    const actionButtonsHandler = (action:string) => {
        if(action === 'random') props.onRandom()
        else if(action === 'decline') props.onDecline(VisibleComponent.playerInfoForm)
    }

    return(
        <div className={styles.actionsBlock}>
                {
                    actionButtons.map(item => {
                        const Icon = item.icon
                        return (
                            <div key={item.action}>
                                <Icon
                                    fill={item.action === 'confirm' ? '#FFD80E' : '#fff'}
                                    width={item.action === 'confirm' ? 4.6875 : 3.6875}
                                    onSelect={actionButtonsHandler}
                                    action={item.action}
                                />
                            </div>
                        )
                    })
                }
            </div>

    )
}

export default ActionButtons