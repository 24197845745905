import * as React from 'react'
import { SVGProps } from 'react'

export const Upload = (props: SVGProps<SVGSVGElement>) => (
    <svg
        width="108"
        height="91"
        viewBox="0 0 108 91"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="M68.2405 11.0274L59.4794 6.26638C51.7871 2.0903 47.941 0 43.8057 0C39.6704 0 35.8243 2.08577 28.132 6.26638L26.7259 7.03267L65.8137 30.153L83.4061 21.0391C80.5762 17.72 76.0117 15.2397 68.2405 11.0183V11.0274ZM86.5075 27.0424L68.994 36.111V49.8771C68.994 50.779 68.6478 51.644 68.0317 52.2817C67.4156 52.9195 66.5799 53.2778 65.7086 53.2778C64.8372 53.2778 64.0015 52.9195 63.3854 52.2817C62.7693 51.644 62.4231 50.779 62.4231 49.8771V39.5072L47.0911 47.4422V90.2503C50.2364 89.4387 53.8153 87.498 59.4794 84.4192L68.2405 78.903C67.5558 59.6391 68.6618 59.6973 70.7225 54.8891C72.3058 51.1946 74.9447 49.0835 87.4343 45.6529C87.4343 37.0695 87.6114 31.1324 86.5075 27.0424ZM40.5203 90.2503V47.4467L1.1039 27.0424C5.0333e-07 31.1324 0 36.501 0 45.0753V45.6058C0 57.0548 -5.0333e-07 62.7816 2.6152 67.3794C5.23478 71.9817 9.94827 74.5436 19.3709 79.6628L28.132 84.4192C33.7961 87.498 37.375 89.4387 40.5203 90.2503ZM4.20535 21.0436L43.8057 41.5385L58.7478 33.8076L19.8221 10.7825L19.3709 11.0274C11.6041 15.2443 7.0352 17.7245 4.20535 21.0481V21.0436Z"
            fill={props.color}
        />
        <path
            d="M107.349 89.0196C106.549 88.787 105.228 88.2295 104.407 87.7753C98.5142 84.5056 94.0962 77.1569 92.8066 68.4988C92.7379 68.0311 92.6761 67.5695 92.6727 67.4743L92.6666 67.2967L95.7191 67.1904L98.7782 67.0839L93.7102 62.4959L88.6421 57.9079L83.9178 62.8177L79.2001 67.7337L82.2403 67.6469C84.7769 67.5713 85.2807 67.5665 85.2833 67.6426C85.2908 67.8583 85.4851 69.1157 85.6311 69.9238C86.625 75.3524 88.8077 80.1489 91.9727 83.8375C92.7163 84.7074 94.5147 86.36 95.3937 86.9837C99.2117 89.6904 103.442 90.5214 107.617 89.3851C107.786 89.3411 107.947 89.2783 107.973 89.2584C107.998 89.2321 107.72 89.1274 107.349 89.0196Z"
            fill={props.color}
        />
    </svg>
)