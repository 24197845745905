import React from 'react'
import styles from './CheckBox.module.scss'
import { Key } from 'shared/icons/generated'

interface CheckBoxProps {
    checked?: boolean
    onChange?: (checked: boolean) => void
    name?: string
    label?: string
}

export const CheckBox: React.FC<CheckBoxProps> = ({label, checked, onChange}) => {

    return (
        <div className={styles.wrapper}>
            <div
                className={styles.container}
                onClick={() => onChange?.(!checked)}
            >
                {checked && (
                    <Key color="rgb(255, 216, 14)" className={styles.icon} />
                )}
            </div>
            {label}
        </div>
    )
}
