import { RpcResponse } from 'shared/typings/common';
import { useEffect, useState, useRef } from 'react'
import rpc from 'altv-rpc'

export const useGYMGame = () => {

    const [start, setStart] = useState<boolean>(false)
    const [duration, setDuration] = useState<number>(2)
    const timeoutRef = useRef<any>(null)
    const event = 'gym:push'

    interface Data {
        start: boolean
        duration: number
    }

    const callback = ({data}:RpcResponse<Data>) => {
        setStart(false)
        setTimeout(() => setStart(data.start),10)
        clearTimeout(timeoutRef.current)
        timeoutRef.current = setTimeout(() => {
            setStart(false)
            clearTimeout(timeoutRef.current)
        }, data.duration*1000)
        setDuration(data.duration)
    }

    useEffect(() => {
        rpc.on(event, callback)
        return () => rpc.off(event, callback)
    })
    return {start, duration}
}
