import { Props } from 'entities/ui/AutoSensors/AutoSensors.typings'
import { AutoSensorsParams } from 'entities/ui/AutoSensors/model/useAutoSensorsData'

type Mapper = (params: Partial<AutoSensorsParams>) => Props

export const mapParamsToProps: Mapper = (params) => {
    const {
        speed,
        maxSpeed,
        maxFuel,
        fuel,
        rpm,
        maxRpm,
        isEngineOn,
        isLightsOn,
        blinkerID,
        gear,
        isArsOn,
        isAbsOn,
        isDoorOpen,
        isEmergencyOn,
        isEspOn,
        hasSportMode,
        isSportMode,
        hasLaunchControl,
        isLaunchControl,
    } = params

    return {
        speed,
        maxSpeed,
        maxFuel,
        fuel,
        rpm,
        maxRpm,
        transmission: gear,
        turnSignals: {
            isLeftOn: blinkerID === 1 || blinkerID === 4,
            isRightOn: blinkerID === 2 || blinkerID === 4,
        },
        modes: {
            hasSportMode,
            isSportMode,
            hasLaunchControl,
            isLaunchControl,
        },
        sensors: {
            isArsOn,
            isAbsOn,
            isEspOn,
            isEngineOn,
            isEmergencyOn,
            isHeadlightsOn: isLightsOn,
            isDoorOpen,
        },
    } as Props
}
