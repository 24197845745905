import { MenuItemVariants } from "./PoliceRadar.typings"
import away from './assets/sounds/away.mp3'
import beep from './assets/sounds/beep.mp3'
import closing from './assets/sounds/closing.mp3'
import done from './assets/sounds/done.mp3'
import front from './assets/sounds/front.mp3'
import plateHit from './assets/sounds/plate_hit.mp3'
import rear from './assets/sounds/rear.mp3'
import speedAlert from './assets/sounds/speed_alert.mp3'
import xMitOff from './assets/sounds/xmit_off.mp3'
import xMitOn from './assets/sounds/xmit_on.mp3'

const fastSpeedLockLimitPresets = []

for (let i = 5; i <= 200; i += 5) {
    fastSpeedLockLimitPresets.push(String(i))
}

export const menuItemVariants: MenuItemVariants = {
    fasterSpeedDisplay: ['OFF', 'On'],
    sameLaneSensitivityAdjustment: ['1', '2', '3', '4', '5'],
    oppositeLaneSensitivityAdjustment: ['1', '2', '3', '4', '5'],
    beepAudioVolume: ['1', '2', '3', '4', '5', 'OFF'],
    voiceEnunciatorVolume: ['1', '2', '3', '4', '5'],
    plateReaderVolume: ['1', '2', '3', '4', '5'],
    unitOfMeasurement: ['USA', 'INT'],
    fastSpeedLock: ['OFF', 'On'],
    fastSpeedLockLimit: fastSpeedLockLimitPresets
}

export const audioLibrary = [
    away,
    beep,
    closing,
    done,
    front,
    plateHit,
    rear,
    speedAlert,
    xMitOff,
    xMitOn,
]

export enum Position {top, bottom}