import * as React from 'react'
import { SVGProps } from 'react'

export const TurnLeft = (props: SVGProps<SVGSVGElement>) => (
    <svg
        width="1em"
        height="1em"
        viewBox="0 0 15 15"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="M14.178 3.41518H8.92299L8.92277 0.761082C8.92277 0.453306 8.72252 0.17577 8.41528 0.0579565C8.10794 -0.0598567 7.75467 0.0052906 7.51935 0.222956L0.2407 6.96155C0.0865905 7.10422 0 7.29784 0 7.49967C0 7.70151 0.0865905 7.89513 0.240809 8.0378L7.51968 14.7764C7.67696 14.922 7.88719 14.9993 8.10115 14.9993C8.20703 14.9993 8.31379 14.9804 8.4155 14.9414C8.72274 14.8236 8.92299 14.546 8.92299 14.2383V11.5842H14.1779C14.632 11.5842 15 11.2434 15 10.8231V4.17614C15.0001 3.75593 14.632 3.41518 14.178 3.41518ZM13.356 10.0621H8.10093C7.64682 10.0621 7.27887 10.4029 7.27887 10.8232V12.401L1.98457 7.49967L7.27876 2.5983L7.27887 4.17625C7.27887 4.59656 7.64693 4.93731 8.10093 4.93731H13.3559V10.0621H13.356Z"
            fill={props.color}
        />
    </svg>
)
