import React, {useState} from 'react'
import styles from './GoalSelectItem.module.scss'
import { Props } from './GoalSelectItem.typings'
import SubGoals from './SubGoals/SubGoals'
import { GoalsType } from '../../screens/About/About.config'
import cn from 'classnames'

const GoalSelectItem = (props: Props) => {

    const [isSubgoalsVisible, setSubgoalsVisible] = useState<boolean>(false)

    const handleClick = () => {
        setSubgoalsVisible(true)
        props.onSelect(GoalsType.mainGoal, props.id)
    }

    return (
        <div
            className={cn(styles.wrapper, props.checkedGoal !== props.id && styles.hover)}
            onClick={() => handleClick()}
        >
            {
            props.lifeGoals.mainGoalId === props.id && props.subGoals && isSubgoalsVisible &&
            <SubGoals
                subGoals={props.subGoals}
                onSelect={props.onSelect}
                lifeGoals={props.lifeGoals}
                switchComponents={props.switchComponents}
                closeTiles={setSubgoalsVisible}
            />
            }
            <div className={styles.imgContainer}>
                <img src={props.img} className={styles.icon} />
            </div>
            <div className={cn(styles.title, props.checkedGoal === props.id && styles.checked)}>{props.title}</div>
        </div>
    )
}

export default GoalSelectItem