import { rpc } from 'altv-xrpc-webview'
import { baseApi } from 'app/api/baseApi'

export const hudHintsApi = baseApi.injectEndpoints({
    endpoints: (builder) => ({
        altStatus: builder.query<boolean, void>({
            queryFn: async () => ({ data: false }),
            async onCacheEntryAdded(
                _,
                { updateCachedData, cacheDataLoaded, cacheEntryRemoved }
            ) {
                try {
                    await cacheDataLoaded

                    rpc.onClient('hud:alt', (value:any) => {
                        updateCachedData(() => value.isActive)
                    })
                } catch {
                    // ...
                }
                await cacheEntryRemoved
            },
        }),
        locationToTransport: builder.query<string, void>({
            queryFn: async () => ({ data: 'inVehicle' }),
            async onCacheEntryAdded(
                _,
                { updateCachedData, cacheDataLoaded, cacheEntryRemoved }
            ) {
                try {
                    await cacheDataLoaded

                    rpc.onClient('player:location-to-transport', (value:any) => {
                        updateCachedData(() => value.location)
                    })
                } catch {
                    // ...
                }
                await cacheEntryRemoved
            },
        }),
    }),
})

export const {
    useAltStatusQuery,
    useLocationToTransportQuery
} = hudHintsApi
