import React from 'react'

export const ConfirmIcon = ({ fill, width, onSelect, action }: { fill: string, width:number, onSelect:(action:string) =>void, action:string }) => {
    return (
        <svg
            style={{ width: `${width}rem`, aspectRatio: 1 / 1 }}
            width="75"
            height="75"
            viewBox="0 0 75 75"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            onClick={() => onSelect(action)}
        >
            <g clipPath="url(#clip0_715_258)">
                <circle
                    cx="37.5"
                    cy="37.5"
                    r="37.5"
                    fill={fill}
                />
                <path
                    d="M54.5021 25.4986C53.8382 24.8338 52.7619 24.8338 52.0979 25.4986L31.7309 45.8911L23.9021 38.0526C23.2383 37.3879 22.1619 37.3879 21.4979 38.0526C20.834 38.7173 20.834 39.795 21.4979 40.4597L30.5288 49.5017C31.1925 50.1663 32.2696 50.1659 32.933 49.5017L54.5021 27.9057C55.166 27.241 55.1659 26.1633 54.5021 25.4986Z"
                    fill="black"
                />
            </g>
            <defs>
                <clipPath id="clip0_715_258">
                    <rect
                        width="75"
                        height="75"
                        fill="white"
                    />
                </clipPath>
            </defs>
        </svg>
    )
}
