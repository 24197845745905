import cn from 'classnames'
import React from 'react'
import styles from './Field.module.scss'
import {InputProps} from "shared/ui/Input/Input.typings";

export type Props = {
    isRounded?: boolean
    placeholder?: string
} & InputProps<string>

const Field = ({
                    value,
                    onChange,
                    isRounded = true,
                   placeholder
}: Props) => {
    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      onChange(e.target.value)
    }

    return (
        <div
            className={cn(
                styles.container,
                isRounded && styles.rounded
            )}
        >
            <input
                className={styles.input}
                value={value}
                onChange={handleChange}
                placeholder={placeholder}
            />
        </div>
    )
}

export default Field
