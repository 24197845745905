import React from 'react'
import styles from './BeforePaymentScreen.module.scss'
import nfcIcon from './assets/nfcIcon.svg'
import { formatNumbers } from 'pages/ui/PaymentSystem/utils/formatNumbers'

export default function BeforePaymentScreen({
    summ,
}:{
    summ:number,
}){
    return(
        <div className={styles.wrapper}>
            <div className={styles.toPay}>к оплате</div>
            <div className={styles.summ}>$ {formatNumbers(summ)}</div>
            <img src={nfcIcon} className={styles.nfc} />
            <div className={styles.directions}>Приложите к терминалу устройство<br></br>или банковскую карту</div>
        </div>
    )
}