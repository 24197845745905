import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface ChatHistoryState {
    chatCommandHistory: string[];
    chatCommandHistoryActiveIndex: number;
}

const initialState: ChatHistoryState = {
    chatCommandHistory: [],
    chatCommandHistoryActiveIndex: 0,
};

const chatHistorySlice = createSlice({
    name: 'chatHistory',
    initialState,
    reducers: {
        addCommandToHistory(state, action: PayloadAction<string>) {
            const value = action.payload;
            const isFull = state.chatCommandHistory.length >= 10;
            if (isFull) {
                state.chatCommandHistory = [value, ...state.chatCommandHistory.slice(1)];
            } else {
                state.chatCommandHistory.unshift(value);
            }
            state.chatCommandHistoryActiveIndex = 0;
        },
        setActiveIndex(state, action: PayloadAction<number>) {
            state.chatCommandHistoryActiveIndex = action.payload;
        },
        resetCommandHistory(state) {
            state.chatCommandHistory = [];
            state.chatCommandHistoryActiveIndex = 0;
        },
    },
});

export const { addCommandToHistory, setActiveIndex, resetCommandHistory } = chatHistorySlice.actions;
export default chatHistorySlice.reducer;
