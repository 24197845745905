import * as React from 'react'
import { SVGProps } from 'react'

export const Shower = (props: SVGProps<SVGSVGElement>) => (
    <svg
        width="1em"
        height="1em"
        viewBox="0 0 30 30"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="M28.1252 14.9438H1.87501C0.841235 14.9438 0 15.7882 0 16.8258C0 17.8634 0.841235 18.7078 1.87501 18.7078H28.1252C29.1589 18.7078 30.0002 17.8634 30.0002 16.8258C30.0002 15.7882 29.1589 14.9438 28.1252 14.9438Z"
            fill={props.color}
        />
        <path
            d="M28.2975 17.4772C27.9587 17.3818 27.6187 17.575 27.525 17.9088L27.4762 18.0807H2.52481L2.47483 17.9088C2.38108 17.575 2.0386 17.3844 1.70233 17.4772C1.36981 17.5725 1.17856 17.9201 1.2736 18.2525L2.99609 24.305C3.52982 26.1807 5.25987 27.4906 7.20238 27.4906H22.7962C24.7399 27.4906 26.4687 26.1807 27.0025 24.305L28.725 18.2525C28.8212 17.92 28.6287 17.5725 28.2975 17.4772ZM7.20361 24.9812C6.37111 24.9812 5.6286 24.4204 5.40108 23.6161L4.58985 20.7631C4.49486 20.4293 4.68735 20.083 5.01858 19.9877C5.35356 19.8923 5.69733 20.0868 5.79108 20.4193L6.60232 23.2724C6.67984 23.5396 6.92605 23.7278 7.20355 23.7278C7.54855 23.7278 7.82857 24.0089 7.82857 24.3552C7.82863 24.7015 7.54861 24.9812 7.20361 24.9812Z"
            fill={props.color}
        />
        <path
            d="M8.40416 26.3012C8.09543 26.1494 7.72166 26.2711 7.56539 26.5823L6.3154 29.0916C6.16165 29.4015 6.28663 29.7779 6.59542 29.9335C6.68665 29.9787 6.78169 30 6.87544 30C7.10419 30 7.32421 29.8733 7.43419 29.6525L8.68418 27.1431C8.83793 26.8332 8.71289 26.4568 8.40416 26.3012Z"
            fill={props.color}
        />
        <path
            d="M23.6848 29.0927L22.4349 26.5834C22.2799 26.2722 21.9049 26.148 21.5961 26.3023C21.2873 26.4579 21.1623 26.8343 21.3161 27.1442L22.566 29.6535C22.676 29.8731 22.896 29.9998 23.1248 29.9998C23.2185 29.9998 23.3135 29.9785 23.4048 29.9346C23.7136 29.779 23.8386 29.4026 23.6848 29.0927Z"
            fill={props.color}
        />
        <path
            d="M25.1068 0C24.4693 0 23.8693 0.249657 23.4181 0.702627L21.4343 2.69377C21.1905 2.93842 21.1905 3.33617 21.4343 3.58083C21.678 3.82549 22.0743 3.82549 22.3181 3.58083L24.3018 1.59092C24.5155 1.37514 24.8018 1.25593 25.113 1.25593C25.7405 1.25593 26.2505 1.76783 26.2505 2.39765V15.5715C26.2505 15.9178 26.5305 16.1989 26.8755 16.1989C27.2205 16.1989 27.5005 15.9178 27.5018 15.5703V2.39641C27.5018 1.07526 26.4318 0 25.1068 0Z"
            fill={props.color}
        />
        <path
            d="M22.9423 3.32104L21.6923 2.0664C21.5498 1.92337 21.3511 1.85686 21.1486 1.89073L18.0236 2.40516C17.7936 2.4428 17.6048 2.60589 17.5311 2.82673C17.4573 3.04757 17.5136 3.29346 17.6761 3.46031L21.4261 7.3372C21.5449 7.46141 21.7086 7.52792 21.8736 7.52792C21.9324 7.52792 21.9924 7.51916 22.0511 7.50158C22.2736 7.43635 22.4423 7.25192 22.4873 7.02355L23.1123 3.88693C23.1535 3.68238 23.0898 3.47031 22.9423 3.32104Z"
            fill={props.color}
        />
        <path
            d="M14.8164 7.60033C14.5727 7.35567 14.1764 7.35567 13.9327 7.60033L12.6827 8.85497C12.4389 9.09963 12.4389 9.49737 12.6827 9.74203C12.8052 9.86371 12.9652 9.92523 13.1252 9.92523C13.2852 9.92523 13.4452 9.86377 13.5665 9.74203L14.8164 8.48739C15.0602 8.24268 15.0602 7.84499 14.8164 7.60033Z"
            fill={props.color}
        />
        <path
            d="M17.3161 10.1105C17.0723 9.86584 16.676 9.86584 16.4323 10.1105L15.1823 11.3651C14.9386 11.6098 14.9386 12.0075 15.1823 12.2522C15.3048 12.3739 15.4648 12.4354 15.6248 12.4354C15.7848 12.4354 15.9448 12.3739 16.0661 12.2522L17.3161 10.9976C17.5598 10.7529 17.5598 10.3552 17.3161 10.1105Z"
            fill={props.color}
        />
        <path
            d="M18.5665 6.34642C18.3227 6.10176 17.9264 6.10176 17.6827 6.34642L16.4327 7.60106C16.189 7.84571 16.189 8.24346 16.4327 8.48812C16.5552 8.6098 16.7152 8.67132 16.8752 8.67132C17.0352 8.67132 17.1952 8.60986 17.3165 8.48812L18.5665 7.23348C18.8102 6.98882 18.8102 6.59108 18.5665 6.34642Z"
            fill={props.color}
        />
    </svg>
)
