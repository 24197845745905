import React, { useState } from 'react'
import styles from './CharacterTraitsSelect.module.scss'
import { Props, Arr } from './CharacterTraitsSelect.typings'
import TraitSelect from './TraitSelect/TraitSelect'
import cn from 'classnames'
import LifeGoalWidget from '../LifeGoalWidget/LifeGoalWidget'
import { traitsArr, characterTraitsLabels } from './CharacterTraitsSelect.config'


const CharacterTraitsSelect = (props: Props) => {

    const [selectedLabel, setSelectedLabel] = useState<number>(1)
    const [selectedTraits, setSelectedTraits] = useState<Arr[]>(traitsArr.traits1)
    const [checkedTrait] = useState<number>(1)

    const handleSelectLabel = (id: number) => {
        setSelectedLabel(id)
        switch (id) {
            case 1: {
                setSelectedTraits(traitsArr.traits1)
                break
            }
            case 2: {
                setSelectedTraits(traitsArr.traits2)
                break
            }
            case 3: {
                setSelectedTraits(traitsArr.traits3)
                break
            }
            case 4: {
                setSelectedTraits(traitsArr.traits4)
                break
            }
        }
    }

    return (
        <div className={styles.wrapper}>
            <LifeGoalWidget
                isTitle={false}
                onMainGoalSelect={props.onMainGoalSelect}
                lifeGoals={props.lifeGoals}
                characterTraits={props.characterTraits}
                onTraitSelect={props.onTraitSelect}
            />
            <div className={styles.title}>Выбрать черту характера</div>
            <div className={styles.labels}>
                {
                    characterTraitsLabels.map(item => {
                        return (
                            <div
                                className={cn(styles.label, selectedLabel === item.id && styles.selected)}
                                key={item.id}
                                onClick={() => handleSelectLabel(item.id)}
                            >
                                <img
                                    className={styles.labelIcon}
                                    src={item.icon}
                                />
                            </div>
                        )
                    })
                }
            </div>
            <div className={styles.gridBlock}>
                <div className={styles.grid}>
                    {
                        selectedTraits.map(item => {
                            return (
                                <TraitSelect
                                    key={item.id}
                                    item={item}
                                    onSelect={props.onTraitSelectItem}
                                    trait={checkedTrait}
                                />
                            )
                        })
                    }
                </div>
            </div>
        </div>
    )
}

export default CharacterTraitsSelect