import React from 'react'
import styles from './GaitVariant.module.scss'
import {Props} from './GaitVariant.typings'
import cn from 'classnames'

const GaitVariant = (props:Props) => {

    return(
        <div
            className={cn(styles.wrapper, props.id === props.selectedGait && styles.selected)}
            onClick={() => props.onSelect(props.id)}
        >
            <img src={props.icon} />
            <div className={styles.tooltip}>{props.title}</div>
        </div>
    )
}

export default GaitVariant