import React, {useState} from 'react'
import styles from './CharacterPersonalization.module.scss'
import CameraRotation from './commonComponents/CameraRotation/CameraRotation'
import ScreenTitle from './commonComponents/ScreenTitle/ScreenTitle'
import { Screens } from './CharacterPersonalization.config'
import About from './screens/About/About'

const CharacterPersonalization = () => {

    const [activeScreen] = useState<Screens>(Screens.about)

    return(
        <div className={styles.wrapper}>
            <ScreenTitle title='О персонаже' />
            <CameraRotation />
            {
                activeScreen === Screens.about &&
                <About />
            }
        </div>
    )
}

export default CharacterPersonalization