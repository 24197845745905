import * as React from 'react'
import { SVGProps } from 'react'

export const Male = (props: SVGProps<SVGSVGElement>) => (
    <svg
        width="1em"
        height="1em"
        viewBox="0 0 21 31"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="M17.7816 17.5691C21.7967 13.5553 21.7967 7.02425 17.7816 3.01042C13.7665 -1.00347 7.23353 -1.00347 3.21836 3.01042C-0.796745 7.02425 -0.796745 13.5553 3.21836 17.5691C4.91791 19.2681 7.0686 20.2477 9.28843 20.5084V23.9549H6.86546C6.19636 23.9549 5.65398 24.4972 5.65398 25.166C5.65398 25.8349 6.19636 26.3771 6.86546 26.3771H9.28843V29.7888C9.28849 30.4577 9.83086 31 10.5 31C11.1691 31 11.7115 30.4577 11.7115 29.7888V26.3771H14.1345C14.8036 26.3771 15.346 25.8349 15.346 25.166C15.346 24.4972 14.8036 23.9549 14.1345 23.9549H11.7115V20.5085C13.9314 20.2477 16.0821 19.2681 17.7816 17.5691ZM4.93172 15.8563C1.86132 12.7869 1.86132 7.79259 4.93172 4.72317C8.00205 1.65387 12.9978 1.65369 16.0683 4.72317C19.1387 7.79259 19.1387 12.7869 16.0683 15.8563C12.9979 18.9256 8.00211 18.9256 4.93172 15.8563Z"
            fill={props.color}
        />
    </svg>
)
