import * as React from 'react'
import { SVGProps } from 'react'

export const Guard = (props: SVGProps<SVGSVGElement>) => (
    <svg
        width="1em"
        height="1em"
        viewBox="0 0 27 30"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="M13.2147 7.54919C9.78771 7.54919 6.99963 10.3373 6.99963 13.7643C6.99963 17.1913 9.78771 19.9794 13.2147 19.9794C16.6417 19.9794 19.4298 17.1913 19.4298 13.7643C19.4298 10.3373 16.6417 7.54919 13.2147 7.54919ZM17.0839 14.642H14.0925V17.6335H12.337V14.642H9.34556V12.8865H12.337V9.89512H14.0925V12.8865H17.0839V14.642Z"
            fill={props.color}
        />
        <path
            d="M25.586 4.73141C23.5018 4.65112 21.8487 2.95356 21.8226 0.866746L21.8117 0H4.61812L4.6073 0.866746C4.58114 2.95356 2.92811 4.65112 0.843925 4.73141L0 4.76394V11.833C0 18.2189 3.31104 24.1437 8.64101 27.2954L13.215 30L17.7889 27.2954C23.1189 24.1438 26.43 18.2189 26.43 11.833V4.76394L25.586 4.73141ZM13.215 21.7344C8.81996 21.7344 5.24437 18.1588 5.24437 13.7638C5.24437 9.36878 8.8199 5.79313 13.215 5.79313C17.6099 5.79313 21.1855 9.36872 21.1855 13.7637C21.1855 18.1587 17.6099 21.7344 13.215 21.7344Z"
            fill={props.color}
        />
    </svg>
)
