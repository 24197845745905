import React from 'react'

export const handleMouseDownCash = (
    e:React.MouseEvent,
    cashImageRef:React.MutableRefObject<HTMLDivElement | null>,
    cashGetterRef:React.MutableRefObject<HTMLDivElement | null>,
    posRef:React.MutableRefObject<HTMLDivElement | null>,
    walletRef:React.MutableRefObject<HTMLDivElement | null>,
    onSuccessPay:(isCash:boolean) => void,
) => {

    const cash = cashImageRef.current
        const initialTop = cash?.getBoundingClientRect().top
        const initialLeft = cash?.getBoundingClientRect().left
        if (cash) {
            cash.ondragstart = function () {
                return false;
            };
            const shiftX = e.clientX - cash.getBoundingClientRect().left;
            const shiftY = e.clientY - cash.getBoundingClientRect().top;
            cash.style.zIndex = '1000';
            cash.style.left = `${e.clientX - shiftX}px`;
            cash.style.top = `${e.clientY - shiftY}px`;
            cash.style.opacity = '1'

            const onMouseMove = (moveEvent: MouseEvent) => {
                cash.style.left = `${moveEvent.clientX - shiftX}px`;
                cash.style.top = `${moveEvent.clientY - shiftY}px`;
            };

            const onMouseUp = () => {
                if (cashGetterRef.current) {
                    const cashGetterRect = cashGetterRef.current.getBoundingClientRect();
                    const cashRect = cash.getBoundingClientRect();
                    const isOverPos = (
                        cashRect.top > cashGetterRect.top - 100 &&
                        cashRect.left > cashGetterRect.left - 100 &&
                        cashRect.bottom < cashGetterRect.bottom + 100 &&
                        cashRect.right < cashGetterRect.right + 100
                    );

                    if (isOverPos) {
                        onSuccessPay(true)
                    } else {
                        if (walletRef.current) {
                            cash.style.top = initialTop + 'px'
                            cash.style.left = initialLeft + 'px'
                            cash.style.opacity = '0'
                        }
                    }
                }
                document.removeEventListener('mousemove', onMouseMove);
                document.removeEventListener('mouseup', onMouseUp);
            };

            document.addEventListener('mousemove', onMouseMove);
            document.addEventListener('mouseup', onMouseUp);
        }
}